import type { ToggleButtonOption } from './types.tsx';

// Finds the first duplicate ID in the array of ToggleButtonOptions, or `null`
// if none are found.
export const findDuplicateIds = (options: ToggleButtonOption[]) => {
	for (let i = 0; i < options.length - 1; ++i) {
		for (let j = i + 1; j < options.length; ++j) {
			if (options[i].id === options[j].id) return options[i].id;
		}
	}
	return null;
};

type Precondition = {
	precondition: boolean;
	message: string;
};

// Writes an error message to the console if the precondition is false. The
// precondition is evaluated lazily, so that we can avoid the runtime cost of
// checking preconditions in produnction.
export const assertPrecondition = (evaluatePrecondition: () => Precondition) => {
	if (process.env.NODE_ENV !== 'production') {
		const { precondition, message } = evaluatePrecondition();
		// eslint-disable-next-line no-console
		console.assert(precondition, message);
	}
};
