import React, { useCallback, type ReactNode } from 'react';
import { styled } from '@compiled/react';
import { graphql, useFragment } from 'react-relay';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Avatar from '@atlaskit/avatar';
import { Box, xcss } from '@atlaskit/primitives';
import Anchor from '@atlaskit/primitives/anchor';
import { FormattedMessage } from '@atlassian/jira-intl';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { AsyncProfileCardNext } from '@atlassian/jira-profilecard-next/src/async.tsx';
import {
	VIEW_PROFILE_ACTION,
	VIEW_FILTERS_ACTION,
} from '@atlassian/jira-profilecard-next/src/common/constants.tsx';
import { generateUserLink } from '@atlassian/jira-profilecard-next/src/common/utils.tsx';
import type { Actions } from '@atlassian/jira-profilecard-next/src/types.tsx';
import type { owner_issueNavigatorCustomFilters$key } from '@atlassian/jira-relay/src/__generated__/owner_issueNavigatorCustomFilters.graphql';
import { TenantContextSubscriber } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import { Section } from '../../../../common/ui/details-popup/styled.tsx';
import messages from './messages.tsx';

type Props = {
	filter: owner_issueNavigatorCustomFilters$key;
};

const PROFILE_ACTIONS: Actions[] = [VIEW_PROFILE_ACTION, VIEW_FILTERS_ACTION];

export const Owner = ({ filter }: Props) => {
	const { owner } = useFragment(
		graphql`
			fragment owner_issueNavigatorCustomFilters on JiraCustomFilter {
				owner @required(action: THROW) {
					accountId
					name
					picture
				}
			}
		`,
		filter,
	);
	const onOwnerLinkClick = useCallback(
		(_: unknown, analyticsEvent: UIAnalyticsEvent) => {
			fireUIAnalytics(analyticsEvent, 'filterOwner', { filterOwnerId: owner.accountId });
		},
		[owner.accountId],
	);

	const UserLink = useCallback(
		(msg: ReactNode) => (
			<Anchor xcss={linkStyle} href={generateUserLink(owner.accountId)} onClick={onOwnerLinkClick}>
				{msg}
			</Anchor>
		),
		[owner.accountId, onOwnerLinkClick],
	);

	return (
		<Section>
			<OwnerContainer>
				<Box xcss={avatarContainerStyles}>
					<AsyncProfileCardNext
						accountId={owner.accountId}
						TenantContextSubscriber={TenantContextSubscriber}
						actions={PROFILE_ACTIONS}
					>
						<Avatar
							src={owner.picture}
							size="large"
							testId="issue-navigator-custom-filters.ui.details-popup.popup-content.owner.avatar"
						/>
					</AsyncProfileCardNext>
				</Box>
				<FormattedMessage
					{...messages.owner}
					values={{
						anchor: UserLink,
						ownerName: owner.name,
					}}
				/>
			</OwnerContainer>
		</Section>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const OwnerContainer = styled.div({
	display: 'flex',
	alignItems: 'center',
	flexDirection: 'row',
});

const avatarContainerStyles = xcss({
	marginRight: 'space.100',
});

const linkStyle = xcss({
	marginLeft: 'space.050',
	marginRight: 'space.050',
});
