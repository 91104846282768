/**
 * @generated SignedSource<<8a77bc15f947513b3b7750cebe8bbe84>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type main_issueNavigator_FullPageIssueApp_view$data = {
  readonly " $fragmentSpreads": FragmentRefs<"main_issueNavigator_IssueAppWithData_view">;
  readonly " $fragmentType": "main_issueNavigator_FullPageIssueApp_view";
};
export type main_issueNavigator_FullPageIssueApp_view$key = {
  readonly " $data"?: main_issueNavigator_FullPageIssueApp_view$data;
  readonly " $fragmentSpreads": FragmentRefs<"main_issueNavigator_FullPageIssueApp_view">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "main_issueNavigator_FullPageIssueApp_view",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "main_issueNavigator_IssueAppWithData_view"
    }
  ],
  "type": "JiraIssueSearchView"
};

(node as any).hash = "468ea16df2cfa07839ca03ecd97948b9";

export default node;
