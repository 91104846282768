import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	showMore: {
		id: 'issue-navigator-custom-filters.common.details-popup.show-more.show-more',
		defaultMessage: 'Show more...',
		description:
			'Text for the show more button, this button expands the description of a filter when is too long',
	},
	showLess: {
		id: 'issue-navigator-custom-filters.common.details-popup.show-more.show-less',
		defaultMessage: 'Show less',
		description:
			'Text for the show less button, this button collapse the description of a filter when is too long',
	},
});
