/**
 * @generated SignedSource<<8cc111c38638a601791847a427dbcdfb>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type main_issueNavigator_Card_fragment$data = {
  readonly fieldSetsForIssueSearchView: {
    readonly " $fragmentSpreads": FragmentRefs<"footer_issueNavigator_FooterInternal" | "main_issueNavigator_Card_fieldSetsForIssueSearchView">;
  };
  readonly isResolved: boolean | null | undefined;
  readonly key: string;
  readonly " $fragmentType": "main_issueNavigator_Card_fragment";
};
export type main_issueNavigator_Card_fragment$key = {
  readonly " $data"?: main_issueNavigator_Card_fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"main_issueNavigator_Card_fragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "amountOfColumns"
    },
    {
      "kind": "RootArgument",
      "name": "filterId"
    },
    {
      "kind": "RootArgument",
      "name": "issueTypeId"
    },
    {
      "kind": "RootArgument",
      "name": "namespace"
    },
    {
      "kind": "RootArgument",
      "name": "projectId"
    },
    {
      "kind": "RootArgument",
      "name": "viewId"
    }
  ],
  "kind": "Fragment",
  "name": "main_issueNavigator_Card_fragment",
  "selections": [
    {
      "kind": "RequiredField",
      "field": {
        "kind": "ScalarField",
        "name": "key"
      },
      "action": "THROW",
      "path": "key"
    },
    {
      "kind": "ScalarField",
      "name": "isResolved"
    },
    {
      "kind": "RequiredField",
      "field": {
        "args": [
          {
            "fields": [
              {
                "fields": [
                  {
                    "kind": "Variable",
                    "name": "issueType",
                    "variableName": "issueTypeId"
                  },
                  {
                    "kind": "Variable",
                    "name": "project",
                    "variableName": "projectId"
                  }
                ],
                "kind": "ObjectValue",
                "name": "projectContext"
              }
            ],
            "kind": "ObjectValue",
            "name": "context"
          },
          {
            "kind": "Variable",
            "name": "filterId",
            "variableName": "filterId"
          },
          {
            "kind": "Variable",
            "name": "first",
            "variableName": "amountOfColumns"
          },
          {
            "kind": "Variable",
            "name": "namespace",
            "variableName": "namespace"
          },
          {
            "kind": "Variable",
            "name": "viewId",
            "variableName": "viewId"
          }
        ],
        "concreteType": "JiraIssueFieldSetConnection",
        "kind": "LinkedField",
        "name": "fieldSetsForIssueSearchView",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "main_issueNavigator_Card_fieldSetsForIssueSearchView"
          },
          {
            "kind": "FragmentSpread",
            "name": "footer_issueNavigator_FooterInternal"
          }
        ]
      },
      "action": "THROW",
      "path": "fieldSetsForIssueSearchView"
    }
  ],
  "type": "JiraIssue"
};

(node as any).hash = "a635b991dd89a748dce8375f793ab03f";

export default node;
