import React from 'react';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/index.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { useShortcuts } from '@atlassian/jira-shortcuts-dialog/src/controllers/shortcuts-service/index.tsx';
import { lazy, LazyWait } from '@atlassian/react-loosely-lazy';
import type { RegisterShortcutsDialogActionsProps } from './index.tsx';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazyRegisterShortcutDialogActions = lazy(
	() => import(/* webpackChunkName: "async-register-shortcut-dialog-actions" */ './index'),
);

export const AsyncRegisterShortcutDialogActions = (props: RegisterShortcutsDialogActionsProps) => {
	const [{ isShortcutsDialogVisible }] = useShortcuts();

	return (
		<JSErrorBoundary
			packageName="jiraIssueNavigator"
			id="register-issue-actions-worklflow-shortcuts-dialog"
			fallback="flag"
		>
			<LazyWait until={isShortcutsDialogVisible}>
				<Placeholder name="register-shortcut-dialog-actions" fallback={null}>
					<LazyRegisterShortcutDialogActions {...props} />
				</Placeholder>
			</LazyWait>
		</JSErrorBoundary>
	);
};
