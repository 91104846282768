import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	private: {
		id: 'issue-navigator-custom-filters.details-popup.popup-content.permissions.edit.private',
		defaultMessage: 'Private',
		description: 'Text when only you can edit this filter.',
	},
	editabledBy: {
		id: 'issue-navigator-custom-filters.details-popup.popup-content.permissions.edit.editabled-by',
		defaultMessage: 'Editable by:',
		description: 'Label before listing all the projects/groups that can edit a filter',
	},
});
