import type { ReactNode } from 'react';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled, { css } from 'styled-components';

/**
 * When the @atlaskit/button component is disabled it prevents mouse events being fired for the button, which prevents
 * tooltips showing https://atlassian.design/components/button/usage#disabled-buttons.
 * When you require a disabled button to show a tooltip on hover then the button should be wrapped in this element.
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DisabledButtonWithTooltip = styled.span<{
	children?: ReactNode;
	isDisabled: boolean;
}>(
	{
		display: 'inline-flex',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	(props) =>
		props.isDisabled
			? // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values
				css({
					cursor: 'not-allowed',
					// Disable pointer events for the button element which means 'event.preventDefault()' will not be
					// called from AK. This allows pointer events to bubble from our wrapping span element.
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
					'> button': {
						pointerEvents: 'none',
					},
				})
			: '',
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export default DisabledButtonWithTooltip;
