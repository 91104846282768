import React, { Component } from 'react';
import noop from 'lodash/noop';
import Button from '@atlaskit/button';
import type { AnalyticsEvent } from '@atlassian/jira-common-analytics-v2-wrapped-components/src/types.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { injectIntlV2 as injectIntl } from '@atlassian/jira-intl/src/v2/inject.tsx';
import type { IntlShapeV2 as Intl } from '@atlassian/jira-intl/src/v2/types.tsx';
import messages from './messages.tsx';
import { Container, InfoMessage } from './styled.tsx';

type Props = {
	intl: Intl;
	onResetErrors: (arg1: AnalyticsEvent) => void;
};

type State = {
	isDialogOpen: boolean;
};
// eslint-disable-next-line jira/react/no-class-components
export class ErrorInfo extends Component<Props, State> {
	static defaultProps = {
		onResetErrors: noop,
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onReloadIssueClick = (...attrs: any[]) => {
		const analyticsEvent = attrs.pop();
		this.props.onResetErrors(analyticsEvent);
	};

	render() {
		const {
			intl: { formatMessage },
		} = this.props;

		return (
			<Container>
				<InfoMessage>
					{formatMessage(
						expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
							? messages.infoTextIssueTermRefresh
							: messages.infoText,
					)}
				</InfoMessage>
				<Button appearance="link" onClick={this.onReloadIssueClick} spacing="none">
					{formatMessage(
						expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
							? messages.reloadIssueIssueTermRefresh
							: messages.reloadIssue,
					)}
				</Button>
			</Container>
		);
	}
}

export default injectIntl(ErrorInfo);
