import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	private: {
		id: 'issue-navigator-custom-filters.details-popup.popup-content.permissions.access.private',
		defaultMessage: 'Private',
		description: 'Text when only you can access this filter.',
	},
	visibleTo: {
		id: 'issue-navigator-custom-filters.details-popup.popup-content.permissions.access.visible-to',
		defaultMessage: 'Visible to:',
		description: 'Label before listing all the projects/groups that can view a filter',
	},
	public: {
		id: 'issue-navigator-custom-filters.details-popup.popup-content.permissions.access.public',
		defaultMessage: 'Public',
		description: 'Text when anyone can access this filter',
	},
	loggedInUsers: {
		id: 'issue-navigator-custom-filters.details-popup.popup-content.permissions.access.logged-in-users',
		defaultMessage: 'Logged in users on this instance',
		description: 'Text when anyone logged in can access this filter',
	},
});
