import React, { useCallback } from 'react';
import AppPermissionsCheck from '@atlassian/jira-app-permissions-check/src/index.tsx';
import type { CustomHeaderProps } from '@atlassian/jira-issue-navigator/src/common/types.tsx';
import IssueNavigator, {
	type Props as IssueNavigatorProps,
} from '@atlassian/jira-issue-navigator/src/main.tsx';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import { JsdGettingStartedPanelShouldRender } from '@atlassian/jira-servicedesk-getting-started-panel/src/async.tsx';
import type { ProjectKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { ProjectIssueNavigatorHeaderSkeleton } from '@atlassian/jira-skeletons/src/ui/issue-navigator/components/project-header.tsx';
import Header from './ui/main.tsx';
import PageActions from './ui/page-actions/index.tsx';

type InnerProps = {
	CustomHeader: IssueNavigatorProps['CustomHeader'];
	HeaderSkeletonImage: IssueNavigatorProps['HeaderSkeletonImage'];
};

export type Props = Omit<IssueNavigatorProps, keyof InnerProps> & {
	projectKey: ProjectKey;
	showNav3Header?: boolean;
};

const ActionMenu = (customHeaderProps: CustomHeaderProps) => {
	const { jql, filterId, filterDetails, searchResultPageData, HierarchyToggle } = customHeaderProps;
	return (
		<PageActions
			jql={jql}
			filterId={filterId}
			isModifiedFilter={filterDetails?.jql !== jql}
			searchResultPageData={searchResultPageData}
			HierarchyToggle={HierarchyToggle}
		/>
	);
};

const ProjectIssueNavigator = ({ projectKey, showNav3Header = false, ...rest }: Props) => {
	const CustomHeader = useCallback(
		(customHeaderProps: CustomHeaderProps) => (
			<Header
				projectKey={projectKey}
				{...customHeaderProps}
				isQuickFiltersDisabled={showNav3Header}
			/>
		),
		[projectKey, showNav3Header],
	);

	const showNewNav = getWillShowNav4() && !showNav3Header;

	return (
		<>
			<IssueNavigator
				{...(!showNewNav && { CustomHeader })}
				{...(showNewNav && { ActionMenu })}
				HeaderSkeletonImage={ProjectIssueNavigatorHeaderSkeleton}
				isFeedbackButtonDisabled
				{...rest}
			/>
			<AppPermissionsCheck hasServiceDeskAccess>
				<JsdGettingStartedPanelShouldRender />
			</AppPermissionsCheck>
		</>
	);
};

export default ProjectIssueNavigator;
