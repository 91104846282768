/**
 * @generated SignedSource<<58e29922a2265a3c4b6bbfefb99cf906>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type main_issueNavigator_IssueNavigator_issueQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"main_issueNavigator_IssueNavigator_refetchQueryNew" | "main_issueNavigator_IssueNavigator_refetchQueryOld">;
  readonly " $fragmentType": "main_issueNavigator_IssueNavigator_issueQuery";
};
export type main_issueNavigator_IssueNavigator_issueQuery$key = {
  readonly " $data"?: main_issueNavigator_IssueNavigator_issueQuery$data;
  readonly " $fragmentSpreads": FragmentRefs<"main_issueNavigator_IssueNavigator_issueQuery">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "__relay_internal__pv__atlassianjirarelayprovidersrcisjscinfinitescrollenabledrelayprovider"
    }
  ],
  "kind": "Fragment",
  "name": "main_issueNavigator_IssueNavigator_issueQuery",
  "selections": [
    {
      "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcisjscinfinitescrollenabledrelayprovider",
      "kind": "Condition",
      "passingValue": false,
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "main_issueNavigator_IssueNavigator_refetchQueryOld"
        }
      ]
    },
    {
      "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcisjscinfinitescrollenabledrelayprovider",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "main_issueNavigator_IssueNavigator_refetchQueryNew"
        }
      ]
    }
  ],
  "type": "Query"
};

(node as any).hash = "0296d13020f68034a057103bb2c3501c";

export default node;
