/**
 * @generated SignedSource<<8a5f6a2f0fd4805c158b73f52eb4b83a>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type ui_issueTableHierarchyToggle_HierarchyToggle$data = {
  readonly id: string;
  readonly viewConfigSettings: {
    readonly __id: string;
    readonly canEnableHierarchy: boolean | null | undefined;
    readonly isHierarchyEnabled: boolean | null | undefined;
  } | null | undefined;
  readonly viewId: string | null | undefined;
  readonly " $fragmentType": "ui_issueTableHierarchyToggle_HierarchyToggle";
};
export type ui_issueTableHierarchyToggle_HierarchyToggle$key = {
  readonly " $data"?: ui_issueTableHierarchyToggle_HierarchyToggle$data;
  readonly " $fragmentSpreads": FragmentRefs<"ui_issueTableHierarchyToggle_HierarchyToggle">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "staticViewInput"
    }
  ],
  "kind": "Fragment",
  "name": "ui_issueTableHierarchyToggle_HierarchyToggle",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "id"
    },
    {
      "kind": "ScalarField",
      "name": "viewId"
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "staticViewInput",
          "variableName": "staticViewInput"
        }
      ],
      "concreteType": "JiraIssueSearchViewConfigSettings",
      "kind": "LinkedField",
      "name": "viewConfigSettings",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "isHierarchyEnabled"
        },
        {
          "kind": "ScalarField",
          "name": "canEnableHierarchy"
        },
        {
          "kind": "ClientExtension",
          "selections": [
            {
              "kind": "ScalarField",
              "name": "__id"
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraIssueSearchView"
};

(node as any).hash = "a90a5d974c65ce1f9f6a8252c6cdb107";

export default node;
