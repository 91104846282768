/**
 * @generated SignedSource<<cfc9b47a86609e2981aad8da17fded50>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type projectRoleGrant_issueNavigatorCustomFilters$data = {
  readonly project: {
    readonly key: string;
    readonly name: string;
  } | null | undefined;
  readonly role: {
    readonly name: string | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "projectRoleGrant_issueNavigatorCustomFilters";
};
export type projectRoleGrant_issueNavigatorCustomFilters$key = {
  readonly " $data"?: projectRoleGrant_issueNavigatorCustomFilters$data;
  readonly " $fragmentSpreads": FragmentRefs<"projectRoleGrant_issueNavigatorCustomFilters">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "ScalarField",
  "name": "name"
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "projectRoleGrant_issueNavigatorCustomFilters",
  "selections": [
    {
      "concreteType": "JiraProject",
      "kind": "LinkedField",
      "name": "project",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "ScalarField",
          "name": "key"
        }
      ]
    },
    {
      "concreteType": "JiraRole",
      "kind": "LinkedField",
      "name": "role",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ]
    }
  ],
  "type": "JiraShareableEntityProjectRoleGrant"
};
})();

(node as any).hash = "0353d445a52987cb7c245d2ca5cd2d8c";

export default node;
