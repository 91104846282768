/**
 * @generated SignedSource<<7a81ad90f786dcda427114c732094375>>
 * @relayHash 2b65e301958724dc8c6402ca22475ab6
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID d9d8ac3393a5f801f0230f5c8f4cbe2ea9c173fe53d6563473bfd82a5c4a5532

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type JiraSoftwareIssueSearchCustomInputContext = "BACKLOG" | "BOARD" | "NONE" | "%future added value";
export type JiraIssueSearchInput = {
  childIssuesInput?: JiraIssueSearchChildIssuesInput | null | undefined;
  customInput?: JiraIssueSearchCustomInput | null | undefined;
  filterId?: string | null | undefined;
  jql?: string | null | undefined;
};
export type JiraIssueSearchChildIssuesInput = {
  filterByProjectKeys?: ReadonlyArray<string> | null | undefined;
  parentIssueKey: string;
};
export type JiraIssueSearchCustomInput = {
  jiraSoftwareInput?: JiraSoftwareIssueSearchCustomInput | null | undefined;
};
export type JiraSoftwareIssueSearchCustomInput = {
  additionalJql?: string | null | undefined;
  context?: JiraSoftwareIssueSearchCustomInputContext | null | undefined;
  jiraEntityId: string;
};
export type JiraIssueSearchOptions = {
  issueKey?: string | null | undefined;
};
export type JiraIssueSearchViewConfigInput = {
  staticViewInput?: JiraIssueSearchStaticViewInput | null | undefined;
  viewInput?: JiraIssueSearchViewInput | null | undefined;
};
export type JiraIssueSearchStaticViewInput = {
  isGroupingEnabled?: boolean | null | undefined;
  isHierarchyEnabled?: boolean | null | undefined;
};
export type JiraIssueSearchViewInput = {
  namespace?: string | null | undefined;
  viewId?: string | null | undefined;
};
export type IssueNavigatorIssueSearchPaginationQuery$variables = {
  after?: string | null | undefined;
  amountOfColumns?: number | null | undefined;
  before?: string | null | undefined;
  cloudId: string;
  fieldSetIds: ReadonlyArray<string>;
  filterId?: string | null | undefined;
  first?: number | null | undefined;
  issueSearchInput: JiraIssueSearchInput;
  issueTypeId?: string | null | undefined;
  last?: number | null | undefined;
  namespace?: string | null | undefined;
  options?: JiraIssueSearchOptions | null | undefined;
  projectId?: string | null | undefined;
  shouldQueryFieldSetsById: boolean;
  viewConfigInput?: JiraIssueSearchViewConfigInput | null | undefined;
  viewId?: string | null | undefined;
};
export type IssueNavigatorIssueSearchPaginationQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"main_issueNavigator_IssueNavigator_issueSearchData">;
};
export type IssueNavigatorIssueSearchPaginationQuery = {
  response: IssueNavigatorIssueSearchPaginationQuery$data;
  variables: IssueNavigatorIssueSearchPaginationQuery$variables;
};
({
  "__relay_internal__pv__atlassianjirarelayprovidersrcisbulkopsinninenabledrelayprovider": bulkOpsInNinEnabled_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcjscm2apiupdatesrelayprovider": isChildIssueTotalCountEnabled_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcjscm1apiupdatesrelayprovider": isJscM1ApiUpdatesEnabled_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcnikechinstorypointsinlineeditrelayprovider": isNikeChinStoryPointsInlineEditEnabled_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrccmdbmediaavatarfgrelayprovider": isCmdbMediaAvatarEnabled_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcissuelinksentrypointrelayprovider": isEntryPointEnabled_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcissuelinkscountrelayprovider": count_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcisjscinfinitescrollenabledrelayprovider": isJscInfiniteScrollEnabled_provider
} as {
  readonly __relay_internal__pv__atlassianjirarelayprovidersrccmdbmediaavatarfgrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcisbulkopsinninenabledrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcisjscinfinitescrollenabledrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcissuelinkscountrelayprovider: {
    readonly get: () => number;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcissuelinksentrypointrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcjscm1apiupdatesrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcjscm2apiupdatesrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcnikechinstorypointsinlineeditrelayprovider: {
    readonly get: () => boolean;
  };
});

import isCmdbMediaAvatarEnabled_provider from '@atlassian/jira-relay-provider/src/cmdb-media-avatar-fg.relayprovider';
import bulkOpsInNinEnabled_provider from '@atlassian/jira-relay-provider/src/is-bulk-ops-in-nin-enabled.relayprovider';
import isJscInfiniteScrollEnabled_provider from '@atlassian/jira-relay-provider/src/is-jsc-infinite-scroll-enabled.relayprovider';
import count_provider from '@atlassian/jira-relay-provider/src/issue-links-count.relayprovider';
import isEntryPointEnabled_provider from '@atlassian/jira-relay-provider/src/issue-links-entrypoint.relayprovider';
import isJscM1ApiUpdatesEnabled_provider from '@atlassian/jira-relay-provider/src/jsc-m1-api-updates.relayprovider';
import isChildIssueTotalCountEnabled_provider from '@atlassian/jira-relay-provider/src/jsc-m2-api-updates.relayprovider';
import isNikeChinStoryPointsInlineEditEnabled_provider from '@atlassian/jira-relay-provider/src/nike-chin-story-points-inline-edit.relayprovider';

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "amountOfColumns"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "before"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cloudId"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "fieldSetIds"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filterId"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "issueSearchInput"
},
v8 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "issueTypeId"
},
v9 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "last"
},
v10 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "namespace"
},
v11 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "options"
},
v12 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectId"
},
v13 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "shouldQueryFieldSetsById"
},
v14 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "viewConfigInput"
},
v15 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "viewId"
},
v16 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "before",
    "variableName": "before"
  },
  {
    "kind": "Variable",
    "name": "cloudId",
    "variableName": "cloudId"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "issueSearchInput",
    "variableName": "issueSearchInput"
  },
  {
    "kind": "Variable",
    "name": "last",
    "variableName": "last"
  },
  {
    "kind": "Variable",
    "name": "options",
    "variableName": "options"
  },
  {
    "kind": "Variable",
    "name": "viewConfigInput",
    "variableName": "viewConfigInput"
  }
],
v17 = {
  "kind": "ScalarField",
  "name": "cursor"
},
v18 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v19 = {
  "kind": "ScalarField",
  "name": "id"
},
v20 = {
  "kind": "ScalarField",
  "name": "key"
},
v21 = {
  "kind": "ScalarField",
  "name": "fieldId"
},
v22 = {
  "kind": "ScalarField",
  "name": "statusCategoryId"
},
v23 = {
  "concreteType": "JiraStatusCategory",
  "kind": "LinkedField",
  "name": "statusCategory",
  "plural": false,
  "selections": [
    (v22/*: any*/),
    (v19/*: any*/)
  ]
},
v24 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "amountOfColumns"
},
v25 = [
  {
    "fields": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "issueType",
            "variableName": "issueTypeId"
          },
          {
            "kind": "Variable",
            "name": "project",
            "variableName": "projectId"
          }
        ],
        "kind": "ObjectValue",
        "name": "projectContext"
      }
    ],
    "kind": "ObjectValue",
    "name": "context"
  },
  {
    "kind": "Variable",
    "name": "filterId",
    "variableName": "filterId"
  },
  (v24/*: any*/),
  {
    "kind": "Variable",
    "name": "namespace",
    "variableName": "namespace"
  },
  {
    "kind": "Variable",
    "name": "viewId",
    "variableName": "viewId"
  }
],
v26 = {
  "kind": "ScalarField",
  "name": "name"
},
v27 = {
  "kind": "ScalarField",
  "name": "text"
},
v28 = {
  "kind": "ScalarField",
  "name": "picture"
},
v29 = {
  "concreteType": "JiraAvatar",
  "kind": "LinkedField",
  "name": "avatar",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "small"
    }
  ]
},
v30 = {
  "kind": "ScalarField",
  "name": "type"
},
v31 = {
  "kind": "ScalarField",
  "name": "fieldSetId"
},
v32 = {
  "kind": "TypeDiscriminator",
  "abstractKey": "__isJiraIssueField"
},
v33 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "JiraAffectedServiceConnection",
      "kind": "LinkedField",
      "name": "selectedAffectedServicesConnection",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraAffectedServiceEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "JiraAffectedService",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "serviceId"
                },
                (v26/*: any*/),
                (v19/*: any*/)
              ]
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraAffectedServicesField"
},
v34 = {
  "kind": "ScalarField",
  "name": "message"
},
v35 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "JiraCmdbObjectConnection",
      "kind": "LinkedField",
      "name": "selectedCmdbObjectsConnection",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraCmdbObjectEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "JiraCmdbObject",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "objectId"
                },
                {
                  "kind": "ScalarField",
                  "name": "label"
                },
                {
                  "concreteType": "JiraCmdbAvatar",
                  "kind": "LinkedField",
                  "name": "avatar",
                  "plural": false,
                  "selections": [
                    {
                      "condition": "__relay_internal__pv__atlassianjirarelayprovidersrccmdbmediaavatarfgrelayprovider",
                      "kind": "Condition",
                      "passingValue": true,
                      "selections": [
                        {
                          "concreteType": "JiraCmdbMediaClientConfig",
                          "kind": "LinkedField",
                          "name": "mediaClientConfig",
                          "plural": false,
                          "selections": [
                            {
                              "kind": "ScalarField",
                              "name": "clientId"
                            },
                            {
                              "kind": "ScalarField",
                              "name": "fileId"
                            },
                            {
                              "kind": "ScalarField",
                              "name": "mediaBaseUrl"
                            },
                            {
                              "kind": "ScalarField",
                              "name": "mediaJwtToken"
                            }
                          ]
                        }
                      ]
                    },
                    {
                      "kind": "ScalarField",
                      "name": "url48"
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          "concreteType": "QueryError",
          "kind": "LinkedField",
          "name": "errors",
          "plural": true,
          "selections": [
            (v34/*: any*/)
          ]
        }
      ]
    }
  ],
  "type": "JiraCMDBField"
},
v36 = [
  (v26/*: any*/),
  (v19/*: any*/)
],
v37 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "JiraComponentConnection",
      "kind": "LinkedField",
      "name": "selectedComponentsConnection",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraComponentEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "JiraComponent",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": (v36/*: any*/)
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraComponentsField"
},
v38 = {
  "kind": "ScalarField",
  "name": "date"
},
v39 = {
  "concreteType": "JiraFieldConfig",
  "kind": "LinkedField",
  "name": "fieldConfig",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "isRequired"
    }
  ]
},
v40 = {
  "kind": "ScalarField",
  "name": "lazyIsEditableInIssueView"
},
v41 = {
  "kind": "ClientExtension",
  "selections": [
    (v40/*: any*/)
  ]
},
v42 = {
  "kind": "InlineFragment",
  "selections": [
    (v38/*: any*/),
    (v26/*: any*/),
    (v39/*: any*/),
    (v41/*: any*/)
  ],
  "type": "JiraDatePickerField"
},
v43 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "dateTime"
    }
  ],
  "type": "JiraDateTimePickerField"
},
v44 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "JiraEpic",
      "kind": "LinkedField",
      "name": "epic",
      "plural": false,
      "selections": [
        (v20/*: any*/),
        {
          "kind": "ScalarField",
          "name": "summary"
        },
        {
          "kind": "ScalarField",
          "name": "color"
        },
        (v19/*: any*/)
      ]
    }
  ],
  "type": "JiraEpicLinkField"
},
v45 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "renderedFieldHtml"
    }
  ],
  "type": "JiraFallbackField"
},
v46 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "JiraStatusCategory",
      "kind": "LinkedField",
      "name": "statusCategory",
      "plural": false,
      "selections": [
        (v26/*: any*/),
        (v22/*: any*/),
        (v19/*: any*/)
      ]
    }
  ],
  "type": "JiraStatusCategoryField"
},
v47 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "JiraStatus",
      "kind": "LinkedField",
      "name": "status",
      "plural": false,
      "selections": [
        (v26/*: any*/),
        {
          "kind": "ScalarField",
          "name": "statusId"
        },
        (v23/*: any*/),
        (v19/*: any*/)
      ]
    }
  ],
  "type": "JiraStatusField"
},
v48 = [
  (v26/*: any*/)
],
v49 = {
  "kind": "ScalarField",
  "name": "totalCount"
},
v50 = {
  "args": [
    {
      "kind": "Literal",
      "name": "first",
      "value": 1000
    }
  ],
  "concreteType": "JiraLabelConnection",
  "kind": "LinkedField",
  "name": "selectedLabelsConnection",
  "plural": false,
  "selections": [
    {
      "concreteType": "JiraLabelEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "concreteType": "JiraLabel",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": (v48/*: any*/)
        }
      ]
    },
    (v49/*: any*/)
  ],
  "storageKey": "selectedLabelsConnection(first:1000)"
},
v51 = {
  "kind": "InlineFragment",
  "selections": [
    (v50/*: any*/),
    (v26/*: any*/),
    (v41/*: any*/)
  ],
  "type": "JiraLabelsField"
},
v52 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "JiraIssueType",
      "kind": "LinkedField",
      "name": "issueType",
      "plural": false,
      "selections": [
        (v29/*: any*/),
        (v26/*: any*/),
        (v19/*: any*/)
      ]
    }
  ],
  "type": "JiraIssueTypeField"
},
v53 = {
  "concreteType": "JiraAvatar",
  "kind": "LinkedField",
  "name": "avatar",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "xsmall"
    }
  ]
},
v54 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "JiraIssue",
      "kind": "LinkedField",
      "name": "parentIssue",
      "plural": false,
      "selections": [
        (v20/*: any*/),
        {
          "concreteType": "JiraSingleLineTextField",
          "kind": "LinkedField",
          "name": "summaryField",
          "plural": false,
          "selections": [
            (v27/*: any*/),
            (v19/*: any*/)
          ]
        },
        (v19/*: any*/),
        {
          "concreteType": "JiraColorField",
          "kind": "LinkedField",
          "name": "issueColorField",
          "plural": false,
          "selections": [
            {
              "concreteType": "JiraColor",
              "kind": "LinkedField",
              "name": "color",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "colorKey"
                },
                (v19/*: any*/)
              ]
            },
            (v19/*: any*/)
          ]
        },
        {
          "concreteType": "JiraIssueTypeField",
          "kind": "LinkedField",
          "name": "issueTypeField",
          "plural": false,
          "selections": [
            {
              "concreteType": "JiraIssueType",
              "kind": "LinkedField",
              "name": "issueType",
              "plural": false,
              "selections": [
                (v53/*: any*/),
                (v26/*: any*/),
                (v19/*: any*/)
              ]
            },
            (v19/*: any*/)
          ]
        }
      ]
    }
  ],
  "type": "JiraParentIssueField"
},
v55 = {
  "concreteType": "JiraPriority",
  "kind": "LinkedField",
  "name": "priority",
  "plural": false,
  "selections": [
    (v26/*: any*/),
    {
      "kind": "ScalarField",
      "name": "iconUrl"
    },
    (v19/*: any*/)
  ]
},
v56 = {
  "kind": "InlineFragment",
  "selections": [
    (v55/*: any*/),
    (v26/*: any*/),
    (v41/*: any*/)
  ],
  "type": "JiraPriorityField"
},
v57 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "JiraProject",
      "kind": "LinkedField",
      "name": "project",
      "plural": false,
      "selections": [
        (v26/*: any*/),
        (v20/*: any*/),
        {
          "concreteType": "JiraAvatar",
          "kind": "LinkedField",
          "name": "avatar",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "name": "large"
            }
          ]
        },
        (v19/*: any*/)
      ]
    }
  ],
  "type": "JiraProjectField"
},
v58 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "JiraResolution",
      "kind": "LinkedField",
      "name": "resolution",
      "plural": false,
      "selections": (v36/*: any*/)
    }
  ],
  "type": "JiraResolutionField"
},
v59 = {
  "concreteType": "JiraSprintConnection",
  "kind": "LinkedField",
  "name": "selectedSprintsConnection",
  "plural": false,
  "selections": [
    {
      "concreteType": "JiraSprintEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "concreteType": "JiraSprint",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v19/*: any*/),
            {
              "kind": "ScalarField",
              "name": "sprintId"
            },
            (v26/*: any*/),
            {
              "kind": "ScalarField",
              "name": "state"
            },
            {
              "kind": "ScalarField",
              "name": "endDate"
            }
          ]
        }
      ]
    }
  ]
},
v60 = {
  "kind": "InlineFragment",
  "selections": [
    (v59/*: any*/),
    (v26/*: any*/),
    (v41/*: any*/)
  ],
  "type": "JiraSprintField"
},
v61 = {
  "kind": "ScalarField",
  "name": "accountId"
},
v62 = {
  "kind": "ScalarField",
  "name": "accountStatus"
},
v63 = {
  "kind": "LinkedField",
  "name": "user",
  "plural": false,
  "selections": [
    (v18/*: any*/),
    (v61/*: any*/),
    (v28/*: any*/),
    (v26/*: any*/),
    (v19/*: any*/),
    (v62/*: any*/)
  ]
},
v64 = {
  "kind": "InlineFragment",
  "selections": [
    (v26/*: any*/),
    {
      "concreteType": "JiraVersionConnection",
      "kind": "LinkedField",
      "name": "selectedVersionsConnection",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraVersionEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "JiraVersion",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": (v36/*: any*/)
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraMultipleVersionPickerField"
},
v65 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "JiraRichText",
      "kind": "LinkedField",
      "name": "richText",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraADF",
          "kind": "LinkedField",
          "name": "adfValue",
          "plural": false,
          "selections": [
            {
              "concreteType": "JiraAdfToConvertedPlainText",
              "kind": "LinkedField",
              "name": "convertedPlainText",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "plainText"
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraRichTextField"
},
v66 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "JiraServiceManagementRequestType",
      "kind": "LinkedField",
      "name": "requestType",
      "plural": false,
      "selections": [
        (v26/*: any*/),
        (v53/*: any*/),
        (v19/*: any*/)
      ]
    }
  ],
  "type": "JiraServiceManagementRequestTypeField"
},
v67 = {
  "kind": "ScalarField",
  "name": "hasNextPage"
},
v68 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "first",
          "variableName": "__relay_internal__pv__atlassianjirarelayprovidersrcissuelinkscountrelayprovider"
        }
      ],
      "concreteType": "JiraIssueLinkConnection",
      "kind": "LinkedField",
      "name": "issueLinkConnection",
      "plural": false,
      "selections": [
        {
          "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcissuelinksentrypointrelayprovider",
          "kind": "Condition",
          "passingValue": false,
          "selections": [
            {
              "concreteType": "PageInfo",
              "kind": "LinkedField",
              "name": "pageInfo",
              "plural": false,
              "selections": [
                (v67/*: any*/)
              ]
            }
          ]
        },
        {
          "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcissuelinksentrypointrelayprovider",
          "kind": "Condition",
          "passingValue": true,
          "selections": [
            (v49/*: any*/)
          ]
        },
        {
          "concreteType": "JiraIssueLinkEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "JiraIssueLink",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "concreteType": "JiraIssue",
                  "kind": "LinkedField",
                  "name": "issue",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "name": "webUrl"
                    },
                    (v20/*: any*/),
                    {
                      "args": [
                        {
                          "kind": "Literal",
                          "name": "ids",
                          "value": [
                            "statusCategory"
                          ]
                        }
                      ],
                      "concreteType": "JiraIssueFieldConnection",
                      "kind": "LinkedField",
                      "name": "fieldsById",
                      "plural": false,
                      "selections": [
                        {
                          "concreteType": "JiraIssueFieldEdge",
                          "kind": "LinkedField",
                          "name": "edges",
                          "plural": true,
                          "selections": [
                            {
                              "kind": "LinkedField",
                              "name": "node",
                              "plural": false,
                              "selections": [
                                (v18/*: any*/),
                                {
                                  "kind": "InlineFragment",
                                  "selections": [
                                    (v23/*: any*/)
                                  ],
                                  "type": "JiraStatusCategoryField"
                                },
                                (v19/*: any*/)
                              ]
                            }
                          ]
                        }
                      ],
                      "storageKey": "fieldsById(ids:[\"statusCategory\"])"
                    },
                    (v19/*: any*/)
                  ]
                },
                {
                  "kind": "ScalarField",
                  "name": "relationName"
                },
                (v19/*: any*/)
              ]
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraIssueLinkField"
},
v69 = {
  "kind": "ScalarField",
  "name": "isStoryPointField"
},
v70 = {
  "kind": "ScalarField",
  "name": "number"
},
v71 = {
  "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcnikechinstorypointsinlineeditrelayprovider",
  "kind": "Condition",
  "passingValue": true,
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        (v69/*: any*/),
        (v70/*: any*/),
        (v26/*: any*/),
        (v41/*: any*/)
      ],
      "type": "JiraNumberField"
    }
  ]
},
v72 = {
  "condition": "shouldQueryFieldSetsById",
  "kind": "Condition",
  "passingValue": true,
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "fieldSetIds",
          "variableName": "fieldSetIds"
        },
        (v24/*: any*/)
      ],
      "concreteType": "JiraIssueFieldSetConnection",
      "kind": "LinkedField",
      "name": "fieldSetsById",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraIssueFieldSetEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "JiraIssueFieldSet",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v30/*: any*/),
                (v31/*: any*/),
                {
                  "concreteType": "JiraIssueFieldConnection",
                  "kind": "LinkedField",
                  "name": "fields",
                  "plural": false,
                  "selections": [
                    {
                      "concreteType": "JiraIssueFieldEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            (v18/*: any*/),
                            (v21/*: any*/),
                            (v30/*: any*/),
                            (v32/*: any*/),
                            (v19/*: any*/),
                            (v33/*: any*/),
                            (v35/*: any*/),
                            (v37/*: any*/),
                            (v42/*: any*/),
                            (v43/*: any*/),
                            (v44/*: any*/),
                            (v45/*: any*/),
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v27/*: any*/),
                                (v26/*: any*/),
                                (v41/*: any*/)
                              ],
                              "type": "JiraSingleLineTextField"
                            },
                            (v46/*: any*/),
                            (v47/*: any*/),
                            (v51/*: any*/),
                            (v52/*: any*/),
                            (v54/*: any*/),
                            (v56/*: any*/),
                            (v57/*: any*/),
                            (v58/*: any*/),
                            (v60/*: any*/),
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v63/*: any*/),
                                (v26/*: any*/),
                                (v39/*: any*/),
                                (v41/*: any*/)
                              ],
                              "type": "JiraSingleSelectUserPickerField"
                            },
                            (v64/*: any*/),
                            (v65/*: any*/),
                            (v66/*: any*/),
                            (v68/*: any*/),
                            (v71/*: any*/)
                          ]
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
},
v73 = {
  "condition": "shouldQueryFieldSetsById",
  "kind": "Condition",
  "passingValue": false,
  "selections": [
    {
      "args": (v25/*: any*/),
      "concreteType": "JiraIssueFieldSetConnection",
      "kind": "LinkedField",
      "name": "fieldSetsForIssueSearchView",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraIssueFieldSetEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "JiraIssueFieldSet",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v30/*: any*/),
                (v31/*: any*/),
                {
                  "concreteType": "JiraIssueFieldConnection",
                  "kind": "LinkedField",
                  "name": "fields",
                  "plural": false,
                  "selections": [
                    {
                      "concreteType": "JiraIssueFieldEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            (v21/*: any*/),
                            (v30/*: any*/),
                            (v32/*: any*/),
                            (v33/*: any*/),
                            (v35/*: any*/),
                            (v37/*: any*/),
                            (v42/*: any*/),
                            (v43/*: any*/),
                            (v44/*: any*/),
                            (v45/*: any*/),
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v30/*: any*/),
                                (v41/*: any*/)
                              ],
                              "type": "JiraSingleLineTextField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                {
                                  "concreteType": "JiraStatusCategory",
                                  "kind": "LinkedField",
                                  "name": "statusCategory",
                                  "plural": false,
                                  "selections": (v48/*: any*/)
                                }
                              ],
                              "type": "JiraStatusCategoryField"
                            },
                            (v47/*: any*/),
                            (v51/*: any*/),
                            (v54/*: any*/),
                            (v56/*: any*/),
                            (v57/*: any*/),
                            (v58/*: any*/),
                            (v60/*: any*/),
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v30/*: any*/),
                                {
                                  "kind": "LinkedField",
                                  "name": "user",
                                  "plural": false,
                                  "selections": [
                                    (v61/*: any*/),
                                    (v62/*: any*/)
                                  ]
                                },
                                (v39/*: any*/),
                                (v41/*: any*/)
                              ],
                              "type": "JiraSingleSelectUserPickerField"
                            },
                            (v64/*: any*/),
                            (v65/*: any*/),
                            (v66/*: any*/),
                            (v68/*: any*/),
                            (v71/*: any*/)
                          ]
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
},
v74 = {
  "kind": "ScalarField",
  "name": "pageNumber"
},
v75 = {
  "kind": "ScalarField",
  "name": "isCurrent"
},
v76 = [
  (v74/*: any*/),
  (v17/*: any*/),
  (v75/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/),
      (v10/*: any*/),
      (v11/*: any*/),
      (v12/*: any*/),
      (v13/*: any*/),
      (v14/*: any*/),
      (v15/*: any*/)
    ],
    "kind": "Fragment",
    "name": "IssueNavigatorIssueSearchPaginationQuery",
    "selections": [
      {
        "kind": "FragmentSpread",
        "name": "main_issueNavigator_IssueNavigator_issueSearchData"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/),
      (v10/*: any*/),
      (v11/*: any*/),
      (v12/*: any*/),
      (v13/*: any*/),
      (v14/*: any*/),
      (v15/*: any*/),
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcisbulkopsinninenabledrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcjscm2apiupdatesrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcjscm1apiupdatesrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcnikechinstorypointsinlineeditrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrccmdbmediaavatarfgrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcissuelinksentrypointrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcissuelinkscountrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcisjscinfinitescrollenabledrelayprovider"
      }
    ],
    "kind": "Operation",
    "name": "IssueNavigatorIssueSearchPaginationQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v16/*: any*/),
            "concreteType": "JiraIssueConnection",
            "kind": "LinkedField",
            "name": "issueSearch",
            "plural": false,
            "selections": [
              {
                "concreteType": "JiraIssueEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  (v17/*: any*/),
                  {
                    "concreteType": "JiraIssue",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v18/*: any*/),
                      (v19/*: any*/),
                      (v20/*: any*/),
                      {
                        "kind": "ScalarField",
                        "name": "issueId"
                      },
                      {
                        "kind": "ScalarField",
                        "name": "isResolved"
                      },
                      {
                        "args": [
                          {
                            "kind": "Literal",
                            "name": "ids",
                            "value": [
                              "status"
                            ]
                          }
                        ],
                        "concreteType": "JiraIssueFieldConnection",
                        "kind": "LinkedField",
                        "name": "fieldsById",
                        "plural": false,
                        "selections": [
                          {
                            "concreteType": "JiraIssueFieldEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v18/*: any*/),
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      (v21/*: any*/),
                                      {
                                        "concreteType": "JiraStatus",
                                        "kind": "LinkedField",
                                        "name": "status",
                                        "plural": false,
                                        "selections": [
                                          (v23/*: any*/),
                                          (v19/*: any*/)
                                        ]
                                      }
                                    ],
                                    "type": "JiraStatusField"
                                  },
                                  (v19/*: any*/)
                                ]
                              }
                            ]
                          }
                        ],
                        "storageKey": "fieldsById(ids:[\"status\"])"
                      },
                      {
                        "args": (v25/*: any*/),
                        "concreteType": "JiraIssueFieldSetConnection",
                        "kind": "LinkedField",
                        "name": "fieldSetsForIssueSearchView",
                        "plural": false,
                        "selections": [
                          {
                            "concreteType": "JiraIssueFieldSetEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "concreteType": "JiraIssueFieldSet",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  {
                                    "concreteType": "JiraIssueFieldConnection",
                                    "kind": "LinkedField",
                                    "name": "fields",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "concreteType": "JiraIssueFieldEdge",
                                        "kind": "LinkedField",
                                        "name": "edges",
                                        "plural": true,
                                        "selections": [
                                          {
                                            "kind": "LinkedField",
                                            "name": "node",
                                            "plural": false,
                                            "selections": [
                                              (v18/*: any*/),
                                              {
                                                "kind": "InlineFragment",
                                                "selections": [
                                                  (v21/*: any*/),
                                                  (v26/*: any*/),
                                                  (v27/*: any*/)
                                                ],
                                                "type": "JiraSingleLineTextField"
                                              },
                                              (v19/*: any*/),
                                              {
                                                "kind": "InlineFragment",
                                                "selections": [
                                                  (v21/*: any*/),
                                                  (v26/*: any*/),
                                                  {
                                                    "kind": "LinkedField",
                                                    "name": "user",
                                                    "plural": false,
                                                    "selections": [
                                                      (v18/*: any*/),
                                                      (v26/*: any*/),
                                                      (v28/*: any*/),
                                                      (v19/*: any*/)
                                                    ]
                                                  }
                                                ],
                                                "type": "JiraSingleSelectUserPickerField"
                                              },
                                              {
                                                "kind": "InlineFragment",
                                                "selections": [
                                                  (v21/*: any*/),
                                                  {
                                                    "concreteType": "JiraIssueType",
                                                    "kind": "LinkedField",
                                                    "name": "issueType",
                                                    "plural": false,
                                                    "selections": [
                                                      (v26/*: any*/),
                                                      (v29/*: any*/),
                                                      (v19/*: any*/)
                                                    ]
                                                  }
                                                ],
                                                "type": "JiraIssueTypeField"
                                              },
                                              {
                                                "kind": "InlineFragment",
                                                "selections": [
                                                  (v21/*: any*/),
                                                  (v23/*: any*/)
                                                ],
                                                "type": "JiraStatusCategoryField"
                                              }
                                            ]
                                          }
                                        ]
                                      }
                                    ]
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      },
                      {
                        "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjscm2apiupdatesrelayprovider",
                        "kind": "Condition",
                        "passingValue": true,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "name": "hasChildren"
                          },
                          {
                            "concreteType": "JiraIssueTypeField",
                            "kind": "LinkedField",
                            "name": "issueTypeField",
                            "plural": false,
                            "selections": [
                              {
                                "concreteType": "JiraIssueType",
                                "kind": "LinkedField",
                                "name": "issueType",
                                "plural": false,
                                "selections": [
                                  {
                                    "concreteType": "JiraIssueTypeHierarchyLevel",
                                    "kind": "LinkedField",
                                    "name": "hierarchy",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "kind": "ScalarField",
                                        "name": "level"
                                      }
                                    ]
                                  },
                                  (v19/*: any*/)
                                ]
                              },
                              (v19/*: any*/)
                            ]
                          }
                        ]
                      },
                      {
                        "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjscm1apiupdatesrelayprovider",
                        "kind": "Condition",
                        "passingValue": false,
                        "selections": [
                          (v72/*: any*/),
                          (v73/*: any*/)
                        ]
                      },
                      {
                        "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjscm1apiupdatesrelayprovider",
                        "kind": "Condition",
                        "passingValue": true,
                        "selections": [
                          (v72/*: any*/),
                          (v73/*: any*/),
                          {
                            "kind": "ClientExtension",
                            "selections": [
                              {
                                "concreteType": "JiraIssueFieldSetConnection",
                                "kind": "LinkedField",
                                "name": "issueRowFieldSets",
                                "plural": false,
                                "selections": [
                                  {
                                    "concreteType": "JiraIssueFieldSetEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "concreteType": "JiraIssueFieldSet",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": [
                                          (v30/*: any*/),
                                          (v31/*: any*/),
                                          {
                                            "concreteType": "JiraIssueFieldConnection",
                                            "kind": "LinkedField",
                                            "name": "fields",
                                            "plural": false,
                                            "selections": [
                                              {
                                                "concreteType": "JiraIssueFieldEdge",
                                                "kind": "LinkedField",
                                                "name": "edges",
                                                "plural": true,
                                                "selections": [
                                                  {
                                                    "kind": "LinkedField",
                                                    "name": "node",
                                                    "plural": false,
                                                    "selections": [
                                                      (v18/*: any*/),
                                                      (v21/*: any*/),
                                                      (v30/*: any*/),
                                                      (v32/*: any*/),
                                                      (v19/*: any*/),
                                                      (v33/*: any*/),
                                                      (v35/*: any*/),
                                                      (v37/*: any*/),
                                                      {
                                                        "kind": "InlineFragment",
                                                        "selections": [
                                                          (v38/*: any*/),
                                                          (v40/*: any*/),
                                                          (v26/*: any*/),
                                                          (v39/*: any*/)
                                                        ],
                                                        "type": "JiraDatePickerField"
                                                      },
                                                      (v43/*: any*/),
                                                      (v44/*: any*/),
                                                      (v45/*: any*/),
                                                      {
                                                        "kind": "InlineFragment",
                                                        "selections": [
                                                          (v27/*: any*/),
                                                          (v40/*: any*/),
                                                          (v26/*: any*/)
                                                        ],
                                                        "type": "JiraSingleLineTextField"
                                                      },
                                                      (v46/*: any*/),
                                                      (v47/*: any*/),
                                                      {
                                                        "kind": "InlineFragment",
                                                        "selections": [
                                                          (v50/*: any*/),
                                                          (v40/*: any*/),
                                                          (v26/*: any*/)
                                                        ],
                                                        "type": "JiraLabelsField"
                                                      },
                                                      (v52/*: any*/),
                                                      (v54/*: any*/),
                                                      {
                                                        "kind": "InlineFragment",
                                                        "selections": [
                                                          (v40/*: any*/),
                                                          (v55/*: any*/),
                                                          (v26/*: any*/)
                                                        ],
                                                        "type": "JiraPriorityField"
                                                      },
                                                      (v57/*: any*/),
                                                      (v58/*: any*/),
                                                      {
                                                        "kind": "InlineFragment",
                                                        "selections": [
                                                          (v40/*: any*/),
                                                          (v59/*: any*/),
                                                          (v26/*: any*/)
                                                        ],
                                                        "type": "JiraSprintField"
                                                      },
                                                      {
                                                        "kind": "InlineFragment",
                                                        "selections": [
                                                          (v63/*: any*/),
                                                          (v40/*: any*/),
                                                          (v26/*: any*/),
                                                          (v39/*: any*/)
                                                        ],
                                                        "type": "JiraSingleSelectUserPickerField"
                                                      },
                                                      (v64/*: any*/),
                                                      (v65/*: any*/),
                                                      (v66/*: any*/),
                                                      (v68/*: any*/),
                                                      {
                                                        "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcnikechinstorypointsinlineeditrelayprovider",
                                                        "kind": "Condition",
                                                        "passingValue": true,
                                                        "selections": [
                                                          {
                                                            "kind": "InlineFragment",
                                                            "selections": [
                                                              (v69/*: any*/),
                                                              (v70/*: any*/),
                                                              (v40/*: any*/),
                                                              (v26/*: any*/)
                                                            ],
                                                            "type": "JiraNumberField"
                                                          }
                                                        ]
                                                      }
                                                    ]
                                                  }
                                                ]
                                              }
                                            ]
                                          }
                                        ]
                                      }
                                    ]
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      },
                      {
                        "kind": "ClientExtension",
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "name": "isHighlightedIssueRow"
                          }
                        ]
                      }
                    ]
                  },
                  (v18/*: any*/)
                ]
              },
              {
                "concreteType": "JiraIssueNavigatorPageInfo",
                "kind": "LinkedField",
                "name": "issueNavigatorPageInfo",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "firstIssuePosition"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "lastIssuePosition"
                  }
                ]
              },
              {
                "args": [
                  {
                    "kind": "Literal",
                    "name": "maxCursors",
                    "value": 7
                  }
                ],
                "concreteType": "JiraPageCursors",
                "kind": "LinkedField",
                "name": "pageCursors",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraPageCursor",
                    "kind": "LinkedField",
                    "name": "around",
                    "plural": true,
                    "selections": (v76/*: any*/)
                  },
                  {
                    "concreteType": "JiraPageCursor",
                    "kind": "LinkedField",
                    "name": "last",
                    "plural": false,
                    "selections": (v76/*: any*/)
                  },
                  {
                    "concreteType": "JiraPageCursor",
                    "kind": "LinkedField",
                    "name": "first",
                    "plural": false,
                    "selections": [
                      (v17/*: any*/),
                      (v74/*: any*/),
                      (v75/*: any*/)
                    ]
                  }
                ],
                "storageKey": "pageCursors(maxCursors:7)"
              },
              {
                "kind": "ScalarField",
                "name": "totalIssueSearchResultCount"
              },
              {
                "kind": "LinkedField",
                "name": "issueSearchError",
                "plural": false,
                "selections": [
                  (v18/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "name": "messages"
                      }
                    ],
                    "type": "JiraInvalidJqlError"
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v34/*: any*/),
                      {
                        "kind": "ScalarField",
                        "name": "errorType"
                      }
                    ],
                    "type": "JiraInvalidSyntaxError"
                  }
                ]
              },
              {
                "kind": "ScalarField",
                "name": "isCappingIssueSearchResult"
              },
              {
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "endCursor"
                  },
                  (v67/*: any*/),
                  {
                    "kind": "ScalarField",
                    "name": "hasPreviousPage"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "startCursor"
                  }
                ]
              },
              {
                "kind": "ClientExtension",
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "__id"
                  }
                ]
              },
              {
                "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcisbulkopsinninenabledrelayprovider",
                "kind": "Condition",
                "passingValue": true,
                "selections": [
                  {
                    "concreteType": "JiraIssueEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "concreteType": "JiraIssue",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "args": (v25/*: any*/),
                            "concreteType": "JiraIssueFieldSetConnection",
                            "kind": "LinkedField",
                            "name": "fieldSetsForIssueSearchView",
                            "plural": false,
                            "selections": [
                              {
                                "concreteType": "JiraIssueFieldSetEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "concreteType": "JiraIssueFieldSet",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "concreteType": "JiraIssueFieldConnection",
                                        "kind": "LinkedField",
                                        "name": "fields",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "concreteType": "JiraIssueFieldEdge",
                                            "kind": "LinkedField",
                                            "name": "edges",
                                            "plural": true,
                                            "selections": [
                                              {
                                                "kind": "LinkedField",
                                                "name": "node",
                                                "plural": false,
                                                "selections": [
                                                  (v32/*: any*/),
                                                  (v21/*: any*/)
                                                ]
                                              }
                                            ]
                                          }
                                        ]
                                      }
                                    ]
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            "args": (v16/*: any*/),
            "filters": [
              "cloudId",
              "issueSearchInput",
              "options",
              "viewConfigInput"
            ],
            "handle": "connection",
            "key": "IssueNavigatorIssueSearchPagination__issueSearch",
            "kind": "LinkedHandle",
            "name": "issueSearch"
          }
        ]
      }
    ]
  },
  "params": {
    "id": "d9d8ac3393a5f801f0230f5c8f4cbe2ea9c173fe53d6563473bfd82a5c4a5532",
    "metadata": {},
    "name": "IssueNavigatorIssueSearchPaginationQuery",
    "operationKind": "query",
    "text": null,
    "providedVariables": {
      "__relay_internal__pv__atlassianjirarelayprovidersrcisbulkopsinninenabledrelayprovider": bulkOpsInNinEnabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcjscm2apiupdatesrelayprovider": isChildIssueTotalCountEnabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcjscm1apiupdatesrelayprovider": isJscM1ApiUpdatesEnabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcnikechinstorypointsinlineeditrelayprovider": isNikeChinStoryPointsInlineEditEnabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrccmdbmediaavatarfgrelayprovider": isCmdbMediaAvatarEnabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcissuelinksentrypointrelayprovider": isEntryPointEnabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcissuelinkscountrelayprovider": count_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcisjscinfinitescrollenabledrelayprovider": isJscInfiniteScrollEnabled_provider
    }
  }
};
})();

(node as any).hash = "bdcb9231bcd4f7f0baea2d7ced5b03de";

export default node;
