import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	permissionErrorHeader: {
		id: 'issue-view-errors.permission-error-view.permission-error-header',
		defaultMessage: "You don't have access to this issue",
		description: '',
	},
	permissionErrorDescription: {
		id: 'issue-view-errors.permission-error-view.permission-error-description',
		defaultMessage:
			"Make sure the issue exists in this project. If it does, ask a project admin for permission to see the project's issues.",
		description: '',
	},
	permissionErrorHeaderIssueTermRefresh: {
		id: 'issue-view-errors.permission-error-view.permission-error-header-issue-term-refresh',
		defaultMessage: "You don't have access to this work item",
		description: '',
	},
	permissionErrorDescriptionIssueTermRefresh: {
		id: 'issue-view-errors.permission-error-view.permission-error-description-issue-term-refresh',
		defaultMessage:
			'Make sure the work item exists in this project. If it does, ask a project admin for permission to see work in this project.',
		description: '',
	},
});
