import React, {
	// eslint-disable-next-line jira/restricted/react-component-props
	type ComponentProps,
	type ComponentType,
	type ReactNode,
	useLayoutEffect,
	memo,
	type PropsWithChildren,
} from 'react';
import {
	graphql,
	type PreloadedQuery,
	type RefetchFn,
	usePreloadedQuery,
	useRefetchableFragment,
	useFragment,
	usePaginationFragment,
} from 'react-relay';
import { expVal } from '@atlassian/jira-feature-experiments';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { fg } from '@atlassian/jira-feature-gating';
import type { FilterRefetchQuery } from '@atlassian/jira-relay/src/__generated__/FilterRefetchQuery.graphql';
import type { IssueNavigatorIssueSearchPaginationQuery } from '@atlassian/jira-relay/src/__generated__/IssueNavigatorIssueSearchPaginationQuery.graphql';
import type { IssueNavigatorIssueSearchRefetchQuery } from '@atlassian/jira-relay/src/__generated__/IssueNavigatorIssueSearchRefetchQuery.graphql';
import IssueNavigatorRefetchQueryOld, {
	type IssueNavigatorResultsRefetchQuery as IssueNavigatorResultsRefetchQueryOld,
} from '@atlassian/jira-relay/src/__generated__/IssueNavigatorResultsRefetchQuery.graphql';
import type { main_FilterQuery as FilterQueryType } from '@atlassian/jira-relay/src/__generated__/main_FilterQuery.graphql';
import type { main_issueNavigator_IssueNavigator_filterQuery$key as FilterFragment } from '@atlassian/jira-relay/src/__generated__/main_issueNavigator_IssueNavigator_filterQuery.graphql';
import type { main_issueNavigator_IssueNavigator_issueQuery$key as IssueSearchConditionFragment } from '@atlassian/jira-relay/src/__generated__/main_issueNavigator_IssueNavigator_issueQuery.graphql';
import type { main_issueNavigator_IssueNavigator_issueSearchData$key as IssueSearchDataFragment } from '@atlassian/jira-relay/src/__generated__/main_issueNavigator_IssueNavigator_issueSearchData.graphql';
import type { main_issueNavigator_IssueNavigator_jira$key as JqlBuilderWithAiKey } from '@atlassian/jira-relay/src/__generated__/main_issueNavigator_IssueNavigator_jira.graphql';
import type { main_issueNavigator_IssueNavigator_refetchQueryNew$key as IssueNavigatorRefetchNewFragment } from '@atlassian/jira-relay/src/__generated__/main_issueNavigator_IssueNavigator_refetchQueryNew.graphql';
import type { main_issueNavigator_IssueNavigator_refetchQueryOld$key as IssueNavigatorRefetchOldFragment } from '@atlassian/jira-relay/src/__generated__/main_issueNavigator_IssueNavigator_refetchQueryOld.graphql';
import type { main_issueNavigator_IssueNavigator_userPreferences$key as UserPreferencesFragmentKey } from '@atlassian/jira-relay/src/__generated__/main_issueNavigator_IssueNavigator_userPreferences.graphql';
import type { main_IssueNavigatorQuery as IssueNavigatorQueryType } from '@atlassian/jira-relay/src/__generated__/main_IssueNavigatorQuery.graphql';
import type { selectedIssue_issueNavigator$key as SelectedIssueFragment } from '@atlassian/jira-relay/src/__generated__/selectedIssue_issueNavigator.graphql';
import type { selectedIssueStateOld_issueNavigator_SelectedIssueContainer$key as SelectedIssueOldFragment } from '@atlassian/jira-relay/src/__generated__/selectedIssueStateOld_issueNavigator_SelectedIssueContainer.graphql';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { FilterTypes, SearchInputTypes } from './common/constants.tsx';
import type {
	CustomHeaderProps,
	FilterId,
	FilterJql,
	SearchInputType,
	IssueNavigatorViewId,
	OverridableIssueTableProps,
} from './common/types.tsx';
import { markOnce, marks } from './common/utils/performance-analytics.tsx';
import { AppContextualAnalytics } from './controllers/app-contextual-analytics/index.tsx';
import { SelectedIssueContainer as SelectedIssueContainerOld } from './controllers/selected-issue-state-old/index.tsx';
import { ActiveJqlProvider } from './services/active-jql/index.tsx';
import { FilterQueryProvider } from './services/filter-query/index.tsx';
import { IssueSearchQueryProvider } from './services/issue-search-query/index.tsx';
import { IssueSearch } from './ui/issue-search/index.tsx';
import type { OverridableJqlBuilderProps } from './ui/jql-builder/index.tsx';
import IssueNavigatorUI from './ui/main.tsx';
import { SelectedIssue as SelectedIssueNew } from './ui/selected-issue/index.tsx';
import { SelectedViewContainer } from './ui/selected-view/index.tsx';

export type Props = {
	issueKey: IssueKey;
	CustomHeader?: ComponentType<CustomHeaderProps>;
	ActionMenu?: ComponentType<CustomHeaderProps>;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	HeaderSkeletonImage: ComponentType<Record<any, any>>;
	defaultJql: string;
	searchInput: SearchInputType;
	queryReference: PreloadedQuery<IssueNavigatorQueryType>;
	filterQueryReference: PreloadedQuery<FilterQueryType>;
	onChangeIssue: JSX.LibraryManagedAttributes<
		typeof IssueNavigatorUI,
		ComponentProps<typeof IssueNavigatorUI>
	>['onChangeIssue'];
	onChangePage?: () => void;
	onChangeFilter?: (filterId?: string) => void;
	onRefinement?: () => void;
	/**
	 * Event emitted when the page has loaded and the key experience is interactive. There are several different user
	 * landing states this encompasses:
	 * - Detail view is rendered with issues loaded
	 * - List view is rendered with issues loaded
	 * - Full page issue app pagination controls are rendered (i.e. list view with an issue selected)
	 */
	onPageDataLoad?: (selectedView: IssueNavigatorViewId) => void;
	onChangeColumnConfiguration?: () => void;
	onChangeJql?: JSX.LibraryManagedAttributes<
		typeof IssueNavigatorUI,
		ComponentProps<typeof IssueNavigatorUI>
	>['onChangeJql'];
	onSetView: (view: IssueNavigatorViewId, issueKey?: IssueKey) => void;
	jqlBuilderProps?: OverridableJqlBuilderProps;
	/**
	 * Removes give feedback button from footer
	 */
	// TODO Remove when cleaning up jira_spreadsheet_component_m1_api_updates
	isFeedbackButtonDisabled?: boolean;
	/**
	 * This overrides a subset of issue table props when rendering the issue table in NIN.
	 */
	issueTableProps?: OverridableIssueTableProps;

	/**
	 * This prop is used to determine if the issue hierarchy feature is enabled in the experience.
	 */
	isIssueHierarchySupportEnabled?: boolean;
};

export const FilterQuery = graphql`
	query main_FilterQuery($filterAri: ID!, $includeFilter: Boolean!) {
		...main_issueNavigator_IssueNavigator_filterQuery
	}
`;

type FilterAndJqlProviderProps = {
	children: ReactNode;
	jql: string | undefined;
	refetch: RefetchFn<FilterRefetchQuery>;
	filterId: string | undefined;
	isFilterEditable: boolean | undefined;
	filterJql?: string;
};

// We memo as an optimisation to reduce forced re-renders when the immediate parent renders. In this case it's
// AppContextualAnalytics which will re-render when interacting with the JQL builder, e.g. opening picker.
const FilterAndJqlProvider = memo(
	({
		children,
		jql,
		refetch,
		filterId,
		isFilterEditable,
		filterJql,
	}: FilterAndJqlProviderProps) => (
		<FilterQueryProvider query={FilterQuery} refetch={refetch} filterId={filterId}>
			<ActiveJqlProvider
				filterId={filterId}
				filterJql={filterJql}
				jql={jql}
				isFilterEditable={isFilterEditable}
			>
				{children}
			</ActiveJqlProvider>
		</FilterQueryProvider>
	),
);

const getFilter = (searchParam: SearchInputType): FilterJql | FilterId | undefined => {
	if (searchParam?.type === SearchInputTypes.JQL) {
		return undefined;
	}
	return searchParam.type === SearchInputTypes.FILTER_AND_JQL ? searchParam.filter : searchParam;
};

const MaybeIssueSearch = componentWithCondition(
	() => expVal('jira_spreadsheet_component_m1', 'isInfiniteScrollingEnabled', false),
	IssueSearch,
	({ children }: PropsWithChildren<{}>) => <>{children}</>,
);
const MaybeIssueSearchQueryProvider = componentWithCondition(
	() => expVal('jira_spreadsheet_component_m1', 'isInfiniteScrollingEnabled', false),
	({ children }: PropsWithChildren<{}>) => <>{children}</>,
	IssueSearchQueryProvider,
);

type SelectedIssueProps = {
	issuesOld: SelectedIssueOldFragment | null;
	issues: SelectedIssueFragment | null;
	selectedIssueKey: IssueKey;
	onChange: (issueKey: IssueKey, isSelectedByUserInteraction: boolean) => void;
};

const SelectedIssue = componentWithCondition(
	() => expVal('jira_spreadsheet_component_m1', 'isInfiniteScrollingEnabled', false),
	({ children, issues, onChange, selectedIssueKey }: PropsWithChildren<SelectedIssueProps>) => (
		<SelectedIssueNew issues={issues} onChange={onChange} selectedIssueKey={selectedIssueKey}>
			{children}
		</SelectedIssueNew>
	),
	({ children, issuesOld, onChange, selectedIssueKey }: PropsWithChildren<SelectedIssueProps>) => (
		<SelectedIssueContainerOld
			issueResults={issuesOld}
			onChange={onChange}
			selectedIssueKey={selectedIssueKey}
		>
			{children}
		</SelectedIssueContainerOld>
	),
);

const IssueNavigator = ({
	issueKey,
	queryReference,
	filterQueryReference,
	CustomHeader,
	ActionMenu,
	HeaderSkeletonImage,
	onChangeIssue,
	onChangeFilter,
	onRefinement,
	onChangePage,
	onPageDataLoad,
	onChangeColumnConfiguration,
	onChangeJql,
	onSetView,
	defaultJql,
	searchInput,
	jqlBuilderProps,
	isFeedbackButtonDisabled,
	issueTableProps,
	isIssueHierarchySupportEnabled,
}: Props) => {
	markOnce(marks.ISSUE_NAVIGATOR_START);
	useLayoutEffect(() => {
		markOnce(marks.ISSUE_NAVIGATOR_END);
	}, []);

	const filterInput = searchInput ? getFilter(searchInput) : undefined;

	const issueQuery = usePreloadedQuery<IssueNavigatorQueryType>(
		graphql`
			query main_IssueNavigatorQuery(
				$cloudId: ID!
				$issueSearchInput: JiraIssueSearchInput!
				$first: Int
				$last: Int
				$before: String
				$after: String
				$namespace: String
				$viewId: String
				$options: JiraIssueSearchOptions
				$filterId: String
				$fieldSetIds: [String!]!
				$shouldQueryFieldSetsById: Boolean!
				$amountOfColumns: Int!
				$atlassianIntelligenceProductFeature: JiraAtlassianIntelligenceFeatureEnum!
				$issueTypeId: ID
				$projectId: ID
				$projectKey: String!
				$shouldQueryProject: Boolean!
				$staticViewInput: JiraIssueSearchStaticViewInput
				$viewConfigInput: JiraIssueSearchViewConfigInput
			) {
				...main_issueNavigator_IssueNavigator_issueQuery
				jira {
					userPreferences(cloudId: $cloudId) @optIn(to: "JiraUserPreferences") {
						...main_issueNavigator_IssueNavigator_userPreferences
					}
					...main_issueNavigator_IssueNavigator_jira
					jiraProjectByKey(cloudId: $cloudId, key: $projectKey) @include(if: $shouldQueryProject) {
						...main_issueNavigator_IssueNavigatorUI_project
					}
				}
			}
		`,
		queryReference,
	);

	const issueDataCondition = useFragment<IssueSearchConditionFragment>(
		graphql`
			fragment main_issueNavigator_IssueNavigator_issueQuery on Query
			@argumentDefinitions(
				isJscInfiniteScrollEnabled: {
					type: "Boolean!"
					provider: "@atlassian/jira-relay-provider/src/is-jsc-infinite-scroll-enabled.relayprovider"
				}
			) {
				...main_issueNavigator_IssueNavigator_refetchQueryOld @skip(if: $isJscInfiniteScrollEnabled)
				...main_issueNavigator_IssueNavigator_refetchQueryNew
					@include(if: $isJscInfiniteScrollEnabled)
			}
		`,
		issueQuery,
	);

	const refetchQueryOld = expVal(
		'jira_spreadsheet_component_m1',
		'isInfiniteScrollingEnabled',
		false,
	)
		? null
		: issueDataCondition;
	const refetchQueryNew = expVal(
		'jira_spreadsheet_component_m1',
		'isInfiniteScrollingEnabled',
		false,
	)
		? issueDataCondition
		: null;

	/* eslint-disable @atlassian/relay/unused-fields */
	const [issueNavigatorRefetchDataOld, issueNavigatorRefetchOld] = useRefetchableFragment<
		IssueNavigatorResultsRefetchQueryOld,
		IssueNavigatorRefetchOldFragment
	>(
		graphql`
			fragment main_issueNavigator_IssueNavigator_refetchQueryOld on Query
			@refetchable(queryName: "IssueNavigatorResultsRefetchQuery") {
				jira {
					issueSearchStable(
						cloudId: $cloudId
						issueSearchInput: $issueSearchInput
						first: $first
						last: $last
						before: $before
						after: $after
						options: $options
					) {
						edges {
							node {
								key
							}
						}
						...issueSearchQuery_issueNavigator_IssueSearchQueryProvider_issueResults
						...selectedIssueStateOld_issueNavigator_SelectedIssueContainer
						...main_issueNavigator_IssueNavigatorUI_issueResults
					}
					issueSearchViewResult(
						cloudId: $cloudId
						namespace: $namespace
						viewId: $viewId
						filterId: $filterId
						issueSearchInput: $issueSearchInput
					) @optIn(to: "JiraIssueSearch") {
						... on JiraIssueSearchView {
							__typename
							...issueSearchQuery_issueNavigator_IssueSearchQueryProvider_view
							...selectedView_issueNavigator_SelectedViewContainer
						}
						...main_issueNavigator_IssueNavigatorUI_viewResult
					}
				}
			}
		`,
		refetchQueryOld,
	);
	/* eslint-enable @atlassian/relay/unused-fields */

	const [issueNavigatorRefetchDataNew, issueNavigatorRefetchNew] = useRefetchableFragment<
		IssueNavigatorIssueSearchRefetchQuery,
		IssueNavigatorRefetchNewFragment
	>(
		graphql`
			fragment main_issueNavigator_IssueNavigator_refetchQueryNew on Query
			@refetchable(queryName: "IssueNavigatorIssueSearchRefetchQuery")
			@argumentDefinitions(
				isRefactorNinToViewSchemaEnabled: {
					type: "Boolean!"
					provider: "@atlassian/jira-relay-provider/src/is-refactor-nin-to-view-schema-enabled.relayprovider"
				}
			) {
				...main_issueNavigator_IssueNavigator_issueSearchData
				jira {
					jiraIssueSearchView(
						cloudId: $cloudId
						namespace: $namespace
						viewId: $viewId
						issueSearchInput: $issueSearchInput
					) @optIn(to: "JiraIssueSearchView") @include(if: $isRefactorNinToViewSchemaEnabled) {
						__typename
						... on JiraGroupedListView {
							groups(
								issueSearchInput: $issueSearchInput
								first: $first
								last: $last
								before: $before
								after: $after
							) {
								...main_issueNavigator_IssueNavigatorUI_groupResults
							}
						}
					}
					issueSearchViewResult(
						cloudId: $cloudId
						namespace: $namespace
						viewId: $viewId
						filterId: $filterId
						issueSearchInput: $issueSearchInput
					) @optIn(to: "JiraIssueSearch") {
						... on JiraIssueSearchView {
							__typename
							...issueSearch_issueNavigator
							...selectedView_issueNavigator_SelectedViewContainer
						}
						...main_issueNavigator_IssueNavigatorUI_viewResult
					}
				}
			}
		`,
		refetchQueryNew,
	);

	/* eslint-disable @atlassian/relay/unused-fields */
	const {
		data: issueConnectionData,
		loadNext,
		loadPrevious,
		hasNext,
		hasPrevious,
		isLoadingNext,
		isLoadingPrevious,
	} = usePaginationFragment<IssueNavigatorIssueSearchPaginationQuery, IssueSearchDataFragment>(
		graphql`
			fragment main_issueNavigator_IssueNavigator_issueSearchData on Query
			@refetchable(queryName: "IssueNavigatorIssueSearchPaginationQuery") {
				jira {
					issueSearch(
						cloudId: $cloudId
						issueSearchInput: $issueSearchInput
						first: $first
						last: $last
						before: $before
						after: $after
						options: $options
						viewConfigInput: $viewConfigInput
					)
						@connection(key: "IssueNavigatorIssueSearchPagination__issueSearch")
						@optIn(to: "JiraSpreadsheetComponent-M1") {
						edges {
							cursor
						}
						...selectedIssue_issueNavigator
						...main_issueNavigator_IssueNavigatorUI_issueResults
					}
				}
			}
		`,
		issueNavigatorRefetchDataNew ?? null,
	);
	/* eslint-enable @atlassian/relay/unused-fields */

	const userPreferencesData = useFragment<UserPreferencesFragmentKey>(
		graphql`
			fragment main_issueNavigator_IssueNavigator_userPreferences on JiraUserPreferences {
				...main_issueNavigator_IssueNavigatorUI_userPreferences
			}
		`,
		issueQuery.jira?.userPreferences ?? null,
	);

	const jqlBuilderWithAiData = useFragment<JqlBuilderWithAiKey>(
		graphql`
			fragment main_issueNavigator_IssueNavigator_jira on JiraQuery {
				...main_issueNavigator_IssueNavigatorUI_jqlBuilderWithAiKey
			}
		`,
		// @ts-expect-error - No overload matches this call.
		issueQuery.jira,
	);

	const issueResultsDataOld = issueNavigatorRefetchDataOld?.jira?.issueSearchStable ?? null;
	const viewResultDataOld = issueNavigatorRefetchDataOld?.jira?.issueSearchViewResult ?? null;
	const issueSearchViewDataOld =
		viewResultDataOld?.__typename === 'JiraIssueSearchView' ? viewResultDataOld : null;

	const issueResultsDataNew = issueConnectionData?.jira?.issueSearch ?? null;
	const viewResultDataNew = issueNavigatorRefetchDataNew?.jira?.issueSearchViewResult ?? null;
	const issueSearchViewDataNew =
		viewResultDataNew?.__typename === 'JiraIssueSearchView' ? viewResultDataNew : null;

	let issueResultsData;
	let viewResultData;
	let issueSearchViewData;

	if (expVal('jira_spreadsheet_component_m1', 'isInfiniteScrollingEnabled', false)) {
		issueResultsData = issueResultsDataNew;
		viewResultData = viewResultDataNew;
		issueSearchViewData = issueSearchViewDataNew;
	} else {
		issueResultsData = issueResultsDataOld;
		viewResultData = viewResultDataOld;
		issueSearchViewData = issueSearchViewDataOld;
	}

	// TODO: HRZ-1580 support pagination for grouping
	let groupResultsData = null;
	if (fg('refactor_nin_to_jira_view_schema')) {
		const jiraViewResult = issueNavigatorRefetchDataNew?.jira?.jiraIssueSearchView;
		groupResultsData =
			jiraViewResult?.__typename === 'JiraGroupedListView' && jiraViewResult.groups
				? jiraViewResult.groups
				: null;
	}

	const filterQuery = usePreloadedQuery<FilterQueryType>(FilterQuery, filterQueryReference);
	/* eslint-disable @atlassian/relay/must-colocate-fragment-spreads */
	const [filterData, refetchFilter] = useRefetchableFragment<FilterRefetchQuery, FilterFragment>(
		graphql`
			fragment main_issueNavigator_IssueNavigator_filterQuery on Query
			@refetchable(queryName: "FilterRefetchQuery") {
				jira {
					filter(id: $filterAri) @include(if: $includeFilter) {
						... on JiraFilter {
							jql
							... on JiraCustomFilter {
								isEditable
							}
							...main_issueNavigator_Header_filter
							...topBar_issueNavigator_filter
							...jqlBuilder_issueNavigator_JQLBuilderWrapper_filter
							...main_issueNavigator_ListView_filter
						}
					}
				}
			}
		`,
		filterQuery,
	);
	/* eslint-enable @atlassian/relay/must-colocate-fragment-spreads */

	return (
		<AppContextualAnalytics>
			<FilterAndJqlProvider
				refetch={refetchFilter}
				filterId={filterInput?.type === FilterTypes.ID ? filterInput?.value : undefined}
				filterJql={
					filterInput?.type === FilterTypes.JQL ? filterInput?.value : filterData?.jira?.filter?.jql
				}
				isFilterEditable={
					filterData.jira?.filter?.isEditable !== null
						? filterData.jira?.filter?.isEditable
						: undefined
				}
				jql={
					searchInput?.type === SearchInputTypes.FILTER_AND_JQL ||
					searchInput?.type === SearchInputTypes.JQL
						? searchInput?.jql
						: undefined
				}
			>
				<MaybeIssueSearchQueryProvider
					refetch={issueNavigatorRefetchOld}
					issueResults={issueResultsDataOld}
					view={issueSearchViewDataOld}
					query={IssueNavigatorRefetchQueryOld}
					onPageDataLoad={onPageDataLoad}
					onChangePage={onChangePage}
				>
					<MaybeIssueSearch
						onPageDataLoad={onPageDataLoad}
						view={issueSearchViewDataNew}
						refetch={issueNavigatorRefetchNew}
						onLoadNext={loadNext}
						onLoadPrevious={loadPrevious}
						isLoadingNext={isLoadingNext}
						isLoadingPrevious={isLoadingPrevious}
						hasNext={hasNext}
						hasPrevious={hasPrevious}
						isIssueHierarchySupportEnabled={isIssueHierarchySupportEnabled}
					>
						<SelectedViewContainer onSetView={onSetView} fragment={issueSearchViewData}>
							<SelectedIssue
								selectedIssueKey={issueKey}
								onChange={onChangeIssue}
								issuesOld={issueResultsDataOld}
								issues={issueResultsDataNew}
							>
								<IssueNavigatorUI
									CustomHeader={CustomHeader}
									ActionMenu={ActionMenu}
									HeaderSkeletonImage={HeaderSkeletonImage}
									filter={filterData?.jira?.filter ?? null}
									onChangeIssue={onChangeIssue}
									onChangeFilter={onChangeFilter}
									onPageDataLoad={onPageDataLoad}
									onChangeColumnConfiguration={onChangeColumnConfiguration}
									onChangeJql={onChangeJql}
									onRefinement={onRefinement}
									defaultJql={defaultJql}
									issueResults={issueResultsData}
									groupResults={groupResultsData}
									viewResult={viewResultData}
									project={issueQuery.jira?.jiraProjectByKey ?? null}
									userPreferences={userPreferencesData}
									jqlBuilderProps={jqlBuilderProps}
									jqlBuilderWithAiKey={jqlBuilderWithAiData}
									isFeedbackButtonDisabled={isFeedbackButtonDisabled}
									issueTableProps={issueTableProps}
									isIssueHierarchySupportEnabled={isIssueHierarchySupportEnabled}
								/>
							</SelectedIssue>
						</SelectedViewContainer>
					</MaybeIssueSearch>
				</MaybeIssueSearchQueryProvider>
			</FilterAndJqlProvider>
		</AppContextualAnalytics>
	);
};

export default memo<Props>(IssueNavigator);
