import React from 'react';
import { useFragment } from 'react-relay';
import { graphql } from 'relay-runtime';
import { SpotlightTarget, SpotlightManager } from '@atlaskit/onboarding';
import { HierarchyToggle as HierarchyToggleBase } from '@atlassian/jira-issue-table-hierarchy-toggle/src/index.tsx';
import { HierarchyToggleOnboarding } from '@atlassian/jira-native-issue-table/src/ui/hierarchy-onboarding/index.tsx';
import type { hierarchyToggle_issueNavigator_HierarchyToggleItem$key } from '@atlassian/jira-relay/src/__generated__/hierarchyToggle_issueNavigator_HierarchyToggleItem.graphql';

export const HierarchyToggleItem = ({
	viewResult,
}: {
	viewResult: hierarchyToggle_issueNavigator_HierarchyToggleItem$key;
}) => {
	const viewResultData = useFragment<hierarchyToggle_issueNavigator_HierarchyToggleItem$key>(
		graphql`
			fragment hierarchyToggle_issueNavigator_HierarchyToggleItem on JiraIssueSearchView
			@argumentDefinitions(
				isJscIssueHierarchyEnabled: {
					type: "Boolean!"
					provider: "@atlassian/jira-relay-provider/src/jsc-m2-api-updates.relayprovider"
				}
			) {
				...ui_issueTableHierarchyToggle_HierarchyToggle
					@arguments(staticViewInput: $staticViewInput)
					@include(if: $isJscIssueHierarchyEnabled)
			}
		`,
		viewResult,
	);

	return (
		<SpotlightManager>
			<SpotlightTarget name="hierarchy-feature-toggle">
				<HierarchyToggleBase viewResult={viewResultData} />
			</SpotlightTarget>
			<HierarchyToggleOnboarding />
		</SpotlightManager>
	);
};
