import React, { useCallback, type MouseEvent } from 'react';
import { styled } from '@compiled/react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button';

import { token } from '@atlaskit/tokens';

import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import messages from './messages.tsx';

type ResetButtonProps = {
	onReset?: (isResetToFilter: boolean) => void;
	isResetToFilter?: boolean;
};

export const ResetButton = ({ onReset, isResetToFilter = false }: ResetButtonProps) => {
	const { formatMessage } = useIntl();

	const handleOnReset = useCallback(
		(mouseEvent: MouseEvent<HTMLElement>, analyticsEvent: UIAnalyticsEvent) => {
			fireUIAnalytics(analyticsEvent, isResetToFilter ? 'goBackToFilterButton' : 'resetButton', {
				// If number of clicks is 0, button was actioned using the keyboard
				keyboard: mouseEvent.detail === 0,
			});
			onReset && onReset(isResetToFilter);
		},
		[onReset, isResetToFilter],
	);

	return (
		<ResetButtonContainer>
			<Button
				testId="issue-navigator.ui.jql-builder.reset-button"
				appearance="subtle-link"
				onClick={handleOnReset}
			>
				{formatMessage(
					isResetToFilter ? messages.goBackToFilterButtonText : messages.resetButtonText,
				)}
			</Button>
		</ResetButtonContainer>
	);
};

export default ResetButton;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ResetButtonContainer = styled.div({
	display: 'flex',
	flex: 0,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	maxWidth: `${25 * gridSize}px`,
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${4 * gridSize}px`,
	marginTop: token('space.050', '4px'),
});
