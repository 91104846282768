import React, { type PropsWithChildren, useMemo } from 'react';
import { graphql, useFragment } from 'react-relay';
import type { selectedIssue_issueNavigator$key as IssuesFragment } from '@atlassian/jira-relay/src/__generated__/selectedIssue_issueNavigator.graphql';
import { toIssueKey, type IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { convertToView } from '../../common/utils/index.tsx';
import { SelectedIssueContainer } from '../../controllers/selected-issue/index.tsx';
import { useSelectedViewState } from '../../controllers/selected-view-state/index.tsx';
import { useSearchKey } from '../../services/issue-search/selectors.tsx';

type Props = {
	issues: IssuesFragment | null;
	/** Issue key that should be selected.  */
	selectedIssueKey: IssueKey;
	/** Event called when the selected issue key is changed. */
	onChange: (issueKey: IssueKey, isSelectedByUserInteraction: boolean) => void;
};

export const SelectedIssue = ({
	children,
	issues,
	selectedIssueKey,
	onChange,
}: PropsWithChildren<Props>) => {
	const [{ view }] = useSelectedViewState();
	const searchKey = useSearchKey();
	const issueData = useFragment<IssuesFragment>(
		graphql`
			fragment selectedIssue_issueNavigator on JiraIssueConnection {
				edges {
					node {
						key
					}
				}
			}
		`,
		issues,
	);

	const issueKeys = useMemo(
		() => issueData?.edges?.map((edge) => toIssueKey(edge?.node?.key ?? '')) ?? [],
		[issueData?.edges],
	);

	return (
		<SelectedIssueContainer
			view={convertToView(view)}
			issueKeys={issueKeys}
			searchKey={searchKey}
			selectedIssueKey={selectedIssueKey}
			onChange={onChange}
		>
			{children}
		</SelectedIssueContainer>
	);
};
