import React, { type PropsWithChildren, useCallback, useMemo } from 'react';
import { graphql, type RefetchFn, useFragment, useRelayEnvironment } from 'react-relay';
import { useFlagsService, toFlagId, type FlagConfiguration } from '@atlassian/jira-flags';
import { isFilterId } from '@atlassian/jira-issue-navigator-actions-common/src/utils/filters/index.tsx';
import type { IssueNavigatorIssueSearchRefetchQuery } from '@atlassian/jira-relay/src/__generated__/IssueNavigatorIssueSearchRefetchQuery.graphql';
import type { issueSearch_issueNavigator$key as ViewFragment } from '@atlassian/jira-relay/src/__generated__/issueSearch_issueNavigator.graphql';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { PACKAGE_NAME } from '../../common/constants.tsx';
import type { IssueNavigatorViewId } from '../../common/types.tsx';
import { parseIssueNavigatorViewIdOrDefault } from '../../common/utils/index.tsx';
import { useActiveJql } from '../../services/active-jql/index.tsx';
import { useExperienceAnalytics } from '../../services/experience-analytics/index.tsx';
import { IssueSearchServiceContainer } from '../../services/issue-search/index.tsx';
import type { InfiniteScrollProps } from '../../services/issue-search/types.tsx';
import { useSelectedViewMutation } from '../../services/selected-view/index.tsx';
import messages from './messages.tsx';

type Props = PropsWithChildren<
	InfiniteScrollProps & {
		/**
		 * Event emitted when page data has loaded and the key experience is interactive.
		 */
		onPageDataLoad?: (selectedView: IssueNavigatorViewId) => void;
		/**
		 * Fragment ref of the issue search view currently rendered.
		 */
		view: ViewFragment | null;
		/**
		 * Relay refetch function to perform a new issue search.
		 */
		refetch: RefetchFn<IssueNavigatorIssueSearchRefetchQuery>;
		/**
		 * Flag indicating whether issue hierarchy feature is enabled in the experience
		 */
		isIssueHierarchySupportEnabled: boolean | undefined;
	}
>;

const searchErrorFlagId = toFlagId('ISSUE_NAVIGATOR_SEARCH_ERROR_FLAG');
const searchErrorFlag: FlagConfiguration = {
	id: searchErrorFlagId,
	type: 'error',
	title: messages.searchFailedTitle,
	description: messages.searchFailedDescription,
};

export const IssueSearch = ({
	children,
	onPageDataLoad,
	view,
	refetch,
	onLoadNext,
	onLoadPrevious,
	isLoadingNext,
	isLoadingPrevious,
	hasNext,
	hasPrevious,
	isIssueHierarchySupportEnabled,
}: Props) => {
	const { showFlag, dismissFlag } = useFlagsService();
	const environment = useRelayEnvironment();
	const cloudId = useCloudId();
	const { jql, filterId } = useActiveJql();
	const issueSearchInput = useMemo(
		() => (jql === undefined && isFilterId(filterId) ? { filterId } : { jql }),
		[filterId, jql],
	);
	const onSelectedViewMutation = useSelectedViewMutation();

	const viewData = useFragment<ViewFragment>(
		graphql`
			fragment issueSearch_issueNavigator on JiraIssueSearchView
			@argumentDefinitions(
				isJscIssueHierarchyEnabled: {
					type: "Boolean!"
					provider: "@atlassian/jira-relay-provider/src/jsc-m2-api-updates.relayprovider"
				}
			) {
				viewConfigSettings(staticViewInput: $staticViewInput)
					@include(if: $isJscIssueHierarchyEnabled) {
					isHierarchyEnabled
				}
				viewId @required(action: THROW)
			}
		`,
		view,
	);

	const onRefetchStart = useCallback(() => {
		// Hide any search error flags currently visible
		dismissFlag(searchErrorFlagId);
	}, [dismissFlag]);

	const viewId = parseIssueNavigatorViewIdOrDefault(viewData?.viewId);
	const { onIssueSearchFail } = useExperienceAnalytics(onPageDataLoad, viewId);
	const onRefetchError = useCallback(
		(error: Error, refetchView: IssueNavigatorViewId) => {
			onIssueSearchFail(`${PACKAGE_NAME}.issue-search-refetch`, error, refetchView);
			showFlag(searchErrorFlag);
		},
		[onIssueSearchFail, showFlag],
	);

	return (
		<IssueSearchServiceContainer
			cloudId={cloudId}
			environment={environment}
			issueSearchInput={issueSearchInput}
			viewId={viewId}
			filterId={filterId}
			onPageDataLoad={onPageDataLoad}
			onRefetchStart={onRefetchStart}
			onRefetchError={onRefetchError}
			onSelectedViewMutation={onSelectedViewMutation}
			refetch={refetch}
			onLoadNext={onLoadNext}
			onLoadPrevious={onLoadPrevious}
			isLoadingNext={isLoadingNext}
			isLoadingPrevious={isLoadingPrevious}
			hasNext={hasNext}
			hasPrevious={hasPrevious}
			isHierarchyEnabled={Boolean(
				isIssueHierarchySupportEnabled && viewData?.viewConfigSettings?.isHierarchyEnabled,
			)}
		>
			{children}
		</IssueSearchServiceContainer>
	);
};
