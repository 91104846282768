/**
 * @generated SignedSource<<830b56b1c6fd2336014f37af74b87fba>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type projectGrant_issueNavigatorCustomFilters$data = {
  readonly project: {
    readonly key: string;
    readonly name: string;
  } | null | undefined;
  readonly " $fragmentType": "projectGrant_issueNavigatorCustomFilters";
};
export type projectGrant_issueNavigatorCustomFilters$key = {
  readonly " $data"?: projectGrant_issueNavigatorCustomFilters$data;
  readonly " $fragmentSpreads": FragmentRefs<"projectGrant_issueNavigatorCustomFilters">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "projectGrant_issueNavigatorCustomFilters",
  "selections": [
    {
      "concreteType": "JiraProject",
      "kind": "LinkedField",
      "name": "project",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "name"
        },
        {
          "kind": "ScalarField",
          "name": "key"
        }
      ]
    }
  ],
  "type": "JiraShareableEntityProjectGrant"
};

(node as any).hash = "a6f0045e8c355671600e72844955bb81";

export default node;
