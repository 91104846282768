import { useCallback, useMemo } from 'react';
import { createActionsHook, createStateHook } from '@atlassian/react-sweet-state';
import { MAX_ISSUES_PER_PAGE } from '../../common/constants.tsx';
import { Store } from './index.tsx';
import type { State, Actions, InfiniteScrollProps as Props } from './types.tsx';

const INFINITE_SCROLL_DEFAULT_OPTIONS = {
	pageSize: MAX_ISSUES_PER_PAGE,
};

export const useSearchKey = createStateHook(Store, { selector: (state) => state.searchKey });

export const useIsFetching = createStateHook(Store, { selector: (state) => state.isFetching });

export const useIsFetchingView = createStateHook(Store, {
	selector: (state) => state.isFetchingView,
});

export const useIsNetworkError = createStateHook(Store, {
	selector: (state) => state.isNetworkError,
});

export const useIsRefreshing = createStateHook(Store, { selector: (state) => state.isRefreshing });

export const useTotalIssueCount = createStateHook(Store, {
	selector: (state) => state.totalIssueCount,
});

export const useTotalIssueCountIsFetching = createStateHook(Store, {
	selector: (state) => state.totalIssueCountIsFetching,
});

export const useTotalIssueCountIsError = createStateHook(Store, {
	selector: (state) => state.totalIssueCountIsError,
});

export const useIssueSearchActions = createActionsHook(Store);

/** Get all infinite scroll related props to avoid prop drilling. */
const useInfiniteScrollProps = createStateHook<State, Actions, Props>(Store, {
	selector: ({
		onLoadNext,
		onLoadPrevious,
		isLoadingNext,
		isLoadingPrevious,
		hasNext,
		hasPrevious,
	}) => ({
		onLoadNext,
		onLoadPrevious,
		isLoadingNext,
		isLoadingPrevious,
		hasNext,
		hasPrevious,
	}),
});

interface InfiniteScrollProps {
	onLoadNext: () => void;
	onLoadPrevious: () => void;
	isLoadingNext: boolean;
	isLoadingPrevious: boolean;
	hasNext: boolean;
	hasPrevious: boolean;
	activationThreshold?: number;
}

type Options = {
	pageSize?: number;
	threshold?: number;
};

/**
 * A hook that makes it easier to consume infinite scroll props.
 *
 * @param options.pageSize Determines the page size to be returned from the backend. Defaults to `50`.
 * @param options.threshold How far, in pixels, from the bottom or top of the issue list the next/previous page of items should start loading. Defaults to `undefined`.
 * @returns A result object with a stable prop getter `getInfiniteScrollProps`
 */
export function useInfiniteScroll(options?: Options): InfiniteScrollProps {
	const { pageSize, threshold } = { ...INFINITE_SCROLL_DEFAULT_OPTIONS, ...options };
	const {
		onLoadNext: onLoadNextProp,
		onLoadPrevious: onLoadPreviousProp,
		...rest
	} = useInfiniteScrollProps();

	const onLoadNext = useCallback(() => onLoadNextProp(pageSize), [onLoadNextProp, pageSize]);
	const onLoadPrevious = useCallback(
		() => onLoadPreviousProp(pageSize),
		[onLoadPreviousProp, pageSize],
	);

	const infiniteScrollProps = useMemo<InfiniteScrollProps>(
		() => ({
			onLoadNext,
			onLoadPrevious,
			activationThreshold: threshold,
			...rest,
		}),
		[onLoadNext, onLoadPrevious, threshold, rest],
	);

	return infiniteScrollProps;
}
