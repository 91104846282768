/**
 * @generated SignedSource<<14c673bea588898dc9074f64c01fc286>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type main_issueNavigator_ListView_issueResults$data = {
  readonly __id: string;
  readonly pageCursors: {
    readonly around: ReadonlyArray<{
      readonly isCurrent: boolean | null | undefined;
      readonly pageNumber: number | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"registerShortcutDialogActions_issueNavigator_RegisterShortcutsDialogActions" | "ui_nativeIssueTable_NativeIssueTable_issues">;
  readonly " $fragmentType": "main_issueNavigator_ListView_issueResults";
};
export type main_issueNavigator_ListView_issueResults$key = {
  readonly " $data"?: main_issueNavigator_ListView_issueResults$data;
  readonly " $fragmentSpreads": FragmentRefs<"main_issueNavigator_ListView_issueResults">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "main_issueNavigator_ListView_issueResults",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "registerShortcutDialogActions_issueNavigator_RegisterShortcutsDialogActions"
    },
    {
      "kind": "FragmentSpread",
      "name": "ui_nativeIssueTable_NativeIssueTable_issues"
    },
    {
      "args": [
        {
          "kind": "Literal",
          "name": "maxCursors",
          "value": 7
        }
      ],
      "concreteType": "JiraPageCursors",
      "kind": "LinkedField",
      "name": "pageCursors",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraPageCursor",
          "kind": "LinkedField",
          "name": "around",
          "plural": true,
          "selections": [
            {
              "kind": "ScalarField",
              "name": "pageNumber"
            },
            {
              "kind": "ScalarField",
              "name": "isCurrent"
            }
          ]
        }
      ],
      "storageKey": "pageCursors(maxCursors:7)"
    },
    {
      "kind": "ClientExtension",
      "selections": [
        {
          "kind": "ScalarField",
          "name": "__id"
        }
      ]
    }
  ],
  "type": "JiraIssueConnection"
};

(node as any).hash = "26e5557aa71bd28be0879894b7dd8134";

export default node;
