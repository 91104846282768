/**
 * @generated SignedSource<<8590f541f77918db05103a0e59693617>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type access_issueNavigatorCustomFilters$data = {
  readonly shareGrants: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly __typename: string;
        readonly user?: {
          readonly name: string;
        } | null | undefined;
        readonly " $fragmentSpreads": FragmentRefs<"groupGrant_issueNavigatorCustomFilters" | "projectGrant_issueNavigatorCustomFilters" | "projectRoleGrant_issueNavigatorCustomFilters">;
      } | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "access_issueNavigatorCustomFilters";
};
export type access_issueNavigatorCustomFilters$key = {
  readonly " $data"?: access_issueNavigatorCustomFilters$data;
  readonly " $fragmentSpreads": FragmentRefs<"access_issueNavigatorCustomFilters">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "access_issueNavigatorCustomFilters",
  "selections": [
    {
      "concreteType": "JiraShareableEntityShareGrantConnection",
      "kind": "LinkedField",
      "name": "shareGrants",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraShareableEntityShareGrantEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "__typename"
                },
                {
                  "kind": "FragmentSpread",
                  "name": "projectGrant_issueNavigatorCustomFilters"
                },
                {
                  "kind": "FragmentSpread",
                  "name": "projectRoleGrant_issueNavigatorCustomFilters"
                },
                {
                  "kind": "FragmentSpread",
                  "name": "groupGrant_issueNavigatorCustomFilters"
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "kind": "LinkedField",
                      "name": "user",
                      "plural": false,
                      "selections": [
                        {
                          "kind": "ScalarField",
                          "name": "name"
                        }
                      ]
                    }
                  ],
                  "type": "JiraShareableEntityUserGrant"
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraCustomFilter"
};

(node as any).hash = "723acfaab9c38e502d502422ae67f3e2";

export default node;
