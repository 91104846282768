import React, { useCallback, type ForwardedRef } from 'react';
import { graphql, useFragment, useMutation } from 'react-relay';

import { Inline } from '@atlaskit/primitives';
import Toggle from '@atlaskit/toggle';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import type { ui_issueTableHierarchyToggle_HierarchyToggle$key } from '@atlassian/jira-relay/src/__generated__/ui_issueTableHierarchyToggle_HierarchyToggle.graphql';
import type { ui_issueTableHierarchyToggle_HierarchyToggleMutation } from '@atlassian/jira-relay/src/__generated__/ui_issueTableHierarchyToggle_HierarchyToggleMutation.graphql';
import messages from './messages.tsx';

export type HierarchyToggleProps = {
	viewResult: ui_issueTableHierarchyToggle_HierarchyToggle$key;
	forwardRef?: ForwardedRef<HTMLInputElement>;
};

export const HierarchyToggle = ({ viewResult, forwardRef }: HierarchyToggleProps) => {
	const { formatMessage } = useIntl();

	const viewResultData = useFragment<ui_issueTableHierarchyToggle_HierarchyToggle$key>(
		graphql`
			fragment ui_issueTableHierarchyToggle_HierarchyToggle on JiraIssueSearchView
			@argumentDefinitions(staticViewInput: { type: "JiraIssueSearchStaticViewInput" }) {
				id
				viewId
				viewConfigSettings(staticViewInput: $staticViewInput) {
					__id
					isHierarchyEnabled
					canEnableHierarchy
				}
			}
		`,
		viewResult,
	);

	const { viewConfigSettings, id, viewId } = viewResultData;

	const { createAnalyticsEvent } = useAnalyticsEvents();
	const toggleIssueHierarchy = useUpdateHierarchyMutation();
	const { isHierarchyEnabled, canEnableHierarchy, __id } = viewConfigSettings ?? {};

	const onChange = useCallback(() => {
		if (id && __id) {
			fireUIAnalytics(createAnalyticsEvent({}), 'hierarchyToggle clicked', {
				isHierarchyEnabled: !isHierarchyEnabled,
			});
			toggleIssueHierarchy(id, __id, !isHierarchyEnabled);
		}
	}, [createAnalyticsEvent, isHierarchyEnabled, toggleIssueHierarchy, id, __id]);

	return (
		<Inline spread="space-between" alignBlock="center">
			<label htmlFor={`${viewId}_hierarchy_toggle`}>{formatMessage(messages.showHierarchy)}</label>
			<Toggle
				isDisabled={!canEnableHierarchy}
				isChecked={Boolean(isHierarchyEnabled && canEnableHierarchy)}
				id={`${viewId}_hierarchy_toggle`}
				ref={forwardRef}
				onChange={onChange}
			/>
		</Inline>
	);
};

export const useUpdateHierarchyMutation = () => {
	const [commit] = useMutation<ui_issueTableHierarchyToggle_HierarchyToggleMutation>(graphql`
		mutation ui_issueTableHierarchyToggle_HierarchyToggleMutation(
			$viewId: ID!
			$isHierarchyEnabled: Boolean!
		) {
			jira {
				updateIssueSearchHierarchyPreference(
					viewId: $viewId
					isHierarchyEnabled: $isHierarchyEnabled
				) @optIn(to: "JiraUpdateIssueSearchHierarchyPreference") {
					success
				}
			}
		}
	`);

	const updateHierarchy = useCallback(
		(
			issueSearchViewResultAri: string,
			issueSearchViewResultRelayId: string,
			isHierarchyEnabled: boolean,
		) => {
			commit({
				variables: {
					viewId: issueSearchViewResultAri,
					isHierarchyEnabled,
				},

				updater(store, data) {
					if (data?.jira?.updateIssueSearchHierarchyPreference?.success) {
						const issueSearchViewResult = store.get(issueSearchViewResultRelayId);
						issueSearchViewResult?.setValue(isHierarchyEnabled, 'isHierarchyEnabled');
					}
				},
			});
		},
		[commit],
	);
	return updateHierarchy;
};
