/**
 * @generated SignedSource<<499d1dce901e38fe101d2fe7b923ec33>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type selectedView_issueNavigator_SelectedViewContainer$data = {
  readonly viewId: string | null | undefined;
  readonly " $fragmentType": "selectedView_issueNavigator_SelectedViewContainer";
};
export type selectedView_issueNavigator_SelectedViewContainer$key = {
  readonly " $data"?: selectedView_issueNavigator_SelectedViewContainer$data;
  readonly " $fragmentSpreads": FragmentRefs<"selectedView_issueNavigator_SelectedViewContainer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "selectedView_issueNavigator_SelectedViewContainer",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "viewId"
    }
  ],
  "type": "JiraIssueSearchView"
};

(node as any).hash = "7715cf1d3aecd1efc3d771f2f7af4bf4";

export default node;
