import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	description: {
		id: 'issue-navigator-custom-filters.details-popup.popup-content.description.description',
		defaultMessage: 'Description',
		description: 'Header text of the Description section for a filter',
	},
	editNameAndDescription: {
		id: 'issue-navigator-custom-filters.details-popup.popup-content.description.edit-name-and-description',
		defaultMessage: 'Edit name and description',
		description: 'Link text to the page where a user can edit the name and description of a filter',
	},
	descriptionPlaceholder: {
		id: 'issue-navigator-custom-filters.details-popup.popup-content.description.description-placeholder',
		defaultMessage: "This filter doesn't have a description.",
		description: 'Placeholder text for when the filter has no description set',
	},
});
