/**
 * @generated SignedSource<<bcf3fd047349255d1334d0cedddeea6f>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type subscriptions_issueNavigatorCustomFilters$data = {
  readonly emailSubscriptions: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly group: {
          readonly name: string;
        } | null | undefined;
        readonly id: string;
        readonly user: {
          readonly name: string;
        } | null | undefined;
      } | null | undefined;
    } | null | undefined>;
  };
  readonly filterId: string;
  readonly " $fragmentSpreads": FragmentRefs<"addSubscriptionModal_issueNavigatorCustomFilters_AddSubscriptionModalProvider">;
  readonly " $fragmentType": "subscriptions_issueNavigatorCustomFilters";
};
export type subscriptions_issueNavigatorCustomFilters$key = {
  readonly " $data"?: subscriptions_issueNavigatorCustomFilters$data;
  readonly " $fragmentSpreads": FragmentRefs<"subscriptions_issueNavigatorCustomFilters">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "ScalarField",
    "name": "name"
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "subscriptions_issueNavigatorCustomFilters",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "filterId"
    },
    {
      "kind": "FragmentSpread",
      "name": "addSubscriptionModal_issueNavigatorCustomFilters_AddSubscriptionModalProvider"
    },
    {
      "kind": "RequiredField",
      "field": {
        "args": [
          {
            "kind": "Literal",
            "name": "last",
            "value": 5
          }
        ],
        "concreteType": "JiraFilterEmailSubscriptionConnection",
        "kind": "LinkedField",
        "name": "emailSubscriptions",
        "plural": false,
        "selections": [
          {
            "kind": "RequiredField",
            "field": {
              "concreteType": "JiraFilterEmailSubscriptionEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "concreteType": "JiraFilterEmailSubscription",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "name": "id"
                    },
                    {
                      "kind": "LinkedField",
                      "name": "user",
                      "plural": false,
                      "selections": (v0/*: any*/)
                    },
                    {
                      "concreteType": "JiraGroup",
                      "kind": "LinkedField",
                      "name": "group",
                      "plural": false,
                      "selections": (v0/*: any*/)
                    }
                  ]
                }
              ]
            },
            "action": "THROW",
            "path": "emailSubscriptions.edges"
          }
        ],
        "storageKey": "emailSubscriptions(last:5)"
      },
      "action": "THROW",
      "path": "emailSubscriptions"
    }
  ],
  "type": "JiraCustomFilter"
};
})();

(node as any).hash = "85c47f44d1a205822525c56240e74538";

export default node;
