/**
 * @generated SignedSource<<0cfcdb0420a09ca725049c94d6efb710>>
 * @relayHash 0e7ba6c01fa74963bf3d9e9aff1825f6
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID ea1a03f3c5fe0687fade2c03e1ce38b97eb1888360eb9a34dfc69dfbbb066f32

import type { ConcreteRequest, Query } from 'relay-runtime';
export type JiraSoftwareIssueSearchCustomInputContext = "BACKLOG" | "BOARD" | "NONE" | "%future added value";
export type JiraIssueSearchInput = {
  childIssuesInput?: JiraIssueSearchChildIssuesInput | null | undefined;
  customInput?: JiraIssueSearchCustomInput | null | undefined;
  filterId?: string | null | undefined;
  jql?: string | null | undefined;
};
export type JiraIssueSearchChildIssuesInput = {
  filterByProjectKeys?: ReadonlyArray<string> | null | undefined;
  parentIssueKey: string;
};
export type JiraIssueSearchCustomInput = {
  jiraSoftwareInput?: JiraSoftwareIssueSearchCustomInput | null | undefined;
};
export type JiraSoftwareIssueSearchCustomInput = {
  additionalJql?: string | null | undefined;
  context?: JiraSoftwareIssueSearchCustomInputContext | null | undefined;
  jiraEntityId: string;
};
export type actions_issueSearchTotalCountQuery$variables = {
  cloudId: string;
  issueSearchInput: JiraIssueSearchInput;
};
export type actions_issueSearchTotalCountQuery$data = {
  readonly jira: {
    readonly issueSearchTotalCount: number | null | undefined;
  } | null | undefined;
};
export type actions_issueSearchTotalCountQuery = {
  response: actions_issueSearchTotalCountQuery$data;
  variables: actions_issueSearchTotalCountQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cloudId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "issueSearchInput"
  }
],
v1 = [
  {
    "concreteType": "JiraQuery",
    "kind": "LinkedField",
    "name": "jira",
    "plural": false,
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "cloudId",
            "variableName": "cloudId"
          },
          {
            "kind": "Variable",
            "name": "issueSearchInput",
            "variableName": "issueSearchInput"
          }
        ],
        "kind": "ScalarField",
        "name": "issueSearchTotalCount"
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "actions_issueSearchTotalCountQuery",
    "selections": (v1/*: any*/),
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "actions_issueSearchTotalCountQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "ea1a03f3c5fe0687fade2c03e1ce38b97eb1888360eb9a34dfc69dfbbb066f32",
    "metadata": {},
    "name": "actions_issueSearchTotalCountQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "9220620a1c9450bcc153a38c7d2c2de0";

export default node;
