import React, { useCallback, useLayoutEffect, useMemo } from 'react';
import { graphql, useFragment } from 'react-relay';
import OpTrigger from '@atlassian/jira-common-util-op-triggers/src/on-mount/index.tsx';
import { isLoaderErrorAttributes } from '@atlassian/jira-errors-handling/src/utils/is-loader-error-attributes.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { ContextualAnalyticsData, SCREEN } from '@atlassian/jira-product-analytics-bridge';
import type { main_issueNavigator_IssueResults_groupResults$key as GroupResultsFragment } from '@atlassian/jira-relay/src/__generated__/main_issueNavigator_IssueResults_groupResults.graphql';
import type { main_issueNavigator_IssueResults_issueResults$key as IssueResultsFragment } from '@atlassian/jira-relay/src/__generated__/main_issueNavigator_IssueResults_issueResults.graphql';
import type { main_issueNavigator_IssueResults_project$key as ProjectFragment } from '@atlassian/jira-relay/src/__generated__/main_issueNavigator_IssueResults_project.graphql';
import type { main_issueNavigator_IssueResults_viewResult$key as ViewResultFragment } from '@atlassian/jira-relay/src/__generated__/main_issueNavigator_IssueResults_viewResult.graphql';
import type { main_issueNavigator_ListView_filter$key as FilterFragment } from '@atlassian/jira-relay/src/__generated__/main_issueNavigator_ListView_filter.graphql';
import UFOLabel from '@atlassian/jira-ufo-label/src/index.tsx';
import { useRouterActions } from '@atlassian/react-resource-router';
import {
	ANALYTICS_DETAIL_VIEW_SOURCE_NAME,
	ANALYTICS_LIST_VIEW_SOURCE_NAME,
	PACKAGE_NAME,
	TEAM_NAME,
} from '../../common/constants.tsx';
import type { OverridableIssueTableProps } from '../../common/types.tsx';
import { ListViewSkeleton } from '../../common/ui/loading-skeleton/index.tsx';
import { withReportErrors } from '../../common/ui/with-report-errors/index.tsx';
import { markOnce, marks } from '../../common/utils/performance-analytics.tsx';
import { useSelectedIssueStateOldActions } from '../../controllers/selected-issue-state-old/index.tsx';
import { useSelectedIssueActions } from '../../controllers/selected-issue/hooks.tsx';
import { useSelectedViewState } from '../../controllers/selected-view-state/index.tsx';
import { SortContainer } from '../../controllers/sort-field-and-direction-state/index.tsx';
import { useResolvedJql } from '../../services/active-jql/index.tsx';
import { useIssueSearchQuery } from '../../services/issue-search-query/index.tsx';
import { useIsFetching } from '../../services/issue-search/selectors.tsx';
import DetailView from './detail-view/main.tsx';
import ErrorView, { RecoverableErrorViewBoundary } from './error-view/index.tsx';
import ListView from './list-view/main.tsx';

type Props = {
	issueResults: IssueResultsFragment | null;
	groupResults: GroupResultsFragment | null;
	viewResult: ViewResultFragment | null;
	project: ProjectFragment | null;
	onChangeColumnConfiguration?: () => void;
	onChangeJql?: (jql: string) => void;
	onCaughtIssueSearchError?: (error?: Error) => void;
	isFeedbackButtonDisabled?: boolean;
	issueTableProps?: OverridableIssueTableProps;
	filter: FilterFragment | null;
	isIssueHierarchySupportEnabled?: boolean;
};

const IssueResults = ({
	onChangeColumnConfiguration,
	onChangeJql,
	onCaughtIssueSearchError,
	issueResults,
	groupResults,
	viewResult,
	project,
	isFeedbackButtonDisabled,
	issueTableProps,
	filter,
	isIssueHierarchySupportEnabled,
}: Props) => {
	markOnce(marks.ISSUE_RESULTS_START);

	const [{ view: selectedView }] = useSelectedViewState();
	const jql = useResolvedJql();

	const { push } = useRouterActions();

	let onSelectedRowChange;

	if (expVal('jira_spreadsheet_component_m1', 'isInfiniteScrollingEnabled', false)) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		const { setSelectedIssueByIndex } = useSelectedIssueActions();
		// eslint-disable-next-line react-hooks/rules-of-hooks
		onSelectedRowChange = useCallback(
			(rowIndex: number) => {
				setSelectedIssueByIndex(rowIndex, {
					shouldDebounce: false,
					shouldNotifyOnChange: false,
					isSelectedByUserInteraction: true,
				});
			},
			[setSelectedIssueByIndex],
		);
	} else {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		const { setSelectedIssueByIndex } = useSelectedIssueStateOldActions();
		// eslint-disable-next-line react-hooks/rules-of-hooks
		onSelectedRowChange = useCallback(
			(rowIndex: number) => {
				setSelectedIssueByIndex(rowIndex, false, false, true);
			},
			[setSelectedIssueByIndex],
		);
	}

	let isFetching;
	if (expVal('jira_spreadsheet_component_m1', 'isInfiniteScrollingEnabled', false)) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		isFetching = useIsFetching();
	} else {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		({ isFetching } = useIssueSearchQuery());
	}

	const issueResultsData = useFragment<IssueResultsFragment>(
		graphql`
			fragment main_issueNavigator_IssueResults_issueResults on JiraIssueConnection {
				...main_issueNavigator_ListView_issueResults
				...main_issueNavigator_DetailView_issueResults
				issueSearchError {
					__typename
				}
			}
		`,
		issueResults,
	);

	const groupResultsData = useFragment<GroupResultsFragment>(
		graphql`
			fragment main_issueNavigator_IssueResults_groupResults on JiraSpreadsheetGroupConnection {
				...main_issueNavigator_ListView_groupResults
			}
		`,
		groupResults,
	);

	const viewResultData = useFragment<ViewResultFragment>(
		graphql`
			fragment main_issueNavigator_IssueResults_viewResult on JiraIssueSearchViewResult {
				... on JiraIssueSearchView {
					...main_issueNavigator_ListView_view
					...main_issueNavigator_DetailView_view
				}
				... on QueryError {
					message
					extensions {
						statusCode
					}
					__typename
				}
			}
		`,
		viewResult,
	);

	const projectData = useFragment(
		graphql`
			fragment main_issueNavigator_IssueResults_project on JiraProject {
				...main_issueNavigator_ListView_project
			}
		`,
		project,
	);

	const viewResultError = useMemo(
		() =>
			viewResultData?.__typename === 'QueryError' &&
			viewResultData?.extensions?.[0]?.statusCode !== undefined &&
			viewResultData?.extensions?.[0]?.statusCode !== null
				? new FetchError(viewResultData?.extensions?.[0]?.statusCode, viewResultData?.message ?? '')
				: undefined,
		[viewResultData],
	);

	useLayoutEffect(() => {
		markOnce(marks.ISSUE_RESULTS_END);
	}, []);

	const onNavigateToIssue = useCallback((issueKey: string) => push(`/browse/${issueKey}`), [push]);

	const sourceName =
		selectedView === 'detail' ? ANALYTICS_DETAIL_VIEW_SOURCE_NAME : ANALYTICS_LIST_VIEW_SOURCE_NAME;

	if (
		!issueResultsData ||
		issueResultsData.issueSearchError?.__typename === 'JiraServerError' ||
		!viewResultData ||
		viewResultData?.__typename === 'QueryError'
	) {
		return isFetching ? <ListViewSkeleton /> : <ErrorView error={viewResultError} />;
	}

	return (
		<UFOLabel name="issue-results">
			<ContextualAnalyticsData sourceName={sourceName} sourceType={SCREEN}>
				<RecoverableErrorViewBoundary
					recoveryProp={isFetching}
					onCaughtIssueSearchError={onCaughtIssueSearchError}
				>
					<SortContainer jql={jql} onChangeJql={onChangeJql}>
						{selectedView !== 'detail' ? (
							<ListView
								loading={isFetching}
								onSelectedRowChange={onSelectedRowChange}
								issueResults={issueResultsData}
								groupResults={groupResultsData}
								view={viewResultData}
								project={projectData}
								onChangeColumnConfiguration={onChangeColumnConfiguration}
								isFeedbackButtonDisabled={isFeedbackButtonDisabled}
								issueTableProps={issueTableProps}
								isIssueHierarchySupportEnabled={isIssueHierarchySupportEnabled}
								filter={filter}
							/>
						) : (
							<DetailView
								loading={isFetching}
								onNavigateToIssue={onNavigateToIssue}
								issueResults={issueResultsData}
								view={viewResultData}
								isFeedbackButtonDisabled={isFeedbackButtonDisabled}
							/>
						)}
					</SortContainer>
					<OpTrigger
						op={() => {
							onCaughtIssueSearchError && onCaughtIssueSearchError(undefined);
						}}
					/>
				</RecoverableErrorViewBoundary>
			</ContextualAnalyticsData>
		</UFOLabel>
	);
};

export default withReportErrors<Props>(IssueResults, {
	id: 'ui.issue-results.unhandled',
	packageName: PACKAGE_NAME,
	teamName: TEAM_NAME,
	sendToPrivacyUnsafeSplunk: true,
	attributes: isLoaderErrorAttributes,
});
