import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	resetButtonText: {
		id: 'issue-navigator.jql-builder.reset-button.reset-button-text',
		defaultMessage: 'Reset',
		description: 'The text for the reset button in refinement bar',
	},
	goBackToFilterButtonText: {
		id: 'issue-navigator.jql-builder.reset-button.go-back-to-filter-button-text',
		defaultMessage: 'Go back to filter',
		description: 'The text for the reset filter button in refinement bar',
	},
});
