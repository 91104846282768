/**
 * @generated SignedSource<<88ce43a5572fe3c9e431d624da258b6a>>
 * @relayHash 9470068c855e94b43e6fa8d8e6d817e4
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID ee527aef2a2a40d44b27f2fc86c42817d720c54bf08f9370ab8b00596206eb28

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type JiraSoftwareIssueSearchCustomInputContext = "BACKLOG" | "BOARD" | "NONE" | "%future added value";
export type JiraIssueSearchInput = {
  childIssuesInput?: JiraIssueSearchChildIssuesInput | null | undefined;
  customInput?: JiraIssueSearchCustomInput | null | undefined;
  filterId?: string | null | undefined;
  jql?: string | null | undefined;
};
export type JiraIssueSearchChildIssuesInput = {
  filterByProjectKeys?: ReadonlyArray<string> | null | undefined;
  parentIssueKey: string;
};
export type JiraIssueSearchCustomInput = {
  jiraSoftwareInput?: JiraSoftwareIssueSearchCustomInput | null | undefined;
};
export type JiraSoftwareIssueSearchCustomInput = {
  additionalJql?: string | null | undefined;
  context?: JiraSoftwareIssueSearchCustomInputContext | null | undefined;
  jiraEntityId: string;
};
export type JiraIssueSearchOptions = {
  issueKey?: string | null | undefined;
};
export type JiraIssueSearchStaticViewInput = {
  isGroupingEnabled?: boolean | null | undefined;
  isHierarchyEnabled?: boolean | null | undefined;
};
export type JiraIssueSearchViewConfigInput = {
  staticViewInput?: JiraIssueSearchStaticViewInput | null | undefined;
  viewInput?: JiraIssueSearchViewInput | null | undefined;
};
export type JiraIssueSearchViewInput = {
  namespace?: string | null | undefined;
  viewId?: string | null | undefined;
};
export type IssueNavigatorIssueSearchRefetchQuery$variables = {
  after?: string | null | undefined;
  amountOfColumns?: number | null | undefined;
  before?: string | null | undefined;
  cloudId: string;
  fieldSetIds: ReadonlyArray<string>;
  filterId?: string | null | undefined;
  first?: number | null | undefined;
  isRefactorNinToViewSchemaEnabled: boolean;
  issueSearchInput: JiraIssueSearchInput;
  issueTypeId?: string | null | undefined;
  last?: number | null | undefined;
  namespace?: string | null | undefined;
  options?: JiraIssueSearchOptions | null | undefined;
  projectId?: string | null | undefined;
  shouldQueryFieldSetsById: boolean;
  staticViewInput?: JiraIssueSearchStaticViewInput | null | undefined;
  viewConfigInput?: JiraIssueSearchViewConfigInput | null | undefined;
  viewId?: string | null | undefined;
};
export type IssueNavigatorIssueSearchRefetchQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"main_issueNavigator_IssueNavigator_refetchQueryNew">;
};
export type IssueNavigatorIssueSearchRefetchQuery = {
  response: IssueNavigatorIssueSearchRefetchQuery$data;
  variables: IssueNavigatorIssueSearchRefetchQuery$variables;
};
({
  "isRefactorNinToViewSchemaEnabled": isRefactorNinToViewSchemaEnabled_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcisrefactornintoviewschemaenabledrelayprovider": isRefactorNinToViewSchemaEnabled_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcisbulkopsinninenabledrelayprovider": bulkOpsInNinEnabled_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcjscm2apiupdatesrelayprovider": isJscIssueHierarchyEnabled_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcjscm1apiupdatesrelayprovider": isJscM1ApiUpdatesEnabled_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcnikechinstorypointsinlineeditrelayprovider": isNikeChinStoryPointsInlineEditEnabled_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrccmdbmediaavatarfgrelayprovider": isCmdbMediaAvatarEnabled_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcissuelinksentrypointrelayprovider": isEntryPointEnabled_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcissuelinkscountrelayprovider": count_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcisjscinfinitescrollenabledrelayprovider": isJscInfiniteScrollEnabled_provider
} as {
  readonly __relay_internal__pv__atlassianjirarelayprovidersrccmdbmediaavatarfgrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcisbulkopsinninenabledrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcisjscinfinitescrollenabledrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcisrefactornintoviewschemaenabledrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcissuelinkscountrelayprovider: {
    readonly get: () => number;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcissuelinksentrypointrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcjscm1apiupdatesrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcjscm2apiupdatesrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcnikechinstorypointsinlineeditrelayprovider: {
    readonly get: () => boolean;
  };
  readonly isRefactorNinToViewSchemaEnabled: {
    readonly get: () => boolean;
  };
});

import isCmdbMediaAvatarEnabled_provider from '@atlassian/jira-relay-provider/src/cmdb-media-avatar-fg.relayprovider';
import bulkOpsInNinEnabled_provider from '@atlassian/jira-relay-provider/src/is-bulk-ops-in-nin-enabled.relayprovider';
import isJscInfiniteScrollEnabled_provider from '@atlassian/jira-relay-provider/src/is-jsc-infinite-scroll-enabled.relayprovider';
import isRefactorNinToViewSchemaEnabled_provider from '@atlassian/jira-relay-provider/src/is-refactor-nin-to-view-schema-enabled.relayprovider';
import count_provider from '@atlassian/jira-relay-provider/src/issue-links-count.relayprovider';
import isEntryPointEnabled_provider from '@atlassian/jira-relay-provider/src/issue-links-entrypoint.relayprovider';
import isJscM1ApiUpdatesEnabled_provider from '@atlassian/jira-relay-provider/src/jsc-m1-api-updates.relayprovider';
import isJscIssueHierarchyEnabled_provider from '@atlassian/jira-relay-provider/src/jsc-m2-api-updates.relayprovider';
import isNikeChinStoryPointsInlineEditEnabled_provider from '@atlassian/jira-relay-provider/src/nike-chin-story-points-inline-edit.relayprovider';

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "amountOfColumns"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "before"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cloudId"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "fieldSetIds"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filterId"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isRefactorNinToViewSchemaEnabled"
},
v8 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "issueSearchInput"
},
v9 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "issueTypeId"
},
v10 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "last"
},
v11 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "namespace"
},
v12 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "options"
},
v13 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectId"
},
v14 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "shouldQueryFieldSetsById"
},
v15 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "staticViewInput"
},
v16 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "viewConfigInput"
},
v17 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "viewId"
},
v18 = {
  "kind": "Variable",
  "name": "after",
  "variableName": "after"
},
v19 = {
  "kind": "Variable",
  "name": "before",
  "variableName": "before"
},
v20 = {
  "kind": "Variable",
  "name": "cloudId",
  "variableName": "cloudId"
},
v21 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v22 = {
  "kind": "Variable",
  "name": "issueSearchInput",
  "variableName": "issueSearchInput"
},
v23 = {
  "kind": "Variable",
  "name": "last",
  "variableName": "last"
},
v24 = [
  (v18/*: any*/),
  (v19/*: any*/),
  (v20/*: any*/),
  (v21/*: any*/),
  (v22/*: any*/),
  (v23/*: any*/),
  {
    "kind": "Variable",
    "name": "options",
    "variableName": "options"
  },
  {
    "kind": "Variable",
    "name": "viewConfigInput",
    "variableName": "viewConfigInput"
  }
],
v25 = {
  "kind": "ScalarField",
  "name": "cursor"
},
v26 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v27 = {
  "kind": "ScalarField",
  "name": "id"
},
v28 = {
  "kind": "ScalarField",
  "name": "key"
},
v29 = {
  "kind": "ScalarField",
  "name": "issueId"
},
v30 = {
  "kind": "ScalarField",
  "name": "isResolved"
},
v31 = {
  "kind": "ScalarField",
  "name": "fieldId"
},
v32 = {
  "kind": "ScalarField",
  "name": "statusCategoryId"
},
v33 = {
  "concreteType": "JiraStatusCategory",
  "kind": "LinkedField",
  "name": "statusCategory",
  "plural": false,
  "selections": [
    (v32/*: any*/),
    (v27/*: any*/)
  ]
},
v34 = {
  "args": [
    {
      "kind": "Literal",
      "name": "ids",
      "value": [
        "status"
      ]
    }
  ],
  "concreteType": "JiraIssueFieldConnection",
  "kind": "LinkedField",
  "name": "fieldsById",
  "plural": false,
  "selections": [
    {
      "concreteType": "JiraIssueFieldEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v26/*: any*/),
            {
              "kind": "InlineFragment",
              "selections": [
                (v31/*: any*/),
                {
                  "concreteType": "JiraStatus",
                  "kind": "LinkedField",
                  "name": "status",
                  "plural": false,
                  "selections": [
                    (v33/*: any*/),
                    (v27/*: any*/)
                  ]
                }
              ],
              "type": "JiraStatusField"
            },
            (v27/*: any*/)
          ]
        }
      ]
    }
  ],
  "storageKey": "fieldsById(ids:[\"status\"])"
},
v35 = {
  "kind": "Variable",
  "name": "filterId",
  "variableName": "filterId"
},
v36 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "amountOfColumns"
},
v37 = {
  "kind": "Variable",
  "name": "namespace",
  "variableName": "namespace"
},
v38 = {
  "kind": "Variable",
  "name": "viewId",
  "variableName": "viewId"
},
v39 = [
  {
    "fields": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "issueType",
            "variableName": "issueTypeId"
          },
          {
            "kind": "Variable",
            "name": "project",
            "variableName": "projectId"
          }
        ],
        "kind": "ObjectValue",
        "name": "projectContext"
      }
    ],
    "kind": "ObjectValue",
    "name": "context"
  },
  (v35/*: any*/),
  (v36/*: any*/),
  (v37/*: any*/),
  (v38/*: any*/)
],
v40 = {
  "kind": "ScalarField",
  "name": "name"
},
v41 = {
  "kind": "ScalarField",
  "name": "text"
},
v42 = {
  "kind": "ScalarField",
  "name": "picture"
},
v43 = {
  "concreteType": "JiraAvatar",
  "kind": "LinkedField",
  "name": "avatar",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "small"
    }
  ]
},
v44 = {
  "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjscm2apiupdatesrelayprovider",
  "kind": "Condition",
  "passingValue": true,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "hasChildren"
    },
    {
      "concreteType": "JiraIssueTypeField",
      "kind": "LinkedField",
      "name": "issueTypeField",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraIssueType",
          "kind": "LinkedField",
          "name": "issueType",
          "plural": false,
          "selections": [
            {
              "concreteType": "JiraIssueTypeHierarchyLevel",
              "kind": "LinkedField",
              "name": "hierarchy",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "level"
                }
              ]
            },
            (v27/*: any*/)
          ]
        },
        (v27/*: any*/)
      ]
    }
  ]
},
v45 = {
  "kind": "ScalarField",
  "name": "type"
},
v46 = {
  "kind": "ScalarField",
  "name": "fieldSetId"
},
v47 = {
  "kind": "TypeDiscriminator",
  "abstractKey": "__isJiraIssueField"
},
v48 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "JiraAffectedServiceConnection",
      "kind": "LinkedField",
      "name": "selectedAffectedServicesConnection",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraAffectedServiceEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "JiraAffectedService",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "serviceId"
                },
                (v40/*: any*/),
                (v27/*: any*/)
              ]
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraAffectedServicesField"
},
v49 = {
  "kind": "ScalarField",
  "name": "message"
},
v50 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "JiraCmdbObjectConnection",
      "kind": "LinkedField",
      "name": "selectedCmdbObjectsConnection",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraCmdbObjectEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "JiraCmdbObject",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "objectId"
                },
                {
                  "kind": "ScalarField",
                  "name": "label"
                },
                {
                  "concreteType": "JiraCmdbAvatar",
                  "kind": "LinkedField",
                  "name": "avatar",
                  "plural": false,
                  "selections": [
                    {
                      "condition": "__relay_internal__pv__atlassianjirarelayprovidersrccmdbmediaavatarfgrelayprovider",
                      "kind": "Condition",
                      "passingValue": true,
                      "selections": [
                        {
                          "concreteType": "JiraCmdbMediaClientConfig",
                          "kind": "LinkedField",
                          "name": "mediaClientConfig",
                          "plural": false,
                          "selections": [
                            {
                              "kind": "ScalarField",
                              "name": "clientId"
                            },
                            {
                              "kind": "ScalarField",
                              "name": "fileId"
                            },
                            {
                              "kind": "ScalarField",
                              "name": "mediaBaseUrl"
                            },
                            {
                              "kind": "ScalarField",
                              "name": "mediaJwtToken"
                            }
                          ]
                        }
                      ]
                    },
                    {
                      "kind": "ScalarField",
                      "name": "url48"
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          "concreteType": "QueryError",
          "kind": "LinkedField",
          "name": "errors",
          "plural": true,
          "selections": [
            (v49/*: any*/)
          ]
        }
      ]
    }
  ],
  "type": "JiraCMDBField"
},
v51 = [
  (v40/*: any*/),
  (v27/*: any*/)
],
v52 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "JiraComponentConnection",
      "kind": "LinkedField",
      "name": "selectedComponentsConnection",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraComponentEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "JiraComponent",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": (v51/*: any*/)
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraComponentsField"
},
v53 = {
  "kind": "ScalarField",
  "name": "date"
},
v54 = {
  "concreteType": "JiraFieldConfig",
  "kind": "LinkedField",
  "name": "fieldConfig",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "isRequired"
    }
  ]
},
v55 = {
  "kind": "ScalarField",
  "name": "lazyIsEditableInIssueView"
},
v56 = {
  "kind": "ClientExtension",
  "selections": [
    (v55/*: any*/)
  ]
},
v57 = {
  "kind": "InlineFragment",
  "selections": [
    (v53/*: any*/),
    (v40/*: any*/),
    (v54/*: any*/),
    (v56/*: any*/)
  ],
  "type": "JiraDatePickerField"
},
v58 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "dateTime"
    }
  ],
  "type": "JiraDateTimePickerField"
},
v59 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "JiraEpic",
      "kind": "LinkedField",
      "name": "epic",
      "plural": false,
      "selections": [
        (v28/*: any*/),
        {
          "kind": "ScalarField",
          "name": "summary"
        },
        {
          "kind": "ScalarField",
          "name": "color"
        },
        (v27/*: any*/)
      ]
    }
  ],
  "type": "JiraEpicLinkField"
},
v60 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "renderedFieldHtml"
    }
  ],
  "type": "JiraFallbackField"
},
v61 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "JiraStatusCategory",
      "kind": "LinkedField",
      "name": "statusCategory",
      "plural": false,
      "selections": [
        (v40/*: any*/),
        (v32/*: any*/),
        (v27/*: any*/)
      ]
    }
  ],
  "type": "JiraStatusCategoryField"
},
v62 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "JiraStatus",
      "kind": "LinkedField",
      "name": "status",
      "plural": false,
      "selections": [
        (v40/*: any*/),
        {
          "kind": "ScalarField",
          "name": "statusId"
        },
        (v33/*: any*/),
        (v27/*: any*/)
      ]
    }
  ],
  "type": "JiraStatusField"
},
v63 = [
  (v40/*: any*/)
],
v64 = {
  "kind": "ScalarField",
  "name": "totalCount"
},
v65 = {
  "args": [
    {
      "kind": "Literal",
      "name": "first",
      "value": 1000
    }
  ],
  "concreteType": "JiraLabelConnection",
  "kind": "LinkedField",
  "name": "selectedLabelsConnection",
  "plural": false,
  "selections": [
    {
      "concreteType": "JiraLabelEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "concreteType": "JiraLabel",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": (v63/*: any*/)
        }
      ]
    },
    (v64/*: any*/)
  ],
  "storageKey": "selectedLabelsConnection(first:1000)"
},
v66 = {
  "kind": "InlineFragment",
  "selections": [
    (v65/*: any*/),
    (v40/*: any*/),
    (v56/*: any*/)
  ],
  "type": "JiraLabelsField"
},
v67 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "JiraIssueType",
      "kind": "LinkedField",
      "name": "issueType",
      "plural": false,
      "selections": [
        (v43/*: any*/),
        (v40/*: any*/),
        (v27/*: any*/)
      ]
    }
  ],
  "type": "JiraIssueTypeField"
},
v68 = [
  {
    "concreteType": "JiraColor",
    "kind": "LinkedField",
    "name": "color",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "name": "colorKey"
      },
      (v27/*: any*/)
    ]
  },
  (v27/*: any*/)
],
v69 = {
  "concreteType": "JiraAvatar",
  "kind": "LinkedField",
  "name": "avatar",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "xsmall"
    }
  ]
},
v70 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "JiraIssue",
      "kind": "LinkedField",
      "name": "parentIssue",
      "plural": false,
      "selections": [
        (v28/*: any*/),
        {
          "concreteType": "JiraSingleLineTextField",
          "kind": "LinkedField",
          "name": "summaryField",
          "plural": false,
          "selections": [
            (v41/*: any*/),
            (v27/*: any*/)
          ]
        },
        (v27/*: any*/),
        {
          "concreteType": "JiraColorField",
          "kind": "LinkedField",
          "name": "issueColorField",
          "plural": false,
          "selections": (v68/*: any*/)
        },
        {
          "concreteType": "JiraIssueTypeField",
          "kind": "LinkedField",
          "name": "issueTypeField",
          "plural": false,
          "selections": [
            {
              "concreteType": "JiraIssueType",
              "kind": "LinkedField",
              "name": "issueType",
              "plural": false,
              "selections": [
                (v69/*: any*/),
                (v40/*: any*/),
                (v27/*: any*/)
              ]
            },
            (v27/*: any*/)
          ]
        }
      ]
    }
  ],
  "type": "JiraParentIssueField"
},
v71 = {
  "kind": "ScalarField",
  "name": "iconUrl"
},
v72 = {
  "concreteType": "JiraPriority",
  "kind": "LinkedField",
  "name": "priority",
  "plural": false,
  "selections": [
    (v40/*: any*/),
    (v71/*: any*/),
    (v27/*: any*/)
  ]
},
v73 = {
  "kind": "InlineFragment",
  "selections": [
    (v72/*: any*/),
    (v40/*: any*/),
    (v56/*: any*/)
  ],
  "type": "JiraPriorityField"
},
v74 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "JiraProject",
      "kind": "LinkedField",
      "name": "project",
      "plural": false,
      "selections": [
        (v40/*: any*/),
        (v28/*: any*/),
        {
          "concreteType": "JiraAvatar",
          "kind": "LinkedField",
          "name": "avatar",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "name": "large"
            }
          ]
        },
        (v27/*: any*/)
      ]
    }
  ],
  "type": "JiraProjectField"
},
v75 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "JiraResolution",
      "kind": "LinkedField",
      "name": "resolution",
      "plural": false,
      "selections": (v51/*: any*/)
    }
  ],
  "type": "JiraResolutionField"
},
v76 = {
  "kind": "ScalarField",
  "name": "state"
},
v77 = {
  "concreteType": "JiraSprintConnection",
  "kind": "LinkedField",
  "name": "selectedSprintsConnection",
  "plural": false,
  "selections": [
    {
      "concreteType": "JiraSprintEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "concreteType": "JiraSprint",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v27/*: any*/),
            {
              "kind": "ScalarField",
              "name": "sprintId"
            },
            (v40/*: any*/),
            (v76/*: any*/),
            {
              "kind": "ScalarField",
              "name": "endDate"
            }
          ]
        }
      ]
    }
  ]
},
v78 = {
  "kind": "InlineFragment",
  "selections": [
    (v77/*: any*/),
    (v40/*: any*/),
    (v56/*: any*/)
  ],
  "type": "JiraSprintField"
},
v79 = {
  "kind": "ScalarField",
  "name": "accountId"
},
v80 = {
  "kind": "ScalarField",
  "name": "accountStatus"
},
v81 = {
  "kind": "LinkedField",
  "name": "user",
  "plural": false,
  "selections": [
    (v26/*: any*/),
    (v79/*: any*/),
    (v42/*: any*/),
    (v40/*: any*/),
    (v27/*: any*/),
    (v80/*: any*/)
  ]
},
v82 = {
  "kind": "InlineFragment",
  "selections": [
    (v40/*: any*/),
    {
      "concreteType": "JiraVersionConnection",
      "kind": "LinkedField",
      "name": "selectedVersionsConnection",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraVersionEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "JiraVersion",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": (v51/*: any*/)
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraMultipleVersionPickerField"
},
v83 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "JiraRichText",
      "kind": "LinkedField",
      "name": "richText",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraADF",
          "kind": "LinkedField",
          "name": "adfValue",
          "plural": false,
          "selections": [
            {
              "concreteType": "JiraAdfToConvertedPlainText",
              "kind": "LinkedField",
              "name": "convertedPlainText",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "plainText"
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraRichTextField"
},
v84 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "JiraServiceManagementRequestType",
      "kind": "LinkedField",
      "name": "requestType",
      "plural": false,
      "selections": [
        (v40/*: any*/),
        (v69/*: any*/),
        (v27/*: any*/)
      ]
    }
  ],
  "type": "JiraServiceManagementRequestTypeField"
},
v85 = {
  "kind": "ScalarField",
  "name": "hasNextPage"
},
v86 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "first",
          "variableName": "__relay_internal__pv__atlassianjirarelayprovidersrcissuelinkscountrelayprovider"
        }
      ],
      "concreteType": "JiraIssueLinkConnection",
      "kind": "LinkedField",
      "name": "issueLinkConnection",
      "plural": false,
      "selections": [
        {
          "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcissuelinksentrypointrelayprovider",
          "kind": "Condition",
          "passingValue": false,
          "selections": [
            {
              "concreteType": "PageInfo",
              "kind": "LinkedField",
              "name": "pageInfo",
              "plural": false,
              "selections": [
                (v85/*: any*/)
              ]
            }
          ]
        },
        {
          "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcissuelinksentrypointrelayprovider",
          "kind": "Condition",
          "passingValue": true,
          "selections": [
            (v64/*: any*/)
          ]
        },
        {
          "concreteType": "JiraIssueLinkEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "JiraIssueLink",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "concreteType": "JiraIssue",
                  "kind": "LinkedField",
                  "name": "issue",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "name": "webUrl"
                    },
                    (v28/*: any*/),
                    {
                      "args": [
                        {
                          "kind": "Literal",
                          "name": "ids",
                          "value": [
                            "statusCategory"
                          ]
                        }
                      ],
                      "concreteType": "JiraIssueFieldConnection",
                      "kind": "LinkedField",
                      "name": "fieldsById",
                      "plural": false,
                      "selections": [
                        {
                          "concreteType": "JiraIssueFieldEdge",
                          "kind": "LinkedField",
                          "name": "edges",
                          "plural": true,
                          "selections": [
                            {
                              "kind": "LinkedField",
                              "name": "node",
                              "plural": false,
                              "selections": [
                                (v26/*: any*/),
                                {
                                  "kind": "InlineFragment",
                                  "selections": [
                                    (v33/*: any*/)
                                  ],
                                  "type": "JiraStatusCategoryField"
                                },
                                (v27/*: any*/)
                              ]
                            }
                          ]
                        }
                      ],
                      "storageKey": "fieldsById(ids:[\"statusCategory\"])"
                    },
                    (v27/*: any*/)
                  ]
                },
                {
                  "kind": "ScalarField",
                  "name": "relationName"
                },
                (v27/*: any*/)
              ]
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraIssueLinkField"
},
v87 = {
  "kind": "ScalarField",
  "name": "isStoryPointField"
},
v88 = {
  "kind": "ScalarField",
  "name": "number"
},
v89 = {
  "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcnikechinstorypointsinlineeditrelayprovider",
  "kind": "Condition",
  "passingValue": true,
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        (v87/*: any*/),
        (v88/*: any*/),
        (v40/*: any*/),
        (v56/*: any*/)
      ],
      "type": "JiraNumberField"
    }
  ]
},
v90 = [
  {
    "concreteType": "JiraIssueFieldSetEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "concreteType": "JiraIssueFieldSet",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v45/*: any*/),
          (v46/*: any*/),
          {
            "concreteType": "JiraIssueFieldConnection",
            "kind": "LinkedField",
            "name": "fields",
            "plural": false,
            "selections": [
              {
                "concreteType": "JiraIssueFieldEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v26/*: any*/),
                      (v31/*: any*/),
                      (v45/*: any*/),
                      (v47/*: any*/),
                      (v27/*: any*/),
                      (v48/*: any*/),
                      (v50/*: any*/),
                      (v52/*: any*/),
                      (v57/*: any*/),
                      (v58/*: any*/),
                      (v59/*: any*/),
                      (v60/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v41/*: any*/),
                          (v40/*: any*/),
                          (v56/*: any*/)
                        ],
                        "type": "JiraSingleLineTextField"
                      },
                      (v61/*: any*/),
                      (v62/*: any*/),
                      (v66/*: any*/),
                      (v67/*: any*/),
                      (v70/*: any*/),
                      (v73/*: any*/),
                      (v74/*: any*/),
                      (v75/*: any*/),
                      (v78/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v81/*: any*/),
                          (v40/*: any*/),
                          (v54/*: any*/),
                          (v56/*: any*/)
                        ],
                        "type": "JiraSingleSelectUserPickerField"
                      },
                      (v82/*: any*/),
                      (v83/*: any*/),
                      (v84/*: any*/),
                      (v86/*: any*/),
                      (v89/*: any*/)
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
],
v91 = {
  "condition": "shouldQueryFieldSetsById",
  "kind": "Condition",
  "passingValue": true,
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "fieldSetIds",
          "variableName": "fieldSetIds"
        },
        (v36/*: any*/)
      ],
      "concreteType": "JiraIssueFieldSetConnection",
      "kind": "LinkedField",
      "name": "fieldSetsById",
      "plural": false,
      "selections": (v90/*: any*/)
    }
  ]
},
v92 = {
  "condition": "shouldQueryFieldSetsById",
  "kind": "Condition",
  "passingValue": false,
  "selections": [
    {
      "args": (v39/*: any*/),
      "concreteType": "JiraIssueFieldSetConnection",
      "kind": "LinkedField",
      "name": "fieldSetsForIssueSearchView",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraIssueFieldSetEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "JiraIssueFieldSet",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v45/*: any*/),
                (v46/*: any*/),
                {
                  "concreteType": "JiraIssueFieldConnection",
                  "kind": "LinkedField",
                  "name": "fields",
                  "plural": false,
                  "selections": [
                    {
                      "concreteType": "JiraIssueFieldEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            (v31/*: any*/),
                            (v45/*: any*/),
                            (v47/*: any*/),
                            (v48/*: any*/),
                            (v50/*: any*/),
                            (v52/*: any*/),
                            (v57/*: any*/),
                            (v58/*: any*/),
                            (v59/*: any*/),
                            (v60/*: any*/),
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v45/*: any*/),
                                (v56/*: any*/)
                              ],
                              "type": "JiraSingleLineTextField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                {
                                  "concreteType": "JiraStatusCategory",
                                  "kind": "LinkedField",
                                  "name": "statusCategory",
                                  "plural": false,
                                  "selections": (v63/*: any*/)
                                }
                              ],
                              "type": "JiraStatusCategoryField"
                            },
                            (v62/*: any*/),
                            (v66/*: any*/),
                            (v70/*: any*/),
                            (v73/*: any*/),
                            (v74/*: any*/),
                            (v75/*: any*/),
                            (v78/*: any*/),
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v45/*: any*/),
                                {
                                  "kind": "LinkedField",
                                  "name": "user",
                                  "plural": false,
                                  "selections": [
                                    (v79/*: any*/),
                                    (v80/*: any*/)
                                  ]
                                },
                                (v54/*: any*/),
                                (v56/*: any*/)
                              ],
                              "type": "JiraSingleSelectUserPickerField"
                            },
                            (v82/*: any*/),
                            (v83/*: any*/),
                            (v84/*: any*/),
                            (v86/*: any*/),
                            (v89/*: any*/)
                          ]
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
},
v93 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "concreteType": "JiraIssueFieldSetConnection",
      "kind": "LinkedField",
      "name": "issueRowFieldSets",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraIssueFieldSetEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "JiraIssueFieldSet",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v45/*: any*/),
                (v46/*: any*/),
                {
                  "concreteType": "JiraIssueFieldConnection",
                  "kind": "LinkedField",
                  "name": "fields",
                  "plural": false,
                  "selections": [
                    {
                      "concreteType": "JiraIssueFieldEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            (v26/*: any*/),
                            (v31/*: any*/),
                            (v45/*: any*/),
                            (v47/*: any*/),
                            (v27/*: any*/),
                            (v48/*: any*/),
                            (v50/*: any*/),
                            (v52/*: any*/),
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v53/*: any*/),
                                (v55/*: any*/),
                                (v40/*: any*/),
                                (v54/*: any*/)
                              ],
                              "type": "JiraDatePickerField"
                            },
                            (v58/*: any*/),
                            (v59/*: any*/),
                            (v60/*: any*/),
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v41/*: any*/),
                                (v55/*: any*/),
                                (v40/*: any*/)
                              ],
                              "type": "JiraSingleLineTextField"
                            },
                            (v61/*: any*/),
                            (v62/*: any*/),
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v65/*: any*/),
                                (v55/*: any*/),
                                (v40/*: any*/)
                              ],
                              "type": "JiraLabelsField"
                            },
                            (v67/*: any*/),
                            (v70/*: any*/),
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v55/*: any*/),
                                (v72/*: any*/),
                                (v40/*: any*/)
                              ],
                              "type": "JiraPriorityField"
                            },
                            (v74/*: any*/),
                            (v75/*: any*/),
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v55/*: any*/),
                                (v77/*: any*/),
                                (v40/*: any*/)
                              ],
                              "type": "JiraSprintField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v81/*: any*/),
                                (v55/*: any*/),
                                (v40/*: any*/),
                                (v54/*: any*/)
                              ],
                              "type": "JiraSingleSelectUserPickerField"
                            },
                            (v82/*: any*/),
                            (v83/*: any*/),
                            (v84/*: any*/),
                            (v86/*: any*/),
                            {
                              "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcnikechinstorypointsinlineeditrelayprovider",
                              "kind": "Condition",
                              "passingValue": true,
                              "selections": [
                                {
                                  "kind": "InlineFragment",
                                  "selections": [
                                    (v87/*: any*/),
                                    (v88/*: any*/),
                                    (v55/*: any*/),
                                    (v40/*: any*/)
                                  ],
                                  "type": "JiraNumberField"
                                }
                              ]
                            }
                          ]
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
},
v94 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "isHighlightedIssueRow"
    }
  ]
},
v95 = {
  "kind": "ScalarField",
  "name": "pageNumber"
},
v96 = {
  "kind": "ScalarField",
  "name": "isCurrent"
},
v97 = [
  (v95/*: any*/),
  (v25/*: any*/),
  (v96/*: any*/)
],
v98 = {
  "kind": "ScalarField",
  "name": "endCursor"
},
v99 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "__id"
    }
  ]
},
v100 = {
  "kind": "ScalarField",
  "name": "displayName"
},
v101 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 50
  }
],
v102 = {
  "condition": "shouldQueryFieldSetsById",
  "kind": "Condition",
  "passingValue": false,
  "selections": [
    {
      "args": (v39/*: any*/),
      "concreteType": "JiraIssueFieldSetConnection",
      "kind": "LinkedField",
      "name": "fieldSetsForIssueSearchView",
      "plural": false,
      "selections": (v90/*: any*/)
    }
  ]
},
v103 = {
  "kind": "ScalarField",
  "name": "jql"
},
v104 = {
  "kind": "ScalarField",
  "name": "fieldType"
},
v105 = {
  "kind": "ScalarField",
  "name": "issueCount"
},
v106 = {
  "kind": "LinkedField",
  "name": "fieldValue",
  "plural": false,
  "selections": [
    (v26/*: any*/),
    {
      "kind": "InlineFragment",
      "selections": [
        (v100/*: any*/),
        {
          "concreteType": "JiraPriority",
          "kind": "LinkedField",
          "name": "priority",
          "plural": false,
          "selections": [
            (v71/*: any*/),
            (v27/*: any*/)
          ]
        }
      ],
      "type": "JiraJqlPriorityFieldValue"
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v100/*: any*/),
        {
          "kind": "LinkedField",
          "name": "user",
          "plural": false,
          "selections": [
            (v26/*: any*/),
            (v42/*: any*/),
            (v27/*: any*/)
          ]
        }
      ],
      "type": "JiraJqlUserFieldValue"
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v100/*: any*/),
        (v33/*: any*/)
      ],
      "type": "JiraJqlStatusFieldValue"
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v100/*: any*/),
        {
          "concreteType": "JiraOption",
          "kind": "LinkedField",
          "name": "option",
          "plural": false,
          "selections": (v68/*: any*/)
        }
      ],
      "type": "JiraJqlOptionFieldValue"
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v100/*: any*/),
        {
          "concreteType": "JiraSprint",
          "kind": "LinkedField",
          "name": "sprint",
          "plural": false,
          "selections": [
            (v76/*: any*/),
            (v27/*: any*/)
          ]
        }
      ],
      "type": "JiraJqlSprintFieldValue"
    }
  ]
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/),
      (v10/*: any*/),
      (v11/*: any*/),
      (v12/*: any*/),
      (v13/*: any*/),
      (v14/*: any*/),
      (v15/*: any*/),
      (v16/*: any*/),
      (v17/*: any*/)
    ],
    "kind": "Fragment",
    "name": "IssueNavigatorIssueSearchRefetchQuery",
    "selections": [
      {
        "kind": "FragmentSpread",
        "name": "main_issueNavigator_IssueNavigator_refetchQueryNew"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/),
      (v10/*: any*/),
      (v11/*: any*/),
      (v12/*: any*/),
      (v13/*: any*/),
      (v14/*: any*/),
      (v15/*: any*/),
      (v16/*: any*/),
      (v17/*: any*/),
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcisrefactornintoviewschemaenabledrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcisbulkopsinninenabledrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcjscm2apiupdatesrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcjscm1apiupdatesrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcnikechinstorypointsinlineeditrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrccmdbmediaavatarfgrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcissuelinksentrypointrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcissuelinkscountrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcisjscinfinitescrollenabledrelayprovider"
      }
    ],
    "kind": "Operation",
    "name": "IssueNavigatorIssueSearchRefetchQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v24/*: any*/),
            "concreteType": "JiraIssueConnection",
            "kind": "LinkedField",
            "name": "issueSearch",
            "plural": false,
            "selections": [
              {
                "concreteType": "JiraIssueEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  (v25/*: any*/),
                  {
                    "concreteType": "JiraIssue",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v26/*: any*/),
                      (v27/*: any*/),
                      (v28/*: any*/),
                      (v29/*: any*/),
                      (v30/*: any*/),
                      (v34/*: any*/),
                      {
                        "args": (v39/*: any*/),
                        "concreteType": "JiraIssueFieldSetConnection",
                        "kind": "LinkedField",
                        "name": "fieldSetsForIssueSearchView",
                        "plural": false,
                        "selections": [
                          {
                            "concreteType": "JiraIssueFieldSetEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "concreteType": "JiraIssueFieldSet",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  {
                                    "concreteType": "JiraIssueFieldConnection",
                                    "kind": "LinkedField",
                                    "name": "fields",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "concreteType": "JiraIssueFieldEdge",
                                        "kind": "LinkedField",
                                        "name": "edges",
                                        "plural": true,
                                        "selections": [
                                          {
                                            "kind": "LinkedField",
                                            "name": "node",
                                            "plural": false,
                                            "selections": [
                                              (v26/*: any*/),
                                              {
                                                "kind": "InlineFragment",
                                                "selections": [
                                                  (v31/*: any*/),
                                                  (v40/*: any*/),
                                                  (v41/*: any*/)
                                                ],
                                                "type": "JiraSingleLineTextField"
                                              },
                                              (v27/*: any*/),
                                              {
                                                "kind": "InlineFragment",
                                                "selections": [
                                                  (v31/*: any*/),
                                                  (v40/*: any*/),
                                                  {
                                                    "kind": "LinkedField",
                                                    "name": "user",
                                                    "plural": false,
                                                    "selections": [
                                                      (v26/*: any*/),
                                                      (v40/*: any*/),
                                                      (v42/*: any*/),
                                                      (v27/*: any*/)
                                                    ]
                                                  }
                                                ],
                                                "type": "JiraSingleSelectUserPickerField"
                                              },
                                              {
                                                "kind": "InlineFragment",
                                                "selections": [
                                                  (v31/*: any*/),
                                                  {
                                                    "concreteType": "JiraIssueType",
                                                    "kind": "LinkedField",
                                                    "name": "issueType",
                                                    "plural": false,
                                                    "selections": [
                                                      (v40/*: any*/),
                                                      (v43/*: any*/),
                                                      (v27/*: any*/)
                                                    ]
                                                  }
                                                ],
                                                "type": "JiraIssueTypeField"
                                              },
                                              {
                                                "kind": "InlineFragment",
                                                "selections": [
                                                  (v31/*: any*/),
                                                  (v33/*: any*/)
                                                ],
                                                "type": "JiraStatusCategoryField"
                                              }
                                            ]
                                          }
                                        ]
                                      }
                                    ]
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      },
                      (v44/*: any*/),
                      {
                        "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjscm1apiupdatesrelayprovider",
                        "kind": "Condition",
                        "passingValue": false,
                        "selections": [
                          (v91/*: any*/),
                          (v92/*: any*/)
                        ]
                      },
                      {
                        "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjscm1apiupdatesrelayprovider",
                        "kind": "Condition",
                        "passingValue": true,
                        "selections": [
                          (v91/*: any*/),
                          (v92/*: any*/),
                          (v93/*: any*/)
                        ]
                      },
                      (v94/*: any*/)
                    ]
                  },
                  (v26/*: any*/)
                ]
              },
              {
                "concreteType": "JiraIssueNavigatorPageInfo",
                "kind": "LinkedField",
                "name": "issueNavigatorPageInfo",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "firstIssuePosition"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "lastIssuePosition"
                  }
                ]
              },
              {
                "args": [
                  {
                    "kind": "Literal",
                    "name": "maxCursors",
                    "value": 7
                  }
                ],
                "concreteType": "JiraPageCursors",
                "kind": "LinkedField",
                "name": "pageCursors",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraPageCursor",
                    "kind": "LinkedField",
                    "name": "around",
                    "plural": true,
                    "selections": (v97/*: any*/)
                  },
                  {
                    "concreteType": "JiraPageCursor",
                    "kind": "LinkedField",
                    "name": "last",
                    "plural": false,
                    "selections": (v97/*: any*/)
                  },
                  {
                    "concreteType": "JiraPageCursor",
                    "kind": "LinkedField",
                    "name": "first",
                    "plural": false,
                    "selections": [
                      (v25/*: any*/),
                      (v95/*: any*/),
                      (v96/*: any*/)
                    ]
                  }
                ],
                "storageKey": "pageCursors(maxCursors:7)"
              },
              {
                "kind": "ScalarField",
                "name": "totalIssueSearchResultCount"
              },
              {
                "kind": "LinkedField",
                "name": "issueSearchError",
                "plural": false,
                "selections": [
                  (v26/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "name": "messages"
                      }
                    ],
                    "type": "JiraInvalidJqlError"
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v49/*: any*/),
                      {
                        "kind": "ScalarField",
                        "name": "errorType"
                      }
                    ],
                    "type": "JiraInvalidSyntaxError"
                  }
                ]
              },
              {
                "kind": "ScalarField",
                "name": "isCappingIssueSearchResult"
              },
              {
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  (v98/*: any*/),
                  (v85/*: any*/),
                  {
                    "kind": "ScalarField",
                    "name": "hasPreviousPage"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "startCursor"
                  }
                ]
              },
              (v99/*: any*/),
              {
                "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcisbulkopsinninenabledrelayprovider",
                "kind": "Condition",
                "passingValue": true,
                "selections": [
                  {
                    "concreteType": "JiraIssueEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "concreteType": "JiraIssue",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "args": (v39/*: any*/),
                            "concreteType": "JiraIssueFieldSetConnection",
                            "kind": "LinkedField",
                            "name": "fieldSetsForIssueSearchView",
                            "plural": false,
                            "selections": [
                              {
                                "concreteType": "JiraIssueFieldSetEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "concreteType": "JiraIssueFieldSet",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "concreteType": "JiraIssueFieldConnection",
                                        "kind": "LinkedField",
                                        "name": "fields",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "concreteType": "JiraIssueFieldEdge",
                                            "kind": "LinkedField",
                                            "name": "edges",
                                            "plural": true,
                                            "selections": [
                                              {
                                                "kind": "LinkedField",
                                                "name": "node",
                                                "plural": false,
                                                "selections": [
                                                  (v47/*: any*/),
                                                  (v31/*: any*/)
                                                ]
                                              }
                                            ]
                                          }
                                        ]
                                      }
                                    ]
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            "args": (v24/*: any*/),
            "filters": [
              "cloudId",
              "issueSearchInput",
              "options",
              "viewConfigInput"
            ],
            "handle": "connection",
            "key": "IssueNavigatorIssueSearchPagination__issueSearch",
            "kind": "LinkedHandle",
            "name": "issueSearch"
          },
          {
            "args": [
              (v20/*: any*/),
              (v35/*: any*/),
              (v22/*: any*/),
              (v37/*: any*/),
              (v38/*: any*/)
            ],
            "kind": "LinkedField",
            "name": "issueSearchViewResult",
            "plural": false,
            "selections": [
              (v26/*: any*/),
              {
                "kind": "TypeDiscriminator",
                "abstractKey": "__isJiraIssueSearchViewResult"
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "viewId"
                  },
                  (v27/*: any*/),
                  {
                    "kind": "ScalarField",
                    "name": "hasDefaultFieldSets"
                  },
                  {
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "filter",
                        "value": {
                          "fieldSetSelectedState": "SELECTED"
                        }
                      },
                      (v36/*: any*/)
                    ],
                    "concreteType": "JiraIssueSearchFieldSetConnection",
                    "kind": "LinkedField",
                    "name": "fieldSets",
                    "plural": false,
                    "selections": [
                      (v64/*: any*/),
                      {
                        "concreteType": "JiraIssueSearchFieldSetEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "concreteType": "JiraIssueSearchFieldSet",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v46/*: any*/),
                              (v45/*: any*/),
                              (v100/*: any*/),
                              {
                                "kind": "ScalarField",
                                "name": "jqlTerm"
                              },
                              {
                                "kind": "ScalarField",
                                "name": "isSortable"
                              },
                              {
                                "concreteType": "JiraFieldSetPreferences",
                                "kind": "LinkedField",
                                "name": "fieldSetPreferences",
                                "plural": false,
                                "selections": [
                                  {
                                    "kind": "ScalarField",
                                    "name": "width"
                                  }
                                ]
                              },
                              {
                                "concreteType": "JiraFieldType",
                                "kind": "LinkedField",
                                "name": "fieldType",
                                "plural": false,
                                "selections": [
                                  (v100/*: any*/)
                                ]
                              }
                            ]
                          }
                        ]
                      },
                      (v99/*: any*/)
                    ]
                  },
                  {
                    "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjscm2apiupdatesrelayprovider",
                    "kind": "Condition",
                    "passingValue": true,
                    "selections": [
                      {
                        "args": [
                          {
                            "kind": "Variable",
                            "name": "staticViewInput",
                            "variableName": "staticViewInput"
                          }
                        ],
                        "concreteType": "JiraIssueSearchViewConfigSettings",
                        "kind": "LinkedField",
                        "name": "viewConfigSettings",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "name": "isHierarchyEnabled"
                          },
                          {
                            "kind": "ScalarField",
                            "name": "canEnableHierarchy"
                          },
                          (v99/*: any*/)
                        ]
                      }
                    ]
                  }
                ],
                "type": "JiraIssueSearchView"
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  (v49/*: any*/),
                  {
                    "kind": "LinkedField",
                    "name": "extensions",
                    "plural": true,
                    "selections": [
                      (v26/*: any*/),
                      {
                        "kind": "ScalarField",
                        "name": "statusCode"
                      }
                    ]
                  }
                ],
                "type": "QueryError"
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  (v27/*: any*/)
                ],
                "type": "Node",
                "abstractKey": "__isNode"
              }
            ]
          },
          {
            "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcisrefactornintoviewschemaenabledrelayprovider",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "args": [
                  (v20/*: any*/),
                  (v22/*: any*/),
                  (v37/*: any*/),
                  (v38/*: any*/)
                ],
                "kind": "LinkedField",
                "name": "jiraIssueSearchView",
                "plural": false,
                "selections": [
                  (v26/*: any*/),
                  (v27/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "args": [
                          (v18/*: any*/),
                          (v19/*: any*/),
                          (v21/*: any*/),
                          (v22/*: any*/),
                          (v23/*: any*/)
                        ],
                        "concreteType": "JiraSpreadsheetGroupConnection",
                        "kind": "LinkedField",
                        "name": "groups",
                        "plural": false,
                        "selections": [
                          {
                            "concreteType": "JiraSpreadsheetGroup",
                            "kind": "LinkedField",
                            "name": "firstGroup",
                            "plural": false,
                            "selections": [
                              (v27/*: any*/),
                              {
                                "args": (v101/*: any*/),
                                "concreteType": "JiraIssueConnection",
                                "kind": "LinkedField",
                                "name": "issues",
                                "plural": false,
                                "selections": [
                                  {
                                    "concreteType": "JiraIssueEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "concreteType": "JiraIssue",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": [
                                          (v29/*: any*/),
                                          (v27/*: any*/),
                                          (v26/*: any*/),
                                          (v28/*: any*/),
                                          (v30/*: any*/),
                                          (v34/*: any*/),
                                          (v44/*: any*/),
                                          {
                                            "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjscm1apiupdatesrelayprovider",
                                            "kind": "Condition",
                                            "passingValue": false,
                                            "selections": [
                                              (v91/*: any*/),
                                              (v102/*: any*/)
                                            ]
                                          },
                                          {
                                            "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjscm1apiupdatesrelayprovider",
                                            "kind": "Condition",
                                            "passingValue": true,
                                            "selections": [
                                              (v91/*: any*/),
                                              (v102/*: any*/),
                                              (v93/*: any*/)
                                            ]
                                          },
                                          (v94/*: any*/)
                                        ]
                                      },
                                      (v26/*: any*/),
                                      (v25/*: any*/)
                                    ]
                                  },
                                  {
                                    "concreteType": "PageInfo",
                                    "kind": "LinkedField",
                                    "name": "pageInfo",
                                    "plural": false,
                                    "selections": [
                                      (v98/*: any*/),
                                      (v85/*: any*/)
                                    ]
                                  },
                                  (v99/*: any*/)
                                ],
                                "storageKey": "issues(first:50)"
                              },
                              {
                                "args": (v101/*: any*/),
                                "handle": "connection",
                                "key": "firstGroupSection_nativeIssueTable__issues",
                                "kind": "LinkedHandle",
                                "name": "issues"
                              },
                              (v103/*: any*/),
                              (v104/*: any*/),
                              (v105/*: any*/),
                              (v106/*: any*/)
                            ]
                          },
                          {
                            "concreteType": "JiraSpreadsheetGroupEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "concreteType": "JiraSpreadsheetGroup",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v27/*: any*/),
                                  (v104/*: any*/),
                                  (v105/*: any*/),
                                  (v103/*: any*/),
                                  (v106/*: any*/)
                                ]
                              }
                            ]
                          },
                          (v99/*: any*/)
                        ]
                      }
                    ],
                    "type": "JiraGroupedListView"
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "ee527aef2a2a40d44b27f2fc86c42817d720c54bf08f9370ab8b00596206eb28",
    "metadata": {},
    "name": "IssueNavigatorIssueSearchRefetchQuery",
    "operationKind": "query",
    "text": null,
    "providedVariables": {
      "isRefactorNinToViewSchemaEnabled": isRefactorNinToViewSchemaEnabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcisrefactornintoviewschemaenabledrelayprovider": isRefactorNinToViewSchemaEnabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcisbulkopsinninenabledrelayprovider": bulkOpsInNinEnabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcjscm2apiupdatesrelayprovider": isJscIssueHierarchyEnabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcjscm1apiupdatesrelayprovider": isJscM1ApiUpdatesEnabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcnikechinstorypointsinlineeditrelayprovider": isNikeChinStoryPointsInlineEditEnabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrccmdbmediaavatarfgrelayprovider": isCmdbMediaAvatarEnabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcissuelinksentrypointrelayprovider": isEntryPointEnabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcissuelinkscountrelayprovider": count_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcisjscinfinitescrollenabledrelayprovider": isJscInfiniteScrollEnabled_provider
    }
  }
};
})();

(node as any).hash = "8e56b4674f49c8d00c94c738c6b308b9";

export default node;
