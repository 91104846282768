import React, { useRef, type ReactNode } from 'react';
import { styled } from '@compiled/react';
import { graphql, useFragment } from 'react-relay';
import { Stack, Inline, xcss, Box } from '@atlaskit/primitives';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import { useInfiniteScrollHandler } from '@atlassian/jira-native-issue-table/src/controllers/infinite-scroll-handler/index.tsx';
import { ScrollStateProvider } from '@atlassian/jira-native-issue-table/src/controllers/scroll-state/index.tsx';
import type { cardContainer_issueNavigator$key as CardContainerFragment } from '@atlassian/jira-relay/src/__generated__/cardContainer_issueNavigator.graphql';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import Feedback from '../../../../common/ui/feedback/index.tsx';
import PageText from '../../../../common/ui/page-text/index.tsx';
import RefreshButton from '../../../../common/ui/refresh-button/index.tsx';
import { useInfiniteScroll } from '../../../../services/issue-search/selectors.tsx';
import { LoadingManager } from './loading-manager/index.tsx';
import OrderDropdown from './order-dropdown/index.tsx';
import Paginator from './paginator/main.tsx';
import SortButton from './sort-button/index.tsx';

type Props = {
	issueResults: CardContainerFragment;
	children: ReactNode;
	isFeedbackButtonDisabled?: boolean;
};

const infiniteScrollOptions = {
	threshold: 5 * 70, // Approximately 5 min-height cards tall
};

const CardContainer = ({ children, issueResults, isFeedbackButtonDisabled }: Props) => {
	const data = useFragment<CardContainerFragment>(
		graphql`
			fragment cardContainer_issueNavigator on JiraIssueConnection
			@argumentDefinitions(
				isJscInfiniteScrollEnabled: {
					type: "Boolean!"
					provider: "@atlassian/jira-relay-provider/src/is-jsc-infinite-scroll-enabled.relayprovider"
				}
			) {
				...main_issueNavigator_Paginator @skip(if: $isJscInfiniteScrollEnabled)
				edges {
					__typename @include(if: $isJscInfiniteScrollEnabled)
				}
				totalIssueSearchResultCount
				isCappingIssueSearchResult
			}
		`,
		issueResults,
	);

	if (!expVal('jira_spreadsheet_component_m1', 'isInfiniteScrollingEnabled', false)) {
		return (
			<StyledContainer
				data-testid="issue-navigator.ui.issue-results.detail-view.card-container.styled-container"
				visualRefresh={fg('jira_nav4_beta_drop_1') && isVisualRefreshEnabled()}
			>
				<HeaderStyledContainer
					visualRefresh={fg('jira_nav4_beta_drop_1') && isVisualRefreshEnabled()}
				>
					<OrderDropdown />
					<SortButton />
					<RefreshButton />
				</HeaderStyledContainer>
				<CardListStyledContainer>{children}</CardListStyledContainer>
				<FooterStyledContainer
					visualRefresh={fg('jira_nav4_beta_drop_1') && isVisualRefreshEnabled()}
				>
					<Paginator issueResults={data} />
				</FooterStyledContainer>
				{!fg('jira_spreadsheet_component_m1_api_updates') && !isFeedbackButtonDisabled && (
					<FeedbackWrapper>
						<Feedback />
					</FeedbackWrapper>
				)}
			</StyledContainer>
		);
	}

	// eslint-disable-next-line react-hooks/rules-of-hooks
	const infiniteScrollProps = useInfiniteScroll(infiniteScrollOptions);
	const { isLoadingPrevious, isLoadingNext } = infiniteScrollProps;
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const { onScroll, onLoadingThresholdCheck } = useInfiniteScrollHandler(infiniteScrollProps);
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const scrollContainerRef = useRef<HTMLDivElement>(null);

	const totalIssues = data.totalIssueSearchResultCount ?? 0;

	return (
		<Stack
			space={isVisualRefreshEnabled() && fg('jira_nav4_beta_drop_1') ? undefined : 'space.025'}
			grow="fill"
			xcss={
				isVisualRefreshEnabled() && fg('jira_nav4_beta_drop_1')
					? containerStyles
					: containerStylesOld
			}
			testId="issue-navigator.ui.issue-results.detail-view.card-container.styled-container"
		>
			<Inline
				space="space.050"
				xcss={
					isVisualRefreshEnabled() && fg('jira_nav4_beta_drop_1') ? headerStyles : headerStylesOld
				}
				alignBlock={isVisualRefreshEnabled() && fg('jira_nav4_beta_drop_1') ? 'center' : undefined}
			>
				<OrderDropdown />
				<SortButton />
				<RefreshButton />
			</Inline>
			<ScrollStateProvider scrollRef={scrollContainerRef}>
				<Stack grow="fill" xcss={loadingIndicatorContainerStyles}>
					<Box ref={scrollContainerRef} xcss={cardListStyles} onScroll={onScroll}>
						{children}
					</Box>
					<LoadingManager
						isLoadingNext={isLoadingNext}
						isLoadingPrevious={isLoadingPrevious}
						onLoadingThresholdCheck={onLoadingThresholdCheck}
					/>
				</Stack>
			</ScrollStateProvider>
			{totalIssues != null && totalIssues > 0 && (
				<Inline alignBlock="center" alignInline="center" xcss={footerStyles}>
					<PageText
						startIssueRange={-1}
						endIssueRange={-1}
						total={totalIssues}
						countExceedsMaxResults={!!data.isCappingIssueSearchResult}
						loadedIssuesCount={data.edges?.length ?? 0}
						isInfiniteScrollEnabled
					/>
				</Inline>
			)}
		</Stack>
	);
};

export default CardContainer;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledContainer = styled.div<{ visualRefresh: boolean }>(
	{
		display: 'flex',
		flexDirection: 'column',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		borderRadius: `${gridSize / 2}px`,
		marginBottom: token('space.100', '8px'),
		marginRight: token('space.200', '16px'),
		minWidth: '256px',
		maxWidth: '256px',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
	({ visualRefresh }) =>
		visualRefresh
			? {
					paddingInline: token('space.025'),
					backgroundColor: token('elevation.surface.sunken'),
				}
			: {
					padding: token('space.025', '2px'),
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values
					backgroundColor: token('color.background.neutral', colors.N20),
				},
);

const containerStylesOld = xcss({
	borderRadius: '4px',
	backgroundColor: 'color.background.neutral',
	boxSizing: 'content-box',
	padding: 'space.025',
	marginBottom: 'space.100',
	marginRight: 'space.200',
	minWidth: '256px',
	maxWidth: '256px',
});

const containerStyles = xcss({
	borderRadius: '4px',
	backgroundColor: 'elevation.surface.sunken',
	boxSizing: 'content-box',
	paddingInline: 'space.025',
	marginBottom: 'space.100',
	marginRight: 'space.200',
	minWidth: '256px',
	maxWidth: '256px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CardListStyledContainer = styled.div({
	overflow: 'auto',
	flex: '1 1 100vh',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const HeaderStyledContainer = styled.div<{
	visualRefresh: boolean;
}>(
	{
		display: 'flex',
		flexGrow: 1,
		flexShrink: 0,
		gap: token('space.050', '4px'),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
	({ visualRefresh }) =>
		visualRefresh
			? {
					height: '40px',
					alignItems: 'center',
					paddingInline: token('space.025'),
					marginBottom: token('space.negative.025'),
				}
			: {
					paddingBottom: token('space.025', '2px'),
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
					height: `${gridSize * 4}px`,
				},
);

const headerStylesOld = xcss({
	flexGrow: 1,
	flexShrink: 0,
	height: '32px', // gridSize * 4
});

const headerStyles = xcss({
	flexGrow: 1,
	flexShrink: 0,
	height: '40px',
	marginBottom: 'space.negative.025', // Cards require small margin to show box shadow. This negative margin recenters the header.
	paddingInline: 'space.025',
});

const loadingIndicatorContainerStyles = xcss({
	flexGrow: '1',
	flexShrink: '1',
	flexBasis: '100vh',
	overflow: 'hidden',
	position: 'relative', // Required in order to contain loading indicator
});

const cardListStyles = xcss({
	flexGrow: '1',
	overflow: 'auto',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FooterStyledContainer = styled.div<{ visualRefresh: boolean }>(
	{
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
	({ visualRefresh }) =>
		visualRefresh
			? { paddingInline: token('space.025'), paddingTop: token('space.025') }
			: { padding: `${token('space.025', '2px')} 0 0 0` },
);

const footerStyles = xcss({
	color: 'color.text.subtlest',
	paddingBlock: 'space.050',
	textAlign: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FeedbackWrapper = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	backgroundColor: token('elevation.surface', colors.N0),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width: `calc(100% + ${gridSize * 2}px)`,
	paddingTop: token('space.200', '16px'),
	paddingBottom: token('space.025', '2px'),
	display: 'flex',
	justifyContent: 'center',
	marginTop: 0,
	marginBottom: token('space.negative.100', '-8px'),
	marginLeft: token('space.negative.100', '-8px'),
	marginRight: token('space.negative.100', '-8px'),
});
