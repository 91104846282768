import { ff } from '@atlassian/jira-feature-flagging';
import { fg } from '@atlassian/jira-feature-gating';

/**
 * Get feature flags and feature gates for the issue navigator performance metrics
 */
// eslint-disable-next-line jira/ff/inline-usage
export const getIssueNavigatorFFAndFG = () => ({
	isCohorted: ff('nin.global-scope_aqec8'),
	isNinExposeTextOption: ff('nin_expose_text_option_jql_builder_4kwzo'),
	isJscM1ApiUpdatesEnabled: fg('jira_spreadsheet_component_m1_api_updates'),
});
