/**
 * @generated SignedSource<<99f0d9d9838b61861cbb2efaa1de9f54>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type edit_issueNavigatorCustomFilters$data = {
  readonly editGrants: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly __typename: string;
        readonly user?: {
          readonly name: string;
        } | null | undefined;
        readonly " $fragmentSpreads": FragmentRefs<"groupGrant_issueNavigatorCustomFilters" | "projectGrant_issueNavigatorCustomFilters" | "projectRoleGrant_issueNavigatorCustomFilters">;
      } | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "edit_issueNavigatorCustomFilters";
};
export type edit_issueNavigatorCustomFilters$key = {
  readonly " $data"?: edit_issueNavigatorCustomFilters$data;
  readonly " $fragmentSpreads": FragmentRefs<"edit_issueNavigatorCustomFilters">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "edit_issueNavigatorCustomFilters",
  "selections": [
    {
      "concreteType": "JiraShareableEntityEditGrantConnection",
      "kind": "LinkedField",
      "name": "editGrants",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraShareableEntityEditGrantEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "__typename"
                },
                {
                  "kind": "FragmentSpread",
                  "name": "projectGrant_issueNavigatorCustomFilters"
                },
                {
                  "kind": "FragmentSpread",
                  "name": "projectRoleGrant_issueNavigatorCustomFilters"
                },
                {
                  "kind": "FragmentSpread",
                  "name": "groupGrant_issueNavigatorCustomFilters"
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "kind": "LinkedField",
                      "name": "user",
                      "plural": false,
                      "selections": [
                        {
                          "kind": "ScalarField",
                          "name": "name"
                        }
                      ]
                    }
                  ],
                  "type": "JiraShareableEntityUserGrant"
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraCustomFilter"
};

(node as any).hash = "cb8085a4ada55e8e573b553cfab4d229";

export default node;
