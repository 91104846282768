/**
 * @generated SignedSource<<5a7e12f260ccdeec03c3d32583112b81>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type main_issueNavigator_IssueNavigatorUI_groupResults$data = {
  readonly " $fragmentSpreads": FragmentRefs<"main_issueNavigator_IssueResults_groupResults">;
  readonly " $fragmentType": "main_issueNavigator_IssueNavigatorUI_groupResults";
};
export type main_issueNavigator_IssueNavigatorUI_groupResults$key = {
  readonly " $data"?: main_issueNavigator_IssueNavigatorUI_groupResults$data;
  readonly " $fragmentSpreads": FragmentRefs<"main_issueNavigator_IssueNavigatorUI_groupResults">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "main_issueNavigator_IssueNavigatorUI_groupResults",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "main_issueNavigator_IssueResults_groupResults"
    }
  ],
  "type": "JiraSpreadsheetGroupConnection"
};

(node as any).hash = "f2f55a211a624c01670e60a1355bce53";

export default node;
