import React, { Component, memo } from 'react';
import '@atlaskit/css-reset';
import isEqual from 'lodash/isEqual';
import pickBy from 'lodash/pickBy';
import { parse as fromQueryString, stringify as toQueryString } from 'query-string';
import { metrics } from '@atlassian/browser-metrics';
import { isFilterId } from '@atlassian/jira-issue-navigator-actions-common/src/utils/filters/index.tsx';
import { DEFAULT_VIEW_ID } from '@atlassian/jira-issue-navigator/src/common/constants.tsx';
import type { IssueNavigatorViewId } from '@atlassian/jira-issue-navigator/src/common/types.tsx';
import { convertToView } from '@atlassian/jira-issue-navigator/src/common/utils/index.tsx';
import RedirectIfProjectArchived from '@atlassian/jira-redirect-archived-project/src/ui/index.tsx';
import {
	type IssueKey,
	toIssueKey,
	toProjectKey,
} from '@atlassian/jira-shared-types/src/general.tsx';
import { ProjectSubProductUpdater } from '@atlassian/jira-spa-apps-common/src/analytics-sub-product/project-sub-product-updater/index.tsx';
import {
	getUrlPrefix,
	isFilterKey,
} from '@atlassian/jira-spa-apps-project-issue-navigator-utils/src/index.tsx';
import { MarkProductStart } from '@atlassian/jira-spa-performance-breakdown/src/utils/mark-product-start/index.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import {
	type Location,
	type Match,
	type RouterActionPush as Push,
	type RouterActionReplace as Replace,
	RouterActions,
	RouterSubscriber,
} from '@atlassian/react-resource-router';
import App from './ui/index.tsx';

type ProjectIssueNavigatorSPAWrapperProps = {
	push: Push;
	replace: Replace;
	location: Location;
	match: Match | null | undefined;
	showNav3Header?: boolean;
};

type ProjectIssueNavigatorSPAWrapperState = {
	initialised: boolean;
	issueKey: string;
};

type MutableSearchParams = {
	projectKey: string;
	issueKey: IssueKey;
	filter?: string;
	jql?: string;
};

type InteractionProperties = {
	filterId?: string;
	view?: string;
};

const BROWSER_METRICS_INTERACTION_APP_KEY = 'jira.project-issue-navigator';

const initInteractions = () => ({
	pagination: {
		metrics: metrics.interaction({
			key: `${BROWSER_METRICS_INTERACTION_APP_KEY}.pagination.start-end.diff`,
		}),
		properties: {},
	},
	refinement: {
		metrics: metrics.interaction({
			key: `${BROWSER_METRICS_INTERACTION_APP_KEY}.refinement.start-end.diff`,
		}),
		properties: {},
	},
	filter: {
		metrics: metrics.interaction({
			key: `${BROWSER_METRICS_INTERACTION_APP_KEY}.filter.start-end.diff`,
		}),
		properties: {},
	},
	'list-view': {
		metrics: metrics.interaction({
			key: `${BROWSER_METRICS_INTERACTION_APP_KEY}.list-view.start-end.diff`,
		}),
		properties: {},
	},
	'split-view': {
		metrics: metrics.interaction({
			key: `${BROWSER_METRICS_INTERACTION_APP_KEY}.split-view.start-end.diff`,
		}),
		properties: {},
	},
	'column-configuration': {
		metrics: metrics.interaction({
			key: `${BROWSER_METRICS_INTERACTION_APP_KEY}.column-configuration.start-end.diff`,
		}),
		properties: {},
	},
});
type Interactions = ReturnType<typeof initInteractions>;
type InteractionKey = keyof Interactions;

// eslint-disable-next-line jira/react/no-class-components
class ProjectIssueNavigatorSPAWrapper extends Component<
	ProjectIssueNavigatorSPAWrapperProps,
	ProjectIssueNavigatorSPAWrapperState
> {
	constructor(props: ProjectIssueNavigatorSPAWrapperProps) {
		super(props);

		const { location } = props;
		this.locationSearchStr = location.search;
		this.activeInteractions = [];
		this.view = DEFAULT_VIEW_ID;
		this.interactions = initInteractions();
	}

	state = {
		initialised: false,
		issueKey: this.props.match?.params.issueKey ?? '',
	};

	componentDidUpdate(prevProps: ProjectIssueNavigatorSPAWrapperProps) {
		const prevIssueKey = prevProps.match?.params.issueKey ?? '';
		const issueKey = this.props.match?.params.issueKey ?? '';
		// If issue key has changed from the router and does not match our internal key then update our state
		if (issueKey !== prevIssueKey && issueKey !== this.state.issueKey) {
			this.setState({
				issueKey,
			});
		}
	}

	activeInteractions: InteractionKey[];

	interactions: Interactions;

	view: IssueNavigatorViewId;

	lastFilter: string | undefined;

	/**
	 * Update the currently selected issue in the URL.
	 *
	 * @param issueKey Issue key to update in the URL
	 * @param isSelectedByUserInteraction True if this issue was selected by a user interaction, e.g. clicking an issue
	 * in the list. This will be false if an issue was automatically selected by the app, e.g. automatically selecting
	 * the first issue once the issue list has loaded.
	 */
	onChangeIssue = (issueKey: IssueKey, isSelectedByUserInteraction: boolean) => {
		// Replace the history entry when an issue is auto selected by the app so we're not pushing redundant entries to
		// the history stack
		this.push({ issueKey }, !isSelectedByUserInteraction);
	};

	onChangePage = () => {
		this.startInteraction('pagination', { view: this.view });
	};

	/**
	 * Set the currently selected view and update the selected issue in the URL if provided.
	 *
	 * @param newView View to update
	 * @param issueKey Issue key to update in the URL
	 */
	onSetView = (newView: IssueNavigatorViewId, issueKey?: IssueKey) => {
		this.startInteraction(convertToView(newView));
		this.view = newView;
		if (issueKey !== undefined) {
			this.push({ issueKey }, true);
		}
	};

	onRefinement = () => {
		this.startInteraction('refinement');
	};

	/**
	 * Callback function called when internal changes are made to PIN's state which generates new JQL.
	 *
	 * @param jql string The new JQL string
	 * @param clearFilter boolean When true we clear the filter from url params
	 */
	onChangeJql = (jql: string, clearFilter = false) => {
		this.push({
			...(clearFilter ? { filter: undefined } : {}),
			jql,
			// Clear issue key from URL whenever JQL changes
			issueKey: toIssueKey(''),
		});
	};

	onPageDataLoad = (selectedView: IssueNavigatorViewId) => {
		if (selectedView !== undefined) {
			this.view = selectedView;
		}
		if (!this.state.initialised) {
			this.setState({
				initialised: true,
			});
		}
		this.endInteractions();
	};

	onStartFilterChangeInteractionIfAny = (filter?: string) => {
		if (this.lastFilter !== filter) {
			this.lastFilter = filter;

			if (filter != null && (isFilterId(filter) || isFilterKey(filter))) {
				this.startInteraction('filter', {
					filterId: filter,
				});
			}
		}
	};

	onChangeFilter = (filter?: string) => {
		this.push({ ...(filter ? { filter, jql: undefined } : { jql: undefined }) }, true);
	};

	onChangeColumnConfiguration = () => {
		this.startInteraction('column-configuration');
	};

	push(newParams: Partial<MutableSearchParams>, shouldReplace = false) {
		const { match, push, location, replace } = this.props;
		const historyAction = shouldReplace ? replace : push;

		const oldIssueKey = this.state.issueKey;

		const {
			projectKey = match?.params.projectKey ?? '',
			issueKey = oldIssueKey ?? '',
			...params
		} = newParams;

		const newProjectKey = newParams.projectKey;
		const oldProjectKey = match?.params.projectKey;

		const projectKeyChanged =
			typeof newProjectKey !== 'undefined' && newProjectKey !== oldProjectKey;

		const newIssueKey = newParams.issueKey;

		const issueKeyParamsAreDefined = !!(newIssueKey || oldIssueKey);

		const issueKeyChanged = issueKeyParamsAreDefined && newIssueKey !== oldIssueKey;

		// @ts-expect-error - The "comma" option requires query-string v6.4.0 or newer. JFE currently uses v5.1.1.
		const query = fromQueryString(location.search, { arrayFormat: 'comma' });

		const queryParamsChanged =
			Object.keys(params).length !== 0 &&
			!isEqual(
				pickBy(params, (param) => param != null),
				query,
			);

		if (projectKeyChanged || issueKeyChanged || queryParamsChanged) {
			// @ts-expect-error - React-resource-router's external type interface claims push/replace only accept "string", however the underlying code works with the `Location` object we're using
			historyAction({
				pathname: `${
					(match && getUrlPrefix(match.url)) ?? ''
				}/projects/${projectKey}/issues/${issueKey ?? ''}`,
				// @ts-expect-error - The "comma" option requires query-string v6.4.0 or newer. JFE currently uses v5.1.1.
				search: toQueryString({ ...query, ...params }, { arrayFormat: 'comma' }),
			});
		}

		if (issueKeyChanged) {
			// We explicitly set this into state instead of being purely prop driven as browser history actions are not
			// re-rendered synchronously. Setting into state makes our render order more reliable.
			this.setState({
				issueKey: issueKey ?? '',
			});
		}
		return true;
	}

	startInteraction = (key: InteractionKey, properties?: InteractionProperties) => {
		if (!this.activeInteractions.includes(key)) {
			this.activeInteractions.push(key);
		}
		const interaction = this.interactions[key];

		if (interaction) {
			interaction.properties = properties || {};
			interaction.metrics.start();
		}
	};

	endInteractions = () => {
		this.activeInteractions.forEach((key) => {
			this.interactions[key]?.metrics.stop({
				customData: this.interactions[key]?.properties,
			});
		});
		this.activeInteractions = [];
	};

	locationSearchStr: string;

	render() {
		const { match, location } = this.props;
		const { projectKey } = match?.params ?? {};
		// @ts-expect-error - The "comma" option requires query-string v6.4.0 or newer. JFE currently uses v5.1.1.
		const params = fromQueryString(location.search, { arrayFormat: 'comma' });
		const { filter, jql } = params;

		this.onStartFilterChangeInteractionIfAny(filter);

		return (
			<>
				<MarkProductStart />

				<ProjectSubProductUpdater />
				<RedirectIfProjectArchived projectKey={projectKey ?? null} />
				<App
					showNav3Header={this.props.showNav3Header}
					projectKey={toProjectKey(projectKey ?? '')}
					issueKey={toIssueKey(this.state.issueKey)}
					jql={jql}
					onChangeIssue={this.onChangeIssue}
					onChangePage={this.onChangePage}
					onPageDataLoad={this.onPageDataLoad}
					onChangeColumnConfiguration={this.onChangeColumnConfiguration}
					onChangeJql={this.onChangeJql}
					initialised={this.state.initialised}
					onRefinement={this.onRefinement}
					onSetView={this.onSetView}
					view={convertToView(this.view)}
					filter={filter}
					onChangeFilter={this.onChangeFilter}
				/>
			</>
		);
	}
}

const ProjectIssueNavigatorSPAWrapperRouterConsumer = () => (
	<UFOSegment name="project-issue-navigator">
		<RouterActions>
			{({ push, replace }) => (
				<RouterSubscriber>
					{({ location, match }) => (
						<ProjectIssueNavigatorSPAWrapper
							push={push}
							location={location}
							match={match}
							replace={replace}
						/>
					)}
				</RouterSubscriber>
			)}
		</RouterActions>
	</UFOSegment>
);

export const ProjectIssueNavigatorSPA = memo<ProjectIssueNavigatorSPAWrapperProps>(
	ProjectIssueNavigatorSPAWrapperRouterConsumer,
);

const ServiceDeskProjectIssueNavigatorSPAWrapperRouterConsumer = () => (
	<UFOSegment name="project-issue-navigator">
		<RouterActions>
			{({ push, replace }) => (
				<RouterSubscriber>
					{({ location, match }) => (
						<ProjectIssueNavigatorSPAWrapper
							push={push}
							location={location}
							match={match}
							replace={replace}
							showNav3Header
						/>
					)}
				</RouterSubscriber>
			)}
		</RouterActions>
	</UFOSegment>
);

export const ServiceDeskProjectIssueNavigatorSPA = memo<ProjectIssueNavigatorSPAWrapperProps>(
	ServiceDeskProjectIssueNavigatorSPAWrapperRouterConsumer,
);
