import React, { useCallback, useEffect, useMemo } from 'react';
import { styled } from '@compiled/react';
import { useQueryLoader } from 'react-relay';
import type { ContentProps } from '@atlaskit/popup';
import { token } from '@atlaskit/tokens';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/index.tsx';
import { createAri } from '@atlassian/jira-platform-ari/src/index.tsx';
import { ContextualAnalyticsData, INLINE_DIALOG } from '@atlassian/jira-product-analytics-bridge';
import FILTER_QUERY, {
	type mainPopupContentFilterByAriQuery,
} from '@atlassian/jira-relay/src/__generated__/mainPopupContentFilterByAriQuery.graphql';
import { useActivationId } from '@atlassian/jira-tenant-context-controller/src/components/activation-id/index.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { useIsAnonymous } from '@atlassian/jira-tenant-context-controller/src/components/is-anonymous/index.tsx';
import { ANALYTICS_PACKAGE_NAME } from '../../../common/constants.tsx';
import { viewDetailsPopupExperience } from '../../../experiences.tsx';
import { ErrorState } from './error-state/index.tsx';
import { PopupContent as BasePopupContent } from './main.tsx';

export type Props = ContentProps & {
	filterId: string;
	onOpenEditFilterModal: () => void;
	onOpenNewSubscriptionModal: () => void;
};

export const PopupContent = ({ filterId, ...props }: Props) => {
	const isAnonymous = useIsAnonymous();

	const activationId = useActivationId();
	const cloudId = useCloudId();
	const filterAri = useMemo(
		() =>
			createAri({
				resourceOwner: 'jira',
				cloudId,
				resourceType: 'filter',
				resourceId: filterId,
				activationId,
			}),
		[filterId, activationId, cloudId],
	);

	const [queryReference, loadQuery, disposeQuery] =
		useQueryLoader<mainPopupContentFilterByAriQuery>(FILTER_QUERY);

	useEffect(() => {
		viewDetailsPopupExperience.start();
		loadQuery({ filterAri, isAnonymous });
		return disposeQuery;
	}, [loadQuery, filterAri, isAnonymous, disposeQuery]);

	const onRetry = useCallback(() => {
		loadQuery({ filterAri, isAnonymous }, { fetchPolicy: 'network-only' });
	}, [loadQuery, filterAri, isAnonymous]);

	const ErrorFallback = useCallback(
		(errorStateProps: { error: Error }) => {
			viewDetailsPopupExperience.failure();
			return <ErrorState {...errorStateProps} onRetry={onRetry} />;
		},
		[onRetry],
	);

	return (
		<ContextualAnalyticsData sourceName="filterDetails" sourceType={INLINE_DIALOG}>
			<ContentWrapper>
				<JSErrorBoundary
					id="DetailsPopupContentWrapper"
					packageName={ANALYTICS_PACKAGE_NAME}
					teamName="empanada"
					fallback={ErrorFallback}
					key={queryReference?.fetchKey}
				>
					{queryReference ? (
						<BasePopupContent {...props} queryReference={queryReference} onSuccess={onRetry} />
					) : null}
				</JSErrorBoundary>
			</ContentWrapper>
		</ContextualAnalyticsData>
	);
};
export default PopupContent;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ContentWrapper = styled.div({
	width: '440px',
	padding: token('space.250', '20px'),
	overflow: 'auto',
	maxHeight: 'calc(100vh - 215px)',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const LoadingWrapper = styled(ContentWrapper)({
	textAlign: 'center',
});
