/**
 * @generated SignedSource<<47dfb295bccdcf1a251d054c5e318803>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type main_issueNavigator_CardList_fragment$data = {
  readonly edges: ReadonlyArray<{
    readonly node: {
      readonly key: string;
      readonly " $fragmentSpreads": FragmentRefs<"main_issueNavigator_CardWithSelectedIssue_fragment" | "main_issueNavigator_Card_fragment">;
    } | null | undefined;
  } | null | undefined> | null | undefined;
  readonly " $fragmentType": "main_issueNavigator_CardList_fragment";
};
export type main_issueNavigator_CardList_fragment$key = {
  readonly " $data"?: main_issueNavigator_CardList_fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"main_issueNavigator_CardList_fragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "main_issueNavigator_CardList_fragment",
  "selections": [
    {
      "concreteType": "JiraIssueEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "concreteType": "JiraIssue",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "name": "key"
            },
            {
              "kind": "FragmentSpread",
              "name": "main_issueNavigator_Card_fragment"
            },
            {
              "kind": "FragmentSpread",
              "name": "main_issueNavigator_CardWithSelectedIssue_fragment"
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraIssueConnection"
};

(node as any).hash = "d3cb3598f2ff377ca459674b752821e1";

export default node;
