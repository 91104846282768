import React, { useCallback, useMemo, type PropsWithChildren } from 'react';
import noop from 'lodash/noop';
import { graphql, useFragment } from 'react-relay';
import { Box, Flex, xcss } from '@atlaskit/primitives';
import { useIsBulkOperationsInProgress } from '@atlassian/jira-bulk-operations-common/src/controllers/use-bulk-operations/index.tsx';
import { AsyncBulkOpsCoreFull } from '@atlassian/jira-bulk-operations-full-core/src/async.tsx';
import type { BulkOperationsCoreFullProps } from '@atlassian/jira-bulk-operations-full-core/src/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import {
	useIssueTableSelectedIssues,
	useIssueTableSelectedIssuesActions,
} from '@atlassian/jira-native-issue-table/src/controllers/selected-issues/index.tsx';
import type { bulkOperationsWrapper_issueNavigator_LoadBulkOpsCore$key } from '@atlassian/jira-relay/src/__generated__/bulkOperationsWrapper_issueNavigator_LoadBulkOpsCore.graphql';

const LoadBulkOpsCore = ({
	bulkOpsCoreEntryPointReference,
	fragmentRef,
}: Pick<BulkOperationsCoreFullProps, 'bulkOpsCoreEntryPointReference'> & {
	fragmentRef: bulkOperationsWrapper_issueNavigator_LoadBulkOpsCore$key;
}) => {
	const selectedIssues = useIssueTableSelectedIssues();
	const { reset, insertSelectedIssueIds } = useIssueTableSelectedIssuesActions();

	const data = useFragment<bulkOperationsWrapper_issueNavigator_LoadBulkOpsCore$key>(
		graphql`
			fragment bulkOperationsWrapper_issueNavigator_LoadBulkOpsCore on JiraIssueConnection {
				edges {
					node {
						issueId
						fieldSetsForIssueSearchView(
							namespace: $namespace
							viewId: $viewId
							first: $amountOfColumns
							filterId: $filterId
							context: { projectContext: { project: $projectId, issueType: $issueTypeId } }
						) @required(action: THROW) {
							edges {
								node {
									fields {
										edges {
											node {
												... on JiraIssueField {
													fieldId
													__typename
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		`,
		fragmentRef,
	);

	const gqlFields = useMemo(
		() =>
			data.edges
				?.find(Boolean)
				?.node?.fieldSetsForIssueSearchView?.edges?.map((outerEdge) =>
					outerEdge?.node?.fields?.edges?.map((innerEdge) => innerEdge?.node),
				)
				.flat()
				.filter(Boolean)
				.map((field) => ({
					fieldId: field.fieldId,
					gqlType: field.__typename,
				})) ?? [],
		[data.edges],
	);

	const issueIds = useMemo(() => {
		const strings = data.edges?.map((edge) => edge?.node?.issueId).filter(Boolean) ?? [];
		return {
			strings,
			numbers: strings.map((issueId) => Number(issueId)),
		};
	}, [data.edges]);

	const onSelectAll = useCallback(() => {
		insertSelectedIssueIds(issueIds.strings);
	}, [issueIds.strings, insertSelectedIssueIds]);

	return (
		<AsyncBulkOpsCoreFull
			gqlFields={gqlFields}
			bulkOpsCoreEntryPointReference={bulkOpsCoreEntryPointReference}
			selectedIssueIds={selectedIssues.map((issue) => Number(issue))}
			onClearAll={reset}
			onSelectAll={onSelectAll}
			selectableIssueIds={issueIds.numbers}
			onClearAllVisible={noop} // What do we do with this one?
		/>
	);
};

export const BulkOperationsWrapper = ({
	children,
	bulkOpsCoreEntryPointReference,
	fragmentRef,
}: PropsWithChildren &
	Pick<BulkOperationsCoreFullProps, 'bulkOpsCoreEntryPointReference'> & {
		fragmentRef: bulkOperationsWrapper_issueNavigator_LoadBulkOpsCore$key | null;
	}) => {
	if (!fg('bulk_operations_in_nin')) {
		return children;
	}

	// eslint-disable-next-line react-hooks/rules-of-hooks
	const isBulkOpsInProgress = useIsBulkOperationsInProgress();

	return (
		<Flex xcss={wrapperStyles} direction="row">
			<Box xcss={[childContainerStyles, isBulkOpsInProgress && childContainerDisabledStyles]}>
				{children}
			</Box>
			{fragmentRef && (
				<LoadBulkOpsCore
					fragmentRef={fragmentRef}
					bulkOpsCoreEntryPointReference={bulkOpsCoreEntryPointReference}
				/>
			)}
		</Flex>
	);
};

const wrapperStyles = xcss({
	height: '100%',
	overflowY: 'auto',
});

const childContainerStyles = xcss({
	flexGrow: 1,
	flexShrink: 1,
	flexBasis: 0,
	overflowY: 'auto',
});

const childContainerDisabledStyles = xcss({
	opacity: 0.5,
	pointerEvents: 'none',
});
