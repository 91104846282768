import React from 'react';
import { styled } from '@compiled/react';
import { expVal } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import LockClosedImage from '@atlassian/jira-illustrations/src/ui/adg4/brand/spots/objects/components/lock-closed/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import permissionErrorImg from '../../common/assets/permission-error.svg';
import { ErrorDisplay } from '../../common/ui/error-display/index.tsx';
import { messages } from './messages.tsx';

const ErrorImage = () =>
	fg('move_issue_view_assets_into_central_location') ? (
		<LockClosedImage width={90} height={130} alt="" />
	) : (
		<Image src={permissionErrorImg} alt="" />
	);

export const PermissionErrorView = () => {
	const intl = useIntl();

	return (
		<ErrorDisplay
			header={intl.formatMessage(
				expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
					? messages.permissionErrorHeaderIssueTermRefresh
					: messages.permissionErrorHeader,
			)}
			description={intl.formatMessage(
				expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
					? messages.permissionErrorDescriptionIssueTermRefresh
					: messages.permissionErrorDescription,
			)}
			image={<ErrorImage />}
		/>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Image = styled.img({
	height: '100px',
	width: '145px',
});
