import React from 'react';
import { styled } from '@compiled/react';
import ErrorImage from '@atlaskit/icon/glyph/error';
import { Box, xcss } from '@atlaskit/primitives';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { CardBaseStyle } from '../../../../../common/ui/styled.tsx';
import ErrorInfo from './error-info/index.tsx';
import messages from './messages.tsx';
import {
	SkeletonContainer,
	SkeletonBackground,
	ColumnMask,
	SummaryMask,
	FooterMask,
	TextLineMask,
	TypeMask,
	IssueKeyMask,
	SeparatorMask,
	BlankMask,
	ErrorIconContainer,
	ErrorWrapper,
	errorWrapperSelector,
	skeletonBackgroundSelector,
	columnMaskSelector,
} from './styled.tsx';

type Props = {
	issueIndex: number;
	hasError?: boolean;
};

const IssueCardSkeleton = ({ hasError = false, issueIndex }: Props) => {
	const { formatMessage } = useIntl();

	return (
		<Box
			as="li"
			xcss={
				isVisualRefreshEnabled() && fg('jira_nav4_beta_drop_1') ? ListItemStyle : ListItemStyleOld
			}
			aria-label={formatMessage(messages.issueLoadingLabel, { number: issueIndex })}
		>
			<CardContainer visualRefresh={isVisualRefreshEnabled() && fg('jira_nav4_beta_drop_1')}>
				<SkeletonContainer>
					<SkeletonBackground
						hasError={hasError}
						data-component-selector={skeletonBackgroundSelector}
					/>
					<ColumnMask data-component-selector={columnMaskSelector}>
						<SummaryMask>
							<TextLineMask fontSize={20} lineHeight={24} intrusion={20} />
							<TextLineMask fontSize={20} lineHeight={24} intrusion={60} />
						</SummaryMask>
						<FooterMask>
							<TypeMask />
							<SeparatorMask />
							<IssueKeyMask />
							{hasError ? (
								<ErrorIconContainer>
									<ErrorImage primaryColor={token('color.icon.danger', colors.R400)} label="" />
								</ErrorIconContainer>
							) : (
								<BlankMask />
							)}
						</FooterMask>
					</ColumnMask>
					<ErrorWrapper data-component-selector={errorWrapperSelector}>
						<ErrorInfo />
					</ErrorWrapper>
				</SkeletonContainer>
			</CardContainer>
		</Box>
	);
};

export default IssueCardSkeleton;

const ListItemStyleOld = xcss({
	marginBlockStart: 'space.0',
});

const ListItemStyle = xcss({
	marginBlockStart: 'space.0',
	marginBlockEnd: 'space.050',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const CardContainer = styled(CardBaseStyle)({});
