import React, { useEffect, useMemo } from 'react';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container/src/index.tsx';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { asyncBulkOpsCoreFullEntryPoint } from '../entrypoint.tsx';
import type { EntryPointContentProps } from './index.tsx';

export const AsyncBulkOpsCoreFull = (props: Pick<EntryPointContentProps, 'props'>['props']) => {
	const cloudId = useCloudId();
	const entryPointParams = useMemo(() => ({ cloudId }), [cloudId]);

	const { entryPointActions, entryPointReferenceSubject } = useEntryPoint(
		asyncBulkOpsCoreFullEntryPoint,
		entryPointParams,
	);

	// this is to reduce the load to NIN when adding bulk ops
	// we should revisit later to remove this and load bulk ops same as the way backlog loads
	// https://hello.jira.atlassian.cloud/browse/DEE-6353
	// eslint-disable-next-line @atlassian/react-entrypoint/no-load-in-hooks
	useEffect(() => {
		entryPointActions.load();
	}, [entryPointActions]);

	return (
		<JiraEntryPointContainer
			id="jira.backlog.bulk.operations.core.full"
			packageName="jiraBulkOperationsCoreFull"
			entryPointReferenceSubject={entryPointReferenceSubject}
			fallback={<></>}
			runtimeProps={props}
		/>
	);
};
