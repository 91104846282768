import { styled } from '@compiled/react';
import Button from '@atlaskit/button';
import { N0, P50, P100, P200, P400 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

type StyledButtonProps = {
	isHighlight: boolean;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const StyledButton = styled(Button)<StyledButtonProps>(
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ isHighlight }) =>
		isHighlight && {
			'&&': {
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
				color: `${token('color.text.accent.purple', P400)} !important`,
				backgroundColor: `${token('color.background.accent.purple.subtlest', P50)}`,
			},
			'&&:hover': {
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
				color: `${token('color.text.accent.purple', P400)} !important`,
				backgroundColor: `${token('color.background.accent.purple.subtler', P100)}`,
			},
			'&&:active': {
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
				color: `${token('color.text.inverse', N0)} !important`,
				background: `${token('color.background.accent.purple.subtle', P200)}`,
			},
		},
);
