import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	unknownProject: {
		id: 'issue-navigator-custom-filters.common.details-popup.unknown-project.unknown-project',
		defaultMessage: 'Project: unknown',
		description: 'Text with project permission.',
	},
	infoTooltip: {
		id: 'issue-navigator-custom-filters.common.details-popup.unknown-project.info-tooltip',
		defaultMessage: 'You do not have access to this project',
		description: 'Text of info tooltip for unknown projects.',
	},
});
