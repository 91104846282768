/**
 * @generated SignedSource<<c93155984576afec4ca63b038a3900cd>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type owner_issueNavigatorCustomFilters$data = {
  readonly owner: {
    readonly accountId: string;
    readonly name: string;
    readonly picture: AGG$URL;
  };
  readonly " $fragmentType": "owner_issueNavigatorCustomFilters";
};
export type owner_issueNavigatorCustomFilters$key = {
  readonly " $data"?: owner_issueNavigatorCustomFilters$data;
  readonly " $fragmentSpreads": FragmentRefs<"owner_issueNavigatorCustomFilters">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "owner_issueNavigatorCustomFilters",
  "selections": [
    {
      "kind": "RequiredField",
      "field": {
        "kind": "LinkedField",
        "name": "owner",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "name": "accountId"
          },
          {
            "kind": "ScalarField",
            "name": "name"
          },
          {
            "kind": "ScalarField",
            "name": "picture"
          }
        ]
      },
      "action": "THROW",
      "path": "owner"
    }
  ],
  "type": "JiraCustomFilter"
};

(node as any).hash = "cb1b46f3f320b4c4793ee7c164c198c7";

export default node;
