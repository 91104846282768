/**
 * @generated SignedSource<<24b4c6aa52274f2214dc30c53ea12406>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type main_issueNavigator_ListView_groupResults$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ui_nativeIssueTable_NativeIssueTable_groups">;
  readonly " $fragmentType": "main_issueNavigator_ListView_groupResults";
};
export type main_issueNavigator_ListView_groupResults$key = {
  readonly " $data"?: main_issueNavigator_ListView_groupResults$data;
  readonly " $fragmentSpreads": FragmentRefs<"main_issueNavigator_ListView_groupResults">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "main_issueNavigator_ListView_groupResults",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "ui_nativeIssueTable_NativeIssueTable_groups"
    }
  ],
  "type": "JiraSpreadsheetGroupConnection"
};

(node as any).hash = "10f0d02785e17748bb2be9a69915e2e4";

export default node;
