import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const HeaderContentWrapper = styled.span({
	display: 'inline-flex',
	alignItems: 'flex-start',
	letterSpacing: 'inherit',
	height: '32px',
	font: token('font.heading.large'),
	maxWidth: '100%',
});
