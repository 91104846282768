import React, { type ComponentType, type ReactElement, type FC, useState } from 'react';
import DropdownMenu, { type CustomTriggerProps } from '@atlaskit/dropdown-menu';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/index.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { useIntl } from '@atlassian/jira-intl';
import { ErrorState } from '@atlassian/jira-issue-navigator-actions-common/src/ui/error/index.tsx';
import { FilterButton } from '@atlassian/jira-issue-navigator-actions-common/src/ui/filter-button/index.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazyAfterPaint } from '@atlassian/react-loosely-lazy';
import messages from './messages.tsx';
import type { ExportIssuesProps } from './types.tsx';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const ExportIssues: ComponentType<ExportIssuesProps> = lazyAfterPaint(
	() => import(/* webpackChunkName: "async-export-issues" */ './index'),
	{
		ssr: false,
	},
);

const Fallback = ({
	onRetry,
	customTriggerButton,
}: {
	onRetry: () => void;
	customTriggerButton?: (
		isOpen: boolean,
		triggerButtonProps: CustomTriggerProps<HTMLElement>,
	) => ReactElement;
}) => {
	const { formatMessage } = useIntl();

	const triggerButton = ({ triggerRef, ...props }: CustomTriggerProps<HTMLElement>) =>
		customTriggerButton ? (
			customTriggerButton(false, { triggerRef, ...props })
		) : (
			<FilterButton
				{...props}
				text={formatMessage(
					expVal('issue-terminology-refresh-m1-fade', 'isIssueTermFaded', false)
						? messages.exportButtonText
						: messages.exportIssuesButtonText,
				)}
				label={formatMessage(messages.exportIssuesButtonIconLabel)}
				ref={triggerRef}
			/>
		);

	return (
		<DropdownMenu
			trigger={({ triggerRef, ...props }) => triggerButton({ triggerRef, ...props })}
			shouldFlip
			placement="bottom-end"
			spacing="compact"
		>
			<ErrorState onRetry={onRetry} pageAction="exportIssues" />
		</DropdownMenu>
	);
};

const AsyncExportIssues: FC<ExportIssuesProps> = (props: ExportIssuesProps) => {
	const { customTriggerButton, customDisabledButton } = props;
	const [resetCaughtError, setResetCaughtError] = useState<Error>();
	const { formatMessage } = useIntl();

	return (
		<JSErrorBoundary
			packageName="jiraIssueNavigatorActionExportIssues"
			id="async-export-issues"
			sendToPrivacyUnsafeSplunk
			// Attempt to re-render children when retry is pressed in the fallback error state
			fallback={({ error }) => (
				<Fallback
					onRetry={() => setResetCaughtError(error)}
					customTriggerButton={customTriggerButton}
				/>
			)}
			onError={() => setResetCaughtError(undefined)}
			resetCaughtError={resetCaughtError}
		>
			<Placeholder
				name="async-export-issues"
				fallback={
					customDisabledButton ?? (
						<FilterButton
							isDisabled
							text={formatMessage(
								expVal('issue-terminology-refresh-m1-fade', 'isIssueTermFaded', false)
									? messages.exportButtonText
									: messages.exportIssuesButtonText,
							)}
							label={formatMessage(messages.exportIssuesButtonIconLabel)}
						/>
					)
				}
			>
				<ExportIssues {...props} />
			</Placeholder>
		</JSErrorBoundary>
	);
};
export default AsyncExportIssues;
