import React, { type ReactNode } from 'react';
import type { IssueNavigatorViewId } from '../../../../common/types.tsx';
import { useSelectedViewState } from '../../../../controllers/selected-view-state/index.tsx';

export type ColumnPickerContainerProps = {
	children: ReactNode;
	viewIdFromResponse: IssueNavigatorViewId;
	isRestoringDefaults: boolean;
};
/**
 * The ColumnPickerContainer serves as a wrapper for the column picker within the platform issue table.
 * Issue: The platform's column picker maintains an internal state which doesn't reset when the viewId changes, causing it to display outdated data.
 * Solution: To address this, we use this container to wrap the platform's column picker. It's passed into the platform table column picker customisation props.
 *           When the viewId changes, we rerender the container utilizing the key prop. This action forces a re-render of the platform column picker too, ensuring data displayed are up-to-date.
 */
export const ColumnPickerContainer = ({
	children,
	viewIdFromResponse,
	isRestoringDefaults,
}: ColumnPickerContainerProps) => {
	const [{ view: optimisticViewId }] = useSelectedViewState();

	return (
		<div key={isRestoringDefaults ? 'loading' : optimisticViewId + viewIdFromResponse}>
			{children}
		</div>
	);
};
