import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	showModalErrorTitle: {
		id: 'issue-navigator-custom-filters.details-popup.popup-content.subscriptions.add-subscription-modal.show-modal-error-title',
		defaultMessage: 'Something went wrong',
		description: "Title for the error flag that appears if the Add Subscription modal doesn't load",
	},
	showModalErrorDescription: {
		id: 'issue-navigator-custom-filters.details-popup.popup-content.subscriptions.add-subscription-modal.show-modal-error-description',
		defaultMessage: "We couldn't create a new subscription. Please refresh the page and try again.",
		description:
			"Description text of the error flag that appears if the Add Subscription modal doesn't load",
	},
	successFlagTitle: {
		id: 'issue-navigator-custom-filters.details-popup.popup-content.subscriptions.add-subscription-modal.success-flag-title',
		defaultMessage: 'Subscription created',
		description:
			'Title for the success flag that appears after a Filter Subscription is successfully created',
	},
	successFlagDescription: {
		id: 'issue-navigator-custom-filters.details-popup.popup-content.subscriptions.add-subscription-modal.success-flag-description',
		defaultMessage: 'You’ve created a subscription for “{filterName}” filter.',
		description:
			'Title for the success flag that appears after a Filter Subscription is successfully created',
	},
	successFlagActionManage: {
		id: 'issue-navigator-custom-filters.details-popup.popup-content.subscriptions.add-subscription-modal.success-flag-action-manage',
		defaultMessage: 'Manage subscriptions',
		description:
			'Link to the "view subscriptions" page shown after a new subscription is created successfully',
	},
});
