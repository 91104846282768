// Performance marks
const JQL_BUILDER_START = 'JQL_BUILDER_START';
const JQL_BUILDER_END = 'JQL_BUILDER_END';
const JQL_BUILDER_HYDRATE_START = 'JQL_BUILDER_HYDRATE_START';
const JQL_BUILDER_HYDRATE_END = 'JQL_BUILDER_HYDRATE_END';
const JQL_BUILDER_BASIC_START = 'JQL_BUILDER_BASIC_START';
const JQL_BUILDER_BASIC_END = 'JQL_BUILDER_BASIC_END';
const JQL_BUILDER_BASIC_JS_START = 'JQL_BUILDER_BASIC_JS_START';
const JQL_BUILDER_BASIC_JS_END = 'JQL_BUILDER_BASIC_JS_END';
const JQL_BUILDER_ADVANCED_START = 'JQL_BUILDER_ADVANCED_START';
const JQL_BUILDER_ADVANCED_END = 'JQL_BUILDER_ADVANCED_END';
const JQL_BUILDER_ADVANCED_JS_START = 'JQL_BUILDER_ADVANCED_JS_START';
const JQL_BUILDER_ADVANCED_JS_END = 'JQL_BUILDER_ADVANCED_JS_END';

export type PerformanceMarks =
	| typeof JQL_BUILDER_START
	| typeof JQL_BUILDER_END
	| typeof JQL_BUILDER_HYDRATE_START
	| typeof JQL_BUILDER_HYDRATE_END
	| typeof JQL_BUILDER_BASIC_START
	| typeof JQL_BUILDER_BASIC_END
	| typeof JQL_BUILDER_BASIC_JS_START
	| typeof JQL_BUILDER_BASIC_JS_END
	| typeof JQL_BUILDER_ADVANCED_START
	| typeof JQL_BUILDER_ADVANCED_END
	| typeof JQL_BUILDER_ADVANCED_JS_START
	| typeof JQL_BUILDER_ADVANCED_JS_END;

export const JQL_BUILDER_PERFORMANCE_MARKS: {
	[key in PerformanceMarks]: PerformanceMarks;
} = {
	/**
	 * JQL_BUILDER
	 * Count how long it takes to load the JQL Builder,
	 * from first render of async component to ready to use
	 */
	JQL_BUILDER_START,
	JQL_BUILDER_END,

	/**
	 * JQL_BUILDER_HYDRATE
	 * Count how long it takes from first try to use hydrated data
	 * to the momento is actually ready to use
	 */
	JQL_BUILDER_HYDRATE_START,
	JQL_BUILDER_HYDRATE_END,

	/**
	 * JQL_BUILDER_BASIC_JS
	 * Count how long it takes from FE perspective to load the basic JQL Builder Basic js package
	 */
	JQL_BUILDER_BASIC_JS_START,
	JQL_BUILDER_BASIC_JS_END,

	/**
	 * JQL_BUILDER_BASIC
	 * Count how long it takes to load the JQL Builder Basic,
	 * from first render of async basic component to ready to use
	 */
	JQL_BUILDER_BASIC_START,
	JQL_BUILDER_BASIC_END,

	/**
	 * JQL_BUILDER_ADVANCED_JS
	 * Count how long it takes from FE perspective to load the basic JQL Builder Advanced js package
	 */
	JQL_BUILDER_ADVANCED_JS_START,
	JQL_BUILDER_ADVANCED_JS_END,

	/**
	 * JQL_BUILDER_BASIC
	 * Count how long it takes to load the JQL Builder Advnaced,
	 * from first render of async advanced component to ready to use
	 */
	JQL_BUILDER_ADVANCED_START,
	JQL_BUILDER_ADVANCED_END,
};
