import React from 'react';
import { graphql, useFragment } from 'react-relay';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Link from '@atlaskit/link';
import { FormattedI18nMessage } from '@atlassian/jira-formatted-i18n-message/src/ui/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type { projectGrant_issueNavigatorCustomFilters$key } from '@atlassian/jira-relay/src/__generated__/projectGrant_issueNavigatorCustomFilters.graphql';
import { SectionBody } from '../styled.tsx';
import messages from './messages.tsx';

type Props = {
	entity: projectGrant_issueNavigatorCustomFilters$key;
};

const linkStart = '{linkStart}';
const linkEnd = '{linkEnd}';

export const ProjectGrant = ({ entity }: Props) => {
	const { formatMessage } = useIntl();

	const projectGrant = useFragment(
		graphql`
			fragment projectGrant_issueNavigatorCustomFilters on JiraShareableEntityProjectGrant {
				project {
					name
					key
				}
			}
		`,
		entity,
	);

	return (
		<SectionBody>
			{projectGrant.project?.name && (
				<FormattedI18nMessage
					componentsMapping={{
						link: ({ children }) =>
							projectGrant.project?.key ? (
								<Link
									href={`/browse/${projectGrant.project?.key}`}
									onClick={(_: unknown, analyticsEvent: UIAnalyticsEvent) =>
										fireUIAnalytics(analyticsEvent, 'projectGrant')
									}
								>
									{children}
								</Link>
							) : (
								<>{children}</>
							),
					}}
					message={formatMessage(messages.projectNameLink, {
						name: projectGrant.project?.name,
						linkStart,
						linkEnd,
					})}
				/>
			)}
		</SectionBody>
	);
};
