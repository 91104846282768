/**
 * @generated SignedSource<<81c294351dba4a1299d24ceb84cb8c13>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type main_issueNavigator_DetailView_view$data = {
  readonly " $fragmentSpreads": FragmentRefs<"main_issueNavigator_IssueAppWithData_view">;
  readonly " $fragmentType": "main_issueNavigator_DetailView_view";
};
export type main_issueNavigator_DetailView_view$key = {
  readonly " $data"?: main_issueNavigator_DetailView_view$data;
  readonly " $fragmentSpreads": FragmentRefs<"main_issueNavigator_DetailView_view">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "main_issueNavigator_DetailView_view",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "main_issueNavigator_IssueAppWithData_view"
    }
  ],
  "type": "JiraIssueSearchView"
};

(node as any).hash = "1b2ee54f76732e67326f37ebaa7aeb47";

export default node;
