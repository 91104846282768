/**
 * @generated SignedSource<<6cdab0b62ebc5a697477607cd35b545c>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type addSubscriptionModal_issueNavigatorCustomFilters_AddSubscriptionModalProvider$data = {
  readonly description: string | null | undefined;
  readonly filterId: string;
  readonly name: string;
  readonly owner: {
    readonly accountId: string;
  } | null | undefined;
  readonly " $fragmentType": "addSubscriptionModal_issueNavigatorCustomFilters_AddSubscriptionModalProvider";
};
export type addSubscriptionModal_issueNavigatorCustomFilters_AddSubscriptionModalProvider$key = {
  readonly " $data"?: addSubscriptionModal_issueNavigatorCustomFilters_AddSubscriptionModalProvider$data;
  readonly " $fragmentSpreads": FragmentRefs<"addSubscriptionModal_issueNavigatorCustomFilters_AddSubscriptionModalProvider">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "addSubscriptionModal_issueNavigatorCustomFilters_AddSubscriptionModalProvider",
  "selections": [
    {
      "kind": "RequiredField",
      "field": {
        "kind": "ScalarField",
        "name": "filterId"
      },
      "action": "THROW",
      "path": "filterId"
    },
    {
      "kind": "RequiredField",
      "field": {
        "kind": "ScalarField",
        "name": "name"
      },
      "action": "THROW",
      "path": "name"
    },
    {
      "kind": "ScalarField",
      "name": "description"
    },
    {
      "kind": "LinkedField",
      "name": "owner",
      "plural": false,
      "selections": [
        {
          "kind": "RequiredField",
          "field": {
            "kind": "ScalarField",
            "name": "accountId"
          },
          "action": "THROW",
          "path": "owner.accountId"
        }
      ]
    }
  ],
  "type": "JiraCustomFilter"
};

(node as any).hash = "9d8d65563104a3f742dd7a5adc73ec81";

export default node;
