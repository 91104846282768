/**
 * @generated SignedSource<<0a1736a97120f52a454a8b09e8d9dd6e>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type permissions_issueNavigatorCustomFilters$data = {
  readonly isEditable: boolean | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"access_issueNavigatorCustomFilters" | "edit_issueNavigatorCustomFilters">;
  readonly " $fragmentType": "permissions_issueNavigatorCustomFilters";
};
export type permissions_issueNavigatorCustomFilters$key = {
  readonly " $data"?: permissions_issueNavigatorCustomFilters$data;
  readonly " $fragmentSpreads": FragmentRefs<"permissions_issueNavigatorCustomFilters">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "permissions_issueNavigatorCustomFilters",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "access_issueNavigatorCustomFilters"
    },
    {
      "kind": "FragmentSpread",
      "name": "edit_issueNavigatorCustomFilters"
    },
    {
      "kind": "ScalarField",
      "name": "isEditable"
    }
  ],
  "type": "JiraCustomFilter"
};

(node as any).hash = "31add9aa56167a062969a5bd4dfaec78";

export default node;
