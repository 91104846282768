import React, { type ComponentType, type FC } from 'react';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazyAfterPaint } from '@atlassian/react-loosely-lazy';
import type { SaveFilterDialogContentProps } from './index.tsx';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const SaveFilterDialogContent: ComponentType<SaveFilterDialogContentProps> = lazyAfterPaint(
	() => import(/* webpackChunkName: "async-save-filter-dialog-content" */ './index'),
	{ ssr: false },
);

export const AsyncSaveFilterDialogContent: FC<SaveFilterDialogContentProps> = (
	props: SaveFilterDialogContentProps,
) => (
	<Placeholder name="async-save-filters-dialog-content" fallback={<></>}>
		<SaveFilterDialogContent {...props} />
	</Placeholder>
);
