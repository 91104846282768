/**
 * @generated SignedSource<<cabde37d42247daed0f55869eb5b6fd9>>
 * @relayHash 9f4cdfd24c4837edbec911e81f56cc4c
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 6bc8c23e9cd41f906771238f4fc28eed8056bad2b96a927b890039d4d973ea8d

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type mainPopupContentFilterByAriQuery$variables = {
  filterAri: string;
  isAnonymous: boolean;
};
export type mainPopupContentFilterByAriQuery$data = {
  readonly jira: {
    readonly filter: {
      readonly __id: string;
      readonly name: string;
      readonly " $fragmentSpreads": FragmentRefs<"description_issueNavigatorCustomFilters" | "owner_issueNavigatorCustomFilters" | "permissions_issueNavigatorCustomFilters" | "subscriptions_issueNavigatorCustomFilters">;
    };
  };
};
export type mainPopupContentFilterByAriQuery = {
  response: mainPopupContentFilterByAriQuery$data;
  variables: mainPopupContentFilterByAriQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filterAri"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "isAnonymous"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "filterAri"
  }
],
v2 = {
  "kind": "ScalarField",
  "name": "name"
},
v3 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "__id"
    }
  ]
},
v4 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v5 = {
  "kind": "ScalarField",
  "name": "id"
},
v6 = {
  "concreteType": "JiraProject",
  "kind": "LinkedField",
  "name": "project",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "kind": "ScalarField",
      "name": "key"
    },
    (v5/*: any*/)
  ]
},
v7 = [
  (v2/*: any*/),
  (v5/*: any*/)
],
v8 = {
  "concreteType": "JiraGroup",
  "kind": "LinkedField",
  "name": "group",
  "plural": false,
  "selections": (v7/*: any*/)
},
v9 = {
  "kind": "LinkedField",
  "name": "user",
  "plural": false,
  "selections": [
    (v4/*: any*/),
    (v2/*: any*/),
    (v5/*: any*/)
  ]
},
v10 = [
  {
    "kind": "LinkedField",
    "name": "node",
    "plural": false,
    "selections": [
      (v4/*: any*/),
      {
        "kind": "InlineFragment",
        "selections": [
          (v6/*: any*/)
        ],
        "type": "JiraShareableEntityProjectGrant"
      },
      {
        "kind": "InlineFragment",
        "selections": [
          (v6/*: any*/),
          {
            "concreteType": "JiraRole",
            "kind": "LinkedField",
            "name": "role",
            "plural": false,
            "selections": (v7/*: any*/)
          }
        ],
        "type": "JiraShareableEntityProjectRoleGrant"
      },
      {
        "kind": "InlineFragment",
        "selections": [
          (v8/*: any*/)
        ],
        "type": "JiraShareableEntityGroupGrant"
      },
      {
        "kind": "InlineFragment",
        "selections": [
          (v9/*: any*/)
        ],
        "type": "JiraShareableEntityUserGrant"
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "mainPopupContentFilterByAriQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "concreteType": "JiraQuery",
          "kind": "LinkedField",
          "name": "jira",
          "plural": false,
          "selections": [
            {
              "kind": "RequiredField",
              "field": {
                "args": (v1/*: any*/),
                "kind": "LinkedField",
                "name": "filter",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "kind": "FragmentSpread",
                        "name": "description_issueNavigatorCustomFilters"
                      },
                      {
                        "condition": "isAnonymous",
                        "kind": "Condition",
                        "passingValue": false,
                        "selections": [
                          {
                            "kind": "FragmentSpread",
                            "name": "owner_issueNavigatorCustomFilters"
                          },
                          {
                            "kind": "FragmentSpread",
                            "name": "subscriptions_issueNavigatorCustomFilters"
                          }
                        ]
                      },
                      {
                        "kind": "FragmentSpread",
                        "name": "permissions_issueNavigatorCustomFilters"
                      }
                    ],
                    "type": "JiraCustomFilter"
                  },
                  (v3/*: any*/)
                ]
              },
              "action": "THROW",
              "path": "jira.filter"
            }
          ]
        },
        "action": "THROW",
        "path": "jira"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "mainPopupContentFilterByAriQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "kind": "LinkedField",
            "name": "filter",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v2/*: any*/),
              (v5/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "description"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "isEditable"
                  },
                  {
                    "concreteType": "JiraShareableEntityShareGrantConnection",
                    "kind": "LinkedField",
                    "name": "shareGrants",
                    "plural": false,
                    "selections": [
                      {
                        "concreteType": "JiraShareableEntityShareGrantEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": (v10/*: any*/)
                      }
                    ]
                  },
                  {
                    "concreteType": "JiraShareableEntityEditGrantConnection",
                    "kind": "LinkedField",
                    "name": "editGrants",
                    "plural": false,
                    "selections": [
                      {
                        "concreteType": "JiraShareableEntityEditGrantEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": (v10/*: any*/)
                      }
                    ]
                  },
                  {
                    "condition": "isAnonymous",
                    "kind": "Condition",
                    "passingValue": false,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "name": "owner",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          {
                            "kind": "ScalarField",
                            "name": "accountId"
                          },
                          (v2/*: any*/),
                          {
                            "kind": "ScalarField",
                            "name": "picture"
                          },
                          (v5/*: any*/)
                        ]
                      },
                      {
                        "kind": "ScalarField",
                        "name": "filterId"
                      },
                      {
                        "args": [
                          {
                            "kind": "Literal",
                            "name": "last",
                            "value": 5
                          }
                        ],
                        "concreteType": "JiraFilterEmailSubscriptionConnection",
                        "kind": "LinkedField",
                        "name": "emailSubscriptions",
                        "plural": false,
                        "selections": [
                          {
                            "concreteType": "JiraFilterEmailSubscriptionEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "concreteType": "JiraFilterEmailSubscription",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v5/*: any*/),
                                  (v9/*: any*/),
                                  (v8/*: any*/)
                                ]
                              }
                            ]
                          }
                        ],
                        "storageKey": "emailSubscriptions(last:5)"
                      }
                    ]
                  }
                ],
                "type": "JiraCustomFilter"
              },
              (v3/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "6bc8c23e9cd41f906771238f4fc28eed8056bad2b96a927b890039d4d973ea8d",
    "metadata": {},
    "name": "mainPopupContentFilterByAriQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "0d557f612ff494f70f6caa72336c5a34";

export default node;
