/**
 * @generated SignedSource<<0ebe40d8d8ba620f502b114827f0d7b3>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type main_issueNavigator_Card_fieldSetsForIssueSearchView$data = {
  readonly edges: ReadonlyArray<{
    readonly node: {
      readonly fields: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly fieldId?: string;
            readonly name?: string;
            readonly text?: string | null | undefined;
          } | null | undefined;
        } | null | undefined> | null | undefined;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined> | null | undefined;
  readonly " $fragmentType": "main_issueNavigator_Card_fieldSetsForIssueSearchView";
};
export type main_issueNavigator_Card_fieldSetsForIssueSearchView$key = {
  readonly " $data"?: main_issueNavigator_Card_fieldSetsForIssueSearchView$data;
  readonly " $fragmentSpreads": FragmentRefs<"main_issueNavigator_Card_fieldSetsForIssueSearchView">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "main_issueNavigator_Card_fieldSetsForIssueSearchView",
  "selections": [
    {
      "concreteType": "JiraIssueFieldSetEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "concreteType": "JiraIssueFieldSet",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "concreteType": "JiraIssueFieldConnection",
              "kind": "LinkedField",
              "name": "fields",
              "plural": false,
              "selections": [
                {
                  "concreteType": "JiraIssueFieldEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        {
                          "kind": "InlineFragment",
                          "selections": [
                            {
                              "kind": "ScalarField",
                              "name": "fieldId"
                            },
                            {
                              "kind": "ScalarField",
                              "name": "name"
                            },
                            {
                              "kind": "ScalarField",
                              "name": "text"
                            }
                          ],
                          "type": "JiraSingleLineTextField"
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraIssueFieldSetConnection"
};

(node as any).hash = "af3f8561029a83ccd09c464401256fc5";

export default node;
