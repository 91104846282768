import React, { type ComponentType, type FC } from 'react';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/index.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazy } from '@atlassian/react-loosely-lazy';
import type { SpotlightCardProps } from './spotlight-card/index.tsx';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const SpotlightCard = lazy<ComponentType<SpotlightCardProps>>(
	// we want both these types of spotlights to be within the same webpack chunk

	() => import(/* webpackChunkName: "async-nin-spotlight" */ './spotlight-card'),
	{ ssr: false },
);

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { default as SpotlightTarget } from './spotlight-target';

export const AsyncSpotlightCard: FC<SpotlightCardProps> = (props: SpotlightCardProps) => (
	<JSErrorBoundary
		packageName="jiraIssueNavigatorChangeboarding"
		id="async-spotlight-card"
		sendToPrivacyUnsafeSplunk
		fallback="unmount"
	>
		<Placeholder name="async-spotlight-card" fallback={null}>
			<SpotlightCard {...props} />
		</Placeholder>
	</JSErrorBoundary>
);
