import type { MouseEvent as ReactMouseEvent } from 'react';
import { createSelectorCreator, defaultMemoize } from 'reselect';
import { views } from '../constants.tsx';
import type {
	BaseStoreType,
	ComplexValueType,
	PossibleValueTypes,
	ExternalKeyValueType,
	IssueNavigatorViewId,
	FilterIssueNavigatorViewId,
	View,
} from '../types.tsx';

/**
 * This selector only recomputes if the comparees are an object and has a property _rememoize
 * that has changed.
 */
export const createRememoizeSelector = createSelectorCreator(
	defaultMemoize,
	// @ts-expect-error - TS2339 - Property '_rememoize' does not exist on type 'T'. | TS2339 - Property '_rememoize' does not exist on type 'T'.
	(a, b) => a._rememoize === b._rememoize,
);

export const isNormalClick = (e: MouseEvent | ReactMouseEvent) =>
	!(e.button || e.metaKey || e.altKey || e.ctrlKey || e.shiftKey);

function isPossibleValueEntry(
	entry: [string, PossibleValueTypes | undefined],
): entry is [string, PossibleValueTypes] {
	return (
		typeof entry[1] === 'string' ||
		typeof entry[1] === 'number' ||
		(Array.isArray(entry[1]) && entry[1].length > 0)
	);
}
export const getRefinementsByKeyValueArray = (state: BaseStoreType) => {
	const definedEntries: [string, PossibleValueTypes][] =
		Object.entries(state).filter(isPossibleValueEntry);

	return definedEntries.map<{
		key: string;
		value: string | number | string[];
	}>((entry) => ({
		key: entry[0],
		value: Array.isArray(entry[1])
			? entry[1].map((values: ComplexValueType) => values.value)
			: entry[1],
	}));
};

export const getRefinementsByKeyValue = (state: BaseStoreType): ExternalKeyValueType =>
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	Object.keys(state).reduce<Record<string, any>>((acc, key) => {
		const value = state[key];
		if (key === 'search') {
			if (typeof value !== 'string') {
				throw new Error('Unexpected non-string literal for search.');
			}
			acc[key] = value;
		} else if (Array.isArray(value)) {
			acc[key] = value.map((values) => values.value);
		} else if (typeof value === 'string' || typeof value === 'undefined') {
			acc[key] = value || [];
		} else {
			acc[key] = value;
		}
		return acc;
	}, {});

/**
 * Maps the provided JiraIssueSearchView viewId into the view mode rendered in the issue navigator.
 */
export const viewIdToViewMode = (viewId?: string | null) =>
	viewId === 'detail' ? views.detail : views.list;

/**
 * Maps the provided issue navigator view mode into the viewId required by the JiraIssueSearchView GraphQL node.
 */
export const viewModeToViewId = (viewMode: string) =>
	viewMode === views.detail ? 'detail' : 'list_default';

/**
 * Maps the provided issue navigator view mode into the searchLayout required by the JiraUserPreferencesMutation.setIssueNavigatorSearchLayout GraphQL node.
 */
export const viewModeToSearchLayout = (viewMode: string) =>
	viewMode === views.detail ? 'DETAIL' : 'LIST';

/**
 * Type predicate to determine that a value is not `null` or `undefined`.
 */
export function isNonNullish<T>(value: T | null | undefined): value is T {
	return value != null;
}

export const parseIssueNavigatorViewIdOrDefault = (
	viewId: string | null | undefined,
	defaultValue: IssueNavigatorViewId = 'list_default',
): IssueNavigatorViewId => {
	if (!viewId) {
		return defaultValue;
	}

	switch (viewId) {
		case 'list_default':
			return 'list_default';
		case 'list_system':
			return 'list_system';
		case 'detail':
			return 'detail';
		default: {
			if (isFilterViewId(viewId)) {
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				return viewId as IssueNavigatorViewId;
			}
			return defaultValue;
		}
	}
};

export const isFilterViewId = (viewId: string) => /^list_filter_\d+$/.test(viewId);

export const convertToIssueNavigatorId = (view: View): IssueNavigatorViewId =>
	view === views.detail ? 'detail' : 'list_default';

export const convertToView = (issueNavigatorViewId: IssueNavigatorViewId): View =>
	issueNavigatorViewId === 'detail' ? views.detail : views.list;

export const convertFilterIdToIssueNavigatorId = (
	filterId: number | string,
): FilterIssueNavigatorViewId => {
	if (typeof filterId === 'number') {
		return `list_filter_${filterId}`;
	}

	const filterIdInt = Number(filterId);
	if (Number.isNaN(filterIdInt)) {
		throw new Error('filterId is invalid');
	}
	return `list_filter_${filterIdInt}`;
};
