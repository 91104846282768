/**
 * @generated SignedSource<<3d1b4a29cfc95e0b21d359da9825db69>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type description_issueNavigatorCustomFilters$data = {
  readonly description: string | null | undefined;
  readonly isEditable: boolean | null | undefined;
  readonly " $fragmentType": "description_issueNavigatorCustomFilters";
};
export type description_issueNavigatorCustomFilters$key = {
  readonly " $data"?: description_issueNavigatorCustomFilters$data;
  readonly " $fragmentSpreads": FragmentRefs<"description_issueNavigatorCustomFilters">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "description_issueNavigatorCustomFilters",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "description"
    },
    {
      "kind": "ScalarField",
      "name": "isEditable"
    }
  ],
  "type": "JiraCustomFilter"
};

(node as any).hash = "f9b450af0990be0be18e39994e4e39db";

export default node;
