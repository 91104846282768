export const BulkOperationsTypes = {
	BULK_EDIT: 'BULK_EDIT',
	BULK_TRANSITION: 'BULK_TRANSITION',
} as const;

export type BulkOperationsTypes = (typeof BulkOperationsTypes)[keyof typeof BulkOperationsTypes];

export const BulkTransitionsStage = {
	LOAD_BULK_TRANSITIONS: 'LOAD_BULK_TRANSITIONS',
	START_QUICK_TRANSITIONS: 'START_QUICK_TRANSITIONS',
	START_SIDEBAR_TRANSITION: 'START_SIDEBAR_TRANSITION',
	START_TRANSITION_SCREEN: 'START_TRANSITION_SCREEN',
} as const;

export type BulkTransitionsStage = (typeof BulkTransitionsStage)[keyof typeof BulkTransitionsStage];
