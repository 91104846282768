import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	unknownErrorHeader: {
		id: 'issue-view-errors.unknown-error-view.unknown-error-header',
		defaultMessage: "We couldn't connect to that issue",
		description: '',
	},
	unknownErrorDescription: {
		id: 'issue-view-errors.unknown-error-view.unknown-error-description',
		defaultMessage:
			"Make sure that this issue actually exists in that project. If it does, try again in a few minutes. If you still can't link to the issue, contact your Jira admin.",
		description: '',
	},
	unknownErrorHeaderIssueTermRefresh: {
		id: 'issue-view-errors.unknown-error-view.unknown-error-header-issue-term-refresh',
		defaultMessage: "We couldn't connect to that work item.",
		description: '',
	},
	unknownErrorDescriptionIssueTermRefresh: {
		id: 'issue-view-errors.unknown-error-view.unknown-error-description-issue-term-refresh',
		defaultMessage:
			"Make sure that this work item actually exists in that project. If it does, try again in a few minutes. If you still can't link to the work item, contact your Jira admin.",
		description: '',
	},
});
