/**
 * @generated SignedSource<<45411dc601d3767e044ebcd989f702c8>>
 * @relayHash ed5ed0e464e8d4111e17e3b76e0f2f4d
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID cb637222e2e5129fea36c125f2836b90ccb549107b0b60ca3c9c2379ae4dfb8f

import type { ConcreteRequest, Mutation } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type JiraReplaceIssueSearchViewFieldSetsInput = {
  after?: string | null | undefined;
  before?: string | null | undefined;
  context?: JiraIssueSearchViewFieldSetsContext | null | undefined;
  inclusive?: boolean | null | undefined;
  nodes: ReadonlyArray<string>;
};
export type JiraIssueSearchViewFieldSetsContext = {
  projectContext?: JiraIssueSearchViewFieldSetsProjectContext | null | undefined;
};
export type JiraIssueSearchViewFieldSetsProjectContext = {
  issueType?: string | null | undefined;
  project?: string | null | undefined;
};
export type JiraFieldSetsMutationInput = {
  replaceFieldSetsInput?: JiraReplaceIssueSearchViewFieldSetsInput | null | undefined;
  resetToDefaultFieldSets?: boolean | null | undefined;
};
export type JiraIssueSearchStaticViewInput = {
  isGroupingEnabled?: boolean | null | undefined;
  isHierarchyEnabled?: boolean | null | undefined;
};
export type main_replaceListViewFieldSetsMutation$variables = {
  amountOfColumns: number;
  fieldSetsInput?: JiraFieldSetsMutationInput | null | undefined;
  id: string;
  includeView: boolean;
  input?: JiraReplaceIssueSearchViewFieldSetsInput | null | undefined;
  staticViewInput?: JiraIssueSearchStaticViewInput | null | undefined;
};
export type main_replaceListViewFieldSetsMutation$data = {
  readonly jira: {
    readonly replaceIssueSearchViewFieldSets: {
      readonly success: boolean;
      readonly view?: {
        readonly viewId: string | null | undefined;
        readonly " $fragmentSpreads": FragmentRefs<"main_issueNavigator_ListView_view">;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type main_replaceListViewFieldSetsMutation$rawResponse = {
  readonly jira: {
    readonly replaceIssueSearchViewFieldSets: {
      readonly success: boolean;
      readonly view: {
        readonly fieldSets: {
          readonly __id?: string;
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly displayName: string | null | undefined;
              readonly fieldSetId: string | null | undefined;
              readonly fieldSetPreferences: {
                readonly width: number | null | undefined;
              } | null | undefined;
              readonly fieldType: {
                readonly displayName: string | null | undefined;
              } | null | undefined;
              readonly isSortable: boolean | null | undefined;
              readonly jqlTerm: string | null | undefined;
              readonly type: string | null | undefined;
            } | null | undefined;
          } | null | undefined> | null | undefined;
          readonly totalCount: number | null | undefined;
        } | null | undefined;
        readonly hasDefaultFieldSets: boolean | null | undefined;
        readonly id: string;
        readonly viewId: string | null | undefined;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type main_replaceListViewFieldSetsMutation = {
  rawResponse: main_replaceListViewFieldSetsMutation$rawResponse;
  response: main_replaceListViewFieldSetsMutation$data;
  variables: main_replaceListViewFieldSetsMutation$variables;
};
({
  "__relay_internal__pv__atlassianjirarelayprovidersrcjscm2apiupdatesrelayprovider": isJscIssueHierarchyEnabled_provider
} as {
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcjscm2apiupdatesrelayprovider: {
    readonly get: () => boolean;
  };
});

import isJscIssueHierarchyEnabled_provider from '@atlassian/jira-relay-provider/src/jsc-m2-api-updates.relayprovider';

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "amountOfColumns"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "fieldSetsInput"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "includeView"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "staticViewInput"
},
v6 = [
  {
    "kind": "Variable",
    "name": "fieldSetsInput",
    "variableName": "fieldSetsInput"
  },
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  },
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v7 = {
  "kind": "ScalarField",
  "name": "success"
},
v8 = {
  "kind": "ScalarField",
  "name": "viewId"
},
v9 = {
  "kind": "ScalarField",
  "name": "displayName"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "name": "main_replaceListViewFieldSetsMutation",
    "selections": [
      {
        "concreteType": "JiraMutation",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v6/*: any*/),
            "concreteType": "JiraIssueSearchViewPayload",
            "kind": "LinkedField",
            "name": "replaceIssueSearchViewFieldSets",
            "plural": false,
            "selections": [
              (v7/*: any*/),
              {
                "condition": "includeView",
                "kind": "Condition",
                "passingValue": true,
                "selections": [
                  {
                    "concreteType": "JiraIssueSearchView",
                    "kind": "LinkedField",
                    "name": "view",
                    "plural": false,
                    "selections": [
                      (v8/*: any*/),
                      {
                        "args": [
                          {
                            "kind": "Literal",
                            "name": "shouldSkipHierarchy",
                            "value": true
                          }
                        ],
                        "kind": "FragmentSpread",
                        "name": "main_issueNavigator_ListView_view"
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v4/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/),
      (v3/*: any*/),
      (v5/*: any*/),
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcjscm2apiupdatesrelayprovider"
      }
    ],
    "kind": "Operation",
    "name": "main_replaceListViewFieldSetsMutation",
    "selections": [
      {
        "concreteType": "JiraMutation",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v6/*: any*/),
            "concreteType": "JiraIssueSearchViewPayload",
            "kind": "LinkedField",
            "name": "replaceIssueSearchViewFieldSets",
            "plural": false,
            "selections": [
              (v7/*: any*/),
              {
                "condition": "includeView",
                "kind": "Condition",
                "passingValue": true,
                "selections": [
                  {
                    "concreteType": "JiraIssueSearchView",
                    "kind": "LinkedField",
                    "name": "view",
                    "plural": false,
                    "selections": [
                      (v8/*: any*/),
                      {
                        "kind": "ScalarField",
                        "name": "id"
                      },
                      {
                        "kind": "ScalarField",
                        "name": "hasDefaultFieldSets"
                      },
                      {
                        "args": [
                          {
                            "kind": "Literal",
                            "name": "filter",
                            "value": {
                              "fieldSetSelectedState": "SELECTED"
                            }
                          },
                          {
                            "kind": "Variable",
                            "name": "first",
                            "variableName": "amountOfColumns"
                          }
                        ],
                        "concreteType": "JiraIssueSearchFieldSetConnection",
                        "kind": "LinkedField",
                        "name": "fieldSets",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "name": "totalCount"
                          },
                          {
                            "concreteType": "JiraIssueSearchFieldSetEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "concreteType": "JiraIssueSearchFieldSet",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  {
                                    "kind": "ScalarField",
                                    "name": "fieldSetId"
                                  },
                                  {
                                    "kind": "ScalarField",
                                    "name": "type"
                                  },
                                  (v9/*: any*/),
                                  {
                                    "kind": "ScalarField",
                                    "name": "jqlTerm"
                                  },
                                  {
                                    "kind": "ScalarField",
                                    "name": "isSortable"
                                  },
                                  {
                                    "concreteType": "JiraFieldSetPreferences",
                                    "kind": "LinkedField",
                                    "name": "fieldSetPreferences",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "kind": "ScalarField",
                                        "name": "width"
                                      }
                                    ]
                                  },
                                  {
                                    "concreteType": "JiraFieldType",
                                    "kind": "LinkedField",
                                    "name": "fieldType",
                                    "plural": false,
                                    "selections": [
                                      (v9/*: any*/)
                                    ]
                                  }
                                ]
                              }
                            ]
                          },
                          {
                            "kind": "ClientExtension",
                            "selections": [
                              {
                                "kind": "ScalarField",
                                "name": "__id"
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "cb637222e2e5129fea36c125f2836b90ccb549107b0b60ca3c9c2379ae4dfb8f",
    "metadata": {},
    "name": "main_replaceListViewFieldSetsMutation",
    "operationKind": "mutation",
    "text": null,
    "providedVariables": {
      "__relay_internal__pv__atlassianjirarelayprovidersrcjscm2apiupdatesrelayprovider": isJscIssueHierarchyEnabled_provider
    }
  }
};
})();

(node as any).hash = "f45d2a46bef4789b43f5954623634539";

export default node;
