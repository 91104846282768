import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	permissions: {
		id: 'issue-navigator-custom-filters.details-popup.popup-content.permissions.permissions',
		defaultMessage: 'Permissions',
		description: 'Header text of the Permissions section for a filter',
	},
	editPermissions: {
		id: 'issue-navigator-custom-filters.details-popup.popup-content.permissions.edit-permissions',
		defaultMessage: 'Edit Permissions',
		description: 'Header text of the Edit Permissions button for a filter',
	},
});
