import React, { memo, type ComponentType } from 'react';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/index.tsx';
import { PACKAGE_NAME, TEAM_NAME } from './common/constants.tsx';
import {
	HierarchyToggle as HierarchyToggleWithoutErrorBoundary,
	type HierarchyToggleProps,
} from './ui/index.tsx';

export const HierarchyToggle: ComponentType<HierarchyToggleProps> = memo<HierarchyToggleProps>(
	(props: HierarchyToggleProps) => (
		<JSErrorBoundary
			fallback="unmount"
			id="issue-table-hierarchy-toggle"
			packageName={PACKAGE_NAME}
			teamName={TEAM_NAME}
		>
			<HierarchyToggleWithoutErrorBoundary {...props} />
		</JSErrorBoundary>
	),
);
