import React, { useCallback } from 'react';
import { graphql, useFragment } from 'react-relay';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type { description_issueNavigatorCustomFilters$key } from '@atlassian/jira-relay/src/__generated__/description_issueNavigatorCustomFilters.graphql';
import { ExperienceSuccess } from '@atlassian/ufo';
import { SectionHeader } from '../../../../common/ui/details-popup/section-header/index.tsx';
import { ShowMore } from '../../../../common/ui/details-popup/show-more/index.tsx';
import { Section, PlaceholderText } from '../../../../common/ui/details-popup/styled.tsx';
import { viewDetailsPopupExperience } from '../../../../experiences.tsx';
import messages from './messages.tsx';

type Props = {
	filter: description_issueNavigatorCustomFilters$key;
	onOpenEditFilterModal: () => void;
};

export const Description = ({ filter, onOpenEditFilterModal }: Props) => {
	const { formatMessage } = useIntl();

	const data = useFragment(
		graphql`
			fragment description_issueNavigatorCustomFilters on JiraCustomFilter {
				description
				isEditable
			}
		`,
		filter,
	);
	const openEditFilterNameAndDescriptionModal = useCallback(
		(_: unknown, analyticsEvent: UIAnalyticsEvent) => {
			onOpenEditFilterModal();
			fireUIAnalytics(analyticsEvent, 'editFilterNameAndDescription');
		},
		[onOpenEditFilterModal],
	);

	return (
		<Section>
			<SectionHeader
				actions={
					data.isEditable === true ? (
						<Button
							onClick={openEditFilterNameAndDescriptionModal}
							appearance="link"
							spacing="none"
							testId="issue-navigator-custom-filters.ui.details-popup.popup-content.description.edit-name-and-description-button"
						>
							{formatMessage(messages.editNameAndDescription)}
						</Button>
					) : null
				}
			>
				{formatMessage(messages.description)}
			</SectionHeader>
			<ShowMore>
				{data.description != null && data.description.length ? (
					data.description
				) : (
					<PlaceholderText>{formatMessage(messages.descriptionPlaceholder)}</PlaceholderText>
				)}
			</ShowMore>
			<ExperienceSuccess experience={viewDetailsPopupExperience} />
		</Section>
	);
};
