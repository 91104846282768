/**
 * @generated SignedSource<<979fda2fd2d51a6e06b80dc24205a0d0>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type hierarchyToggle_issueNavigator_HierarchyToggleItem$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ui_issueTableHierarchyToggle_HierarchyToggle">;
  readonly " $fragmentType": "hierarchyToggle_issueNavigator_HierarchyToggleItem";
};
export type hierarchyToggle_issueNavigator_HierarchyToggleItem$key = {
  readonly " $data"?: hierarchyToggle_issueNavigator_HierarchyToggleItem$data;
  readonly " $fragmentSpreads": FragmentRefs<"hierarchyToggle_issueNavigator_HierarchyToggleItem">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "__relay_internal__pv__atlassianjirarelayprovidersrcjscm2apiupdatesrelayprovider"
    },
    {
      "kind": "RootArgument",
      "name": "staticViewInput"
    }
  ],
  "kind": "Fragment",
  "name": "hierarchyToggle_issueNavigator_HierarchyToggleItem",
  "selections": [
    {
      "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjscm2apiupdatesrelayprovider",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "args": [
            {
              "kind": "Variable",
              "name": "staticViewInput",
              "variableName": "staticViewInput"
            }
          ],
          "kind": "FragmentSpread",
          "name": "ui_issueTableHierarchyToggle_HierarchyToggle"
        }
      ]
    }
  ],
  "type": "JiraIssueSearchView"
};

(node as any).hash = "963561a968d3c37206d8a42b2396fc1f";

export default node;
